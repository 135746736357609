import VueCurrencyFilter from "vue-currency-filter";
import Vue from "vue";

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
  avoidEmptyDecimals: undefined,
});
