<template>
  <v-app class="page-container">
    <h1 class="page-title mb__default text-center">AI Suggestions</h1>
    <div class="flex justify__center" v-if="isLoading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <v-card outlined v-if="!isLoading">
      <v-card-title class="font__inter">
        <v-text-field hide-details outlined dense @input="filterSuggestions" label="Search" />
      </v-card-title>
      <v-card-text class="font__inter">
        <v-list subheader two-line flat dense>
          <v-list-item-group multiple>
            <v-list-item
              v-for="suggestion in filteredSuggestions"
              :key="suggestion.title"
            >
              <template #default>
                <v-list-item-icon>
                  <v-icon color="primary">
                    {{ generateIcon(suggestion) }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ suggestion.title }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ generateSubTitle(suggestion) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                    :value="suggestion"
                    v-model="selectedSuggestions"
                    color="primary"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <div class="flex justify__center">
          <v-btn @click="attachSelected" color="primary">ATTACH TO RECORD</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "AutoSuggestion",
  data: () => ({
    suggestions: [
      { title: "Hight.io BV", type: "CUSTOMER", email: "hello@hight.io" },
      { title: "STRAKKER", type: "SUPPLIER", email: "jan@hight.io" },
      { title: "3203|PV|00001", type: "VOUCHER", email: "STRAKKER" },
      { title: "3202|PV|00001", type: "VOUCHER", email: "STRAKKER" },
      { title: "3201|PV|00001", type: "VOUCHER", email: "STRAKKER" },
      { title: "3200|PV|00001", type: "VOUCHER", email: "STRAKKER" },
      { title: "3199|PV|00001", type: "VOUCHER", email: "STRAKKER" },
      { title: "Hight.io UC PoC", type: "SUPPLIER", email: "jan@hight.io" },
      { title: "248|PL|00001", type: "VOUCHER", email: "Hight.io BV" },
      { title: "247|PL|00001", type: "VOUCHER", email: "Hight.io BV" },
      { title: "246|PL|00001", type: "VOUCHER", email: "Hight.io BV" },
      { title: "245|PL|00001", type: "VOUCHER", email: "Hight.io BV" },
      { title: "244|PL|00001", type: "VOUCHER", email: "Hight.io BV" },
      { title: "Jan Hox", type: "EMPLOYEE", email: "jan@hight.io" },
      { title: "2855|SO|00200", type: "SALESORDER", email: "The Belgian Beer Company" },
      { title: "2854|SO|00200", type: "SALESORDER", email: "The Belgian Beer Company" },
      { title: "2853|SO|00200", type: "SALESORDER", email: "The Belgian Beer Company" },
      { title: "2852|SO|00200", type: "SALESORDER", email: "The Belgian Beer Company" },
      { title: "2851|SO|00200", type: "SALESORDER", email: "The Belgian Beer Company" },
      { title: "2683|OP|00001", type: "PURCHASEORDER", email: "Hight.io BV" },
      { title: "2682|OP|00001", type: "PURCHASEORDER", email: "Hight.io BV" },
      { title: "2681|OP|00001", type: "PURCHASEORDER", email: "Hight.io BV" },
      { title: "2680|OP|00001", type: "PURCHASEORDER", email: "Hight.io BV" },
      { title: "2679|OP|00001", type: "PURCHASEORDER", email: "Hight.io BV" }
    ],
    filteredSuggestions: [],
    selectedSuggestions: [],
    isLoading: true,
  }),
  mounted() {
    this.filteredSuggestions = [...this.suggestions];
    setTimeout(() => {
      this.isLoading = false;
    }, this.getRandomInt(500, 2000));
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    },
    attachSelected() {
      console.log(this.selectedSuggestions);
    },
    filterSuggestions(value) {
      if (!value.length) {
        this.filteredSuggestions = [...this.suggestions];
        return;
      }
      if (value.length < 2) return;
      this.filteredSuggestions = this.suggestions.filter((suggestion) => {
        const concatedString = Object.values(suggestion)
          .map((value) => `${value}`.toLowerCase())
          .join(",");
        return concatedString.includes(value.toLowerCase());
      });
    },
    generateSubTitle(suggestion) {
      const parsedType = suggestion.type
        .split("-")
        .map((word) => this.capitalizeWord(word))
        .join(" ");
      if (suggestion.type.includes("order")) {
        return `${parsedType} | ${suggestion.entity}`;
      }
      return `${parsedType} | ${suggestion.email}`;
    },
    capitalizeWord(word) {
      return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
    },
    generateIcon({ type }) {
      switch (type) {
        case "CUSTOMER":
          return "mdi-account-tie-outline";
        case "SUPPLIER":
          return "mdi-account-star-outline";
        case "EMPLOYEE":
          return "mdi-account-multiple";
        case "VOUCHER":
          return "mdi-ticket-percent-outline";
        case "SALESORDER":
          return "mdi-basket-plus";
        case "PURCHASEORDER":
          return "mdi-cart";
        default:
          return "mdi-file";
      }
    },
  },
};
</script>

<style>
.v-list-item {
  padding: 0;
}
.v-list-item__icon {
  margin: auto !important;
  margin-right: 10px !important;
}
</style>
