<template>
  <v-app>
    <v-container>
      <h1 class="page-title text-center mt-5">Attaching email to JDE</h1>
      <v-row>
        <v-col class="center mt-5">
          <v-progress-circular indeterminate color="primary" v-if="isLoading" />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      stage: "",
    };
  },
  computed: {
    ...mapState({
      contact: (state) => state.contact.contact,
    }),
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },
  async mounted() {
    let ewsData = {};
    try {
      this.isLoading = true;
      Office.context.mailbox.getCallbackTokenAsync(
        { isRest: true },
        (result) => {
          var ewsId = Office.context.mailbox.item.itemId;
          var token = result.value;
          var restId = Office.context.mailbox.convertToRestId(
            ewsId,
            Office.MailboxEnums.RestVersion.v2_0
          );
          var getMailUrl =
            Office.context.mailbox.restUrl +
            "/v2.0/me/messages/" +
            restId +
            "/$value";

          var headers = { Authorization: "Bearer " + token };
          var options = { headers: headers };
          ewsData.ewsId = ewsId;
          ewsData.url = getMailUrl;
          ewsData.token = token;
          ewsData.restId = restId;

          axios
            .get(getMailUrl, options)
            .then((response) => {
              //ewsData.response = response.data;
              let postDataAttachment = {
                mail_content: response.data,
                action: "mail_attach",
                email_id: ewsData.ewsId,
                email_rest: ewsData.restId,
                attach_to: `ABGT__${this.contact.ab_number}`,
              };

              axios
                .post(
                  "https://s3skv5gm1l.execute-api.us-east-1.amazonaws.com/production/api/outlookbot",
                  postDataAttachment
                )
                .then(() => {
                  ewsData.status = "OK";

                  this.sleep(3000).then(() => {
                    this.isLoading = false;
                    this.showSnackbar({
                      type: "success",
                      message: "Email successfully attached.",
                    });
                    this.$router.push("/");
                  });
                  return ewsData;
                })
                .catch((error) => {
                  throw "Post Attachments API ERROR.." + error;
                });
            })
            .catch((error) => {
              throw "Get Attachments EWS Call ERROR.." + error;
            });
        }
      );
    } catch (e) {
      this.showSnackbar({
        type: "error",
        mesage: `ATTACHMENTS ERROR ${e}`,
      });
      this.stage = "ATTACHMENTS ERROR" + e;
    }
  },
};
</script>

<style scoped>
.success-message {
  color: #05d872;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
}
</style>
