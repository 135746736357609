<template>
  <div>
    <v-expansion-panel outlined>
      <v-expansion-panel-header class="w__full h__full">
        <div class="flex justify__between align__center">
          <h3>{{ objectName }} {{ lineIndex + 1 }}</h3>
          <v-icon
            @click="deleteLine(lineIndex, $event)"
            color="red"
            class="mr-3"
            :class="{ 'not-visible': lineIndex === 0 }"
          >
            mdi-delete-outline
          </v-icon>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <component
          v-for="(field, fieldIndex) in getElements"
          :key="fieldIndex"
          @selected="handleLineChange(lineIndex, $event)"
          @text-change="handleLineChange(lineIndex, $event)"
          @phone-change="handleLineChange(lineIndex, $event)"
          :field="field"
          :is="decideWhichComponent(field)"
          v-show="field.visibility !== 'hide'"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LOVField from "@/components/Fields/LOVField.vue";
import UDCField from "@/components/Fields/UDCField.vue";
import SearchFormField from "@/components/Fields/SearchFormField.vue";
import XREFField from "@/components/Fields/XREFField.vue";
import TableField from "@/components/Fields/TableField.vue";
import StaticField from "@/components/Fields/StaticField.vue";
import ORCHField from "@/components/Fields/ORCHField.vue";
import TextField from "@/components/Fields/TextField.vue";
import RuleField from "@/components/Fields/RuleField.vue";
import DateField from "@/components/Fields/DateField.vue";
import PasswordField from "@/components/Fields/PasswordField.vue";
import LinklistField from "@/components/Fields/LinklistField.vue";
import HTML5String from "@/components/Fields/HTML5/String.vue";
import HTML5Email from "@/components/Fields/HTML5/Email.vue";
import HTML5Phone from "@/components/Fields/HTML5/Phone.vue";
import HTML5Numeric from "@/components/Fields/HTML5/Numeric.vue";
import HTML5Slider from "@/components/Fields/HTML5/Slider.vue";
import HTML5Checkbox from "@/components/Fields/HTML5/Checkbox.vue";
import HTML5Money from "@/components/Fields/HTML5/Money.vue";
import AddressField from "@/components/Fields/AddressField.vue";
import RatingField from "@/components/Fields/RatingField.vue";

export default {
  name: "LineItem",
  components: {
    "udc-field": UDCField,
    "lov-field": LOVField,
    "searchform-field": SearchFormField,
    "xref-field": XREFField,
    "table-field": TableField,
    "static-field": StaticField,
    "orch-field": ORCHField,
    "text-field": TextField,
    "rule-field": RuleField,
    "date-field": DateField,
    "password-field": PasswordField,
    "linklist-field": LinklistField,
    "string-field": HTML5String,
    "email-field": HTML5Email,
    "phone-field": HTML5Phone,
    "numeric-field": HTML5Numeric,
    "slider-field": HTML5Slider,
    "checkbox-field": HTML5Checkbox,
    "money-field": HTML5Money,
    AddressField,
    RatingField,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    objectName: {
      type: String,
      required: true,
    },
    lineIndex: {
      type: Number,
      required: true,
    },
    templateLine: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    getElements() {
      const clonedElements = JSON.parse(JSON.stringify(this.fields));
      return clonedElements.map((ele) => {
        if (!this.templateLine) return ele;
        const elKey = ele.key;
        if (Object.prototype.hasOwnProperty.call(this.templateLine, elKey)) {
          ele.default = this.templateLine[elKey];
        }
        return ele;
      });
    },
  },
  methods: {
    decideWhichComponent(field) {
      const lowerCasedField = field.attribute_type?.toLowerCase();
      if (!lowerCasedField || lowerCasedField === "string") {
        return "text-field";
      }
      return `${field.attribute_type.toLowerCase()}-field`;
    },
    deleteLine(index, event) {
      event.stopPropagation();
      this.$emit("delete-line", index);
    },
    handleLineChange(index, object) {
      this.$emit("update-payload", { index, object });
    },
  },
};
</script>
