<template>
  <div>
    <v-tabs :grow="isGrowEnabled" v-model="currentTab">
      <v-tabs-slider color="primary" />
      <v-tab v-for="(tabItem, index) in vuetifyTabs" :key="index">
        {{ tabItem }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab" touchless>
      <v-tab-item
        v-for="(tabItem, index) in vuetifyTabItems"
        :key="index"
        class="pa-4"
      >
        <v-row>
          <v-col
            v-for="(field, index) in vuetifyTabItems[index]"
            v-show="isFieldVisible(field)"
            :key="index"
            cols="12"
          >
            <component
              :is="decideWhichComponent(field)"
              :field="field"
              @selected="handleFieldUpdate"
              @text-change="handleFieldUpdate"
              @phone-change="handleFieldUpdate"
              @delete-payload-field="handlePayloadFieldDeletion"
              @manage-field-visibility="manageFieldVisibility"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LOVField from "@/components/Fields/LOVField.vue";
import UDCField from "@/components/Fields/UDCField.vue";
import SearchFormField from "@/components/Fields/SearchFormField.vue";
import XREFField from "@/components/Fields/XREFField.vue";
import TableField from "@/components/Fields/TableField.vue";
import StaticField from "@/components/Fields/StaticField.vue";
import ORCHField from "@/components/Fields/ORCHField.vue";
import TextField from "@/components/Fields/TextField.vue";
import RuleField from "@/components/Fields/RuleField.vue";
import DateField from "@/components/Fields/DateField.vue";
import PasswordField from "@/components/Fields/PasswordField.vue";
import HTML5String from "@/components/Fields/HTML5/String.vue";
import HTML5Checkbox from "@/components/Fields/HTML5/Checkbox.vue";
import HTML5Email from "@/components/Fields/HTML5/Email.vue";
import HTML5Numeric from "@/components/Fields/HTML5/Numeric.vue";
import HTML5Phone from "@/components/Fields/HTML5/Phone.vue";
import HTML5Slider from "@/components/Fields/HTML5/Slider.vue";
import HTML5Money from "@/components/Fields/HTML5/Money.vue";
import HTML5Image from "@/components/Fields/HTML5/Image.vue";
import HTML5Title from "@/components/Fields/HTML5/Title.vue";
import HTML5Description from "@/components/Fields/HTML5/Description.vue";
import AddressField from "@/components/Fields/AddressField.vue";
import RatingField from "@/components/Fields/RatingField.vue";
import LinklistField from "@/components/Fields/LinklistField.vue";
import CSVFilePickerField from "@/components/Fields/CSVFilePickerField.vue";
import BarcodeField from "@/components/Fields/BarcodeField.vue";

export default {
  name: "Tabs",
  components: {
    "udc-field": UDCField,
    "lov-field": LOVField,
    "searchform-field": SearchFormField,
    "xref-field": XREFField,
    "table-field": TableField,
    "static-field": StaticField,
    "orch-field": ORCHField,
    "text-field": TextField,
    "rule-field": RuleField,
    "date-field": DateField,
    "password-field": PasswordField,
    "string-field": HTML5String,
    "checkbox-field": HTML5Checkbox,
    "email-field": HTML5Email,
    "numeric-field": HTML5Numeric,
    "phone-field": HTML5Phone,
    "slider-field": HTML5Slider,
    "money-field": HTML5Money,
    "image-field": HTML5Image,
    "title-field": HTML5Title,
    "description-field": HTML5Description,
    "csv-file-picker-field": CSVFilePickerField,
    AddressField,
    RatingField,
    LinklistField,
    BarcodeField,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    tab: {},
    currentTab: 0,
    visibleFields: [],
    vuetifyTabs: [],
    vuetifyTabItems: [],
  }),
  computed: {
    isGrowEnabled() {
      return this.tab?.config?.grow || false;
    },
  },
  created() {
    this.tab = this.tabs[0];
    this.tab.config?.tabs.forEach((tab) => {
      this.vuetifyTabs.push(tab.label);
      this.vuetifyTabItems.push(this.tab?.elements[tab.key]);
    });
    this.extractVisibleElementsPerTab();
  },
  methods: {
    extractVisibleElementsPerTab() {
      Object.values(this.tab.elements)
        .filter((el) => el.length)
        .flat()
        .forEach((field) => {
          if (!field) return;
          if (field.visibility === "hide") return;
          this.visibleFields.push(field.key);
        });
    },
    manageFieldVisibility({ visibility, field }) {
      const indexOfField = this.visibleFields.indexOf(field);

      if (visibility && indexOfField === -1) {
        this.visibleFields.push(field);
      }
      if (!visibility && indexOfField !== -1) {
        this.visibleFields.splice(indexOfField, 1);
      }
    },
    isFieldVisible(field) {
      return this.visibleFields.includes(field.key);
    },
    decideWhichComponent(field) {
      const lowerCasedField = field.attribute_type?.toLowerCase();
      if (!lowerCasedField || lowerCasedField === "string") {
        return "text-field";
      }
      return `${lowerCasedField}-field`;
    },
    handleFieldUpdate(payload) {
      this.$emit("update-payload", payload);
    },
    handlePayloadFieldDeletion(field) {
      this.$emit("delete-payload-entry", field);
    },
  },
};
</script>
