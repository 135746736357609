<template>
  <div class="d-flex align-center px-2">
    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="primary" v-bind="attrs" v-on="on">
          mdi-translate
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          :class="{ 'primary white--text': isLocaleActive(locale) }"
          v-for="locale in locales"
          :key="locale"
          @click="setCurrentLocale(locale)"
        >
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              {{ locale }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Locales",
  data: () => ({
    locales: ["en", "nl", "fr", "es"],
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
    }),
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentUser() {
      this.setLocaleFromStorage();
    },
  },
  mounted() {
    this.setLocaleFromStorage();
  },
  methods: {
    isLocaleActive(locale) {
      return locale === this.$i18n.locale;
    },
    setCurrentLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    setLocaleFromStorage() {
      const selectedLocale = localStorage.getItem("locale");
      if (!selectedLocale) {
        const currentUserJDELocale = this.currentUser?.locale?.toLowerCase();
        if (!currentUserJDELocale) return;
        this.$i18n.locale = currentUserJDELocale;
        localStorage.setItem("locale", currentUserJDELocale);
        return;
      }
      this.$i18n.locale = selectedLocale;
    },
  },
};
</script>
