import axios from "axios";

const getBaseUrl = () => {
  return "https://qbb6l7jemk.execute-api.us-east-1.amazonaws.com/production/api/inbound_bot_request";
};

const jdeDataInstance = axios.create({
  baseURL: getBaseUrl(),
});

const generateGridDataLines = (dataLines, detailBranchPlant) => {
  const payload = {
    ArrayElement1: `${dataLines.length}`,
  };
  dataLines.forEach((line, index) => {
    const suffix = index > 0 ? `_${index}` : "";
    const lineObject = {
      [`[GridData_1]detailBP${suffix}`]: `${detailBranchPlant.businessUnit}_${detailBranchPlant.buType}`,
      [`[GridData_1]item${suffix}`]: line.poItem,
      [`[GridData_1]quantity_ordered${suffix}`]: line.quantity,
      [`[GridData_1]unit_price${suffix}`]: line.price,
      [`[GridData_1]lineDescription1${suffix}`]: line.lineDescription,
    };
    Object.assign(payload, lineObject);
  });
  return payload;
};

const generateFetchHeaderDataPayload = (payload) => ({
  action: "orchestratorbot_route_F55BOT10",
  orchestratorbot_route_F55BOT10: true,
  environment: payload.split("_")[1],
  source: "JDE",
  channel: "OUTLOOK",
});
const generateFetchDetailBranchPlantPayload = (payload) => ({
  action: "orchestratorbot_route_F0006_BU",
  orchestratorbot_route_F0006_BU: true,
  environment: payload.flowName.split("_")[1],
  company: payload.company,
  source: "JDE",
  channel: "OUTLOOK",
});
const generateFetchSuppliersPayload = (payload) => ({
  action: "orchestratorbot_route_F0101_VENDORS",
  orchestratorbot_route_F0101_VENDORS_V3: true,
  environment: payload.flowName.split("_")[1],
  securityBU: payload.bu,
  source: "JDE",
  channel: "OUTLOOK",
});
const generatePOItemsPayload = (payload) => ({
  action: "orchestratorbot_route_F55BOT20",
  orchestratorbot_route_F55BOT20: true,
  poType: payload.poType,
  environment: payload.flowName.split("_")[1],
  source: "JDE",
  channel: "OUTLOOK",
});

const generateFetchDetailBranchPlantV2Payload = (payload) => ({
  action: "orchestratorbot_route_F0006_BU_V2",
  orchestratorbot_route_F0006_BU_V2: true,
  environment: payload.flowName.split("_")[1],
  company: payload.company,
  source: "JDE",
  channel: "OUTLOOK",
});

const generateCreatePOPayload = (payload) => ({
  action: "orchestratorbot_route_v3",
  orchestratorbot_route_v3: true,
  input_data: {
    selected_bu: payload.headerBranchPlant.bu.trim(),
    selected_co: payload.headerBranchPlant.company,
    selected_busec: payload.headerBranchPlant.bu_security,
    selected_an8: payload.supplier,
    poType: payload.poType,
    currencyCode: payload.currencyCode,
    headerDescription: payload.poDescription,
    ...generateGridDataLines(payload.dataLines, payload.detailBranchPlant),
  },
  user_email: payload.userEmail,
  ad_user: "hoxjan@hamon.com",
  environment: payload.flowName.split("_")[1],
  source: "JDE",
  channel: "OUTLOOK",
});

export default {
  fetchHeaderData: async (payload) => {
    const { data } = await jdeDataInstance.post(
      "",
      generateFetchHeaderDataPayload(payload)
    );
    return data.obot.f55bot10_data;
  },
  fetchDetailBranchPlant: async (payload) => {
    const { data } = await jdeDataInstance.post(
      "",
      generateFetchDetailBranchPlantPayload(payload)
    );
    return data.obot.f0006_data.map((item) => ({
      ...item,
      businessUnit: item.businessUnit.trim(),
      label: `${item.businessUnit.trim()}_${item.buDesc1}`,
    }));
  },
  fetchDetailBranchPlantV2: async (payload) => {
    const { data } = await jdeDataInstance.post(
      "",
      generateFetchDetailBranchPlantV2Payload(payload)
    );
    return data.obot.f0006_data.map((item) => ({
      ...item,
      businessUnit: item.businessUnit.trim(),
      label: `${item.businessUnit.trim()}_${item.buDesc1}`,
    }));
  },
  fetchSuppliers: async (payload) => {
    const { data } = await jdeDataInstance.post(
      "",
      generateFetchSuppliersPayload(payload)
    );
    return data.obot.f0101_data.map((item) => ({
      ...item,
      searchLabel: `${item.returnALPH}_${item.returnCTR}_${item.returnCTY1}_${item.returnADD1}`,
    }));
  },
  fetchPOItems: async (payload) => {
    const { data } = await jdeDataInstance.post(
      "",
      generatePOItemsPayload(payload)
    );
    return data.obot.f55bot20_data;
  },
  createPO: async (payload) => {
    const { data } = await jdeDataInstance.post(
      "",
      generateCreatePOPayload(payload)
    );
    if (data.obot.result === "ERROR") {
      throw new Error(data.obot.error);
    }
    return data.obot;
  },
};
