import { getUsersUrl } from "@/config/endpointsUrl";

const getInstance = (axios) => {
  axios.setBaseURL(getUsersUrl());
  return axios;
};

export default (axios) => ({
  fetchMenuItems: async (companyId) => {
    const { data } = await getInstance(axios).get("/menuitems", {
      params: { company_id: companyId },
    });
    return data.menu_items;
  },
  fetchBotUsers: async (companyId) => {
    const { data } = await getInstance(axios).get("/botusers", {
      params: { company_id: companyId },
    });
    return data.users;
  },
});
