import { mapState } from "vuex";

export default {
  name: "BaseField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    defaultValue: "",
  }),
  computed: {
    ...mapState({
      currentBotUser: (state) => state.users.currentUser,
      staticValues: (state) => state.company.staticValues,
      outlookState: (state) => state.common.outlookState,
      currentFlow: (state) => state.company.currentFlow,
    }),
  },
  mounted() {
    if (this.field.inherit_default_value) {
      this.listenValueChange();
      return;
    }
    if (this.field.default) {
      const staticValues = [];
      const outlookValues = [];
      this.$set(this, "defaultValue", this.field.default);
      this.staticValues.forEach((field) => {
        if (field.type === "standard") staticValues.push(field.value);
        if (field.type === "outlook") outlookValues.push(field.value);
      });
      if (staticValues.includes(this.field.default)) {
        this.extractStaticValue(this.field.default);
      }
      if (outlookValues.includes(this.field.default)) {
        this.extractOutlookValue(this.field.default);
      }
      this.handleChange(this.defaultValue);
    }
  },
  methods: {
    listenValueChange() {
      const fields = [...this.currentFlow.config.payload_config.root_elements];
      if (this.currentFlow.config.payload_config.tab_elements?.length) {
        const tabElements = Object.values(
          this.currentFlow.config.payload_config.tab_elements[0].elements
        ).flat();
        fields.push(...tabElements);
      }
      const masterField = fields.find(
        (field) =>
          field.published_name === this.field.default ||
          field.key === this.field.default
      );
      if (!masterField) return;
      const currentStoreData = this.$store.state.company.flowData;
      if (Object.keys(currentStoreData).includes(masterField.key)) {
        const value = currentStoreData[masterField.key];
        if (!value) return;
        if (value === this.defaultValue) return;
        this.$set(this, "defaultValue", value);
        this.handleChange(value);
      }
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          if (
            Object.prototype.hasOwnProperty.call(
              mutation.payload,
              masterField.key
            )
          ) {
            const value = mutation.payload[masterField.key];
            if (value === this.defaultValue) return;
            this.$set(this, "defaultValue", value);
            this.handleChange(value);
          }
        }
      });
    },
    async extractOutlookValue(value) {
      let body;
      const getBody = () => {
        return new Promise((res, rej) => {
          Office.context.mailbox.item.body?.getAsync(
            Office.CoercionType.Text,
            (result) => {
              res(result.value);
            }
          );
        });
      };
      body = (await getBody()) || "";
      const mailboxItem = Office.context.mailbox.item;
      if (!mailboxItem || this.outlookState === "Compose") return;
      switch (value) {
        case "STATIC_FROM":
          this.$set(this, "defaultValue", mailboxItem.from.emailAddress);
          break;
        case "STATIC_FROM_NAME":
          this.$set(this, "defaultValue", mailboxItem.from.displayName);
          break;
        case "STATIC_TO":
          const currentUser = mailboxItem.to.find(
            (user) => user.emailAddress === this.currentBotUser.email
          );
          if (!currentUser) {
            this.$set(this, "defaultValue", mailboxItem.to.first.emailAddress);
            break;
          }
          this.$set(this, "defaultValue", currentUser.emailAddress);
          break;
        case "STATIC_CC":
          this.$set(this, "defaultValue", mailboxItem.cc?.first.emailAddress);
          break;
        case "STATIC_BODY":
          this.$set(this, "defaultValue", body);
          break;
        case "STATIC_SUBJECT":
          this.$set(this, "defaultValue", mailboxItem.subject);
          break;
        default:
          return "";
      }
      this.handleChange(this.defaultValue);
    },
    extractStaticValue(value) {
      const interval = setInterval(() => {
        if (this.currentBotUser) {
          switch (value) {
            case "STATIC_JDE_USER":
              this.$set(this, "defaultValue", this.currentBotUser.jde_user);
              break;
            case "STATIC_JDE_COMPANY":
              this.$set(this, "defaultValue", this.currentBotUser.jde_company);
              break;
            case "STATIC_JDE_AB":
              this.$set(this, "defaultValue", this.currentBotUser.ab_number);
              break;
            case "CURRENT_USER_EMAIL":
              this.$set(this, "defaultValue", this.currentBotUser.email);
              break;
            default:
              return "";
          }
          this.handleChange(this.defaultValue);
          clearInterval(interval);
        }
      }, 100);
    },
  },
};
