<template>
  <div>
    <v-autocomplete
      outlined
      :items="items"
      :label="generateLabel"
      :rules="isItRequired"
      v-model="currentValue"
      :readonly="isLoading"
      @change="handleSelection"
      item-text="filter"
      item-value="value"
      dense
      :hint="generateHint"
      :persistent-hint="filteredByField"
      :disabled="isDisabled"
      :append-icon="showRefresh ? 'mdi-refresh' : 'mdi-menu-down'"
      @click:append="fetchItems"
      :value="defaultValue"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          size="24"
          color="primary"
          indeterminate
        />
      </template>
      <template #item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.label }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.filter }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template #selection="data">
        <p class="mb-0">{{ data.item.label }}</p>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import fieldsAPI from "@/api/fields";
export default {
  name: "ORCHField",
  data: () => ({
    isLoading: true,
    items: [],
    filteredByField: false,
    unsubscribe: null,
    masterFieldValue: "",
    currentValue: null,
    unsubscribe: null,
    controllerField: null,
    defaultValueSet: false,
    defaultValue: "",
    showRefresh: false,
    itemsFilters: [],
  }),
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      companySettings: (state) => state.company.settings,
      currentFlow: (state) => state.company.currentFlow,
      currentBotUser: (state) => state.users.currentUser,
      flowData: (state) => state.company.flowData,
      staticValues: (state) => state.company.staticValues,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.requiredAllowEmpty]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    generateHint() {
      const fields = [...this.currentFlow.config.payload_config.root_elements];
      let masterField = null;
      if (this.currentFlow.config.payload_config.array_elements) {
        this.currentFlow.config.payload_config.array_elements.forEach((e) =>
          fields.push(...e.elements)
        );
      }
      if (this.filteredByField) {
        if (
          this.staticValues
            .map((item) => item.value)
            .includes(this.field.linked_attributes.filter_field)
        ) {
          masterField = this.field.linked_attributes.filter_field;
        } else {
          masterField = fields.find(
            (field) => field?.key === this.field.linked_attributes.filter_field
          );
        }
      }
      let hint = "";
      if (
        masterField &&
        !this.staticValues
          .map((item) => item.value)
          .includes(this.field.linked_attributes.filter_field)
      ) {
        hint += `Linked to: ${masterField.published_name}`;
      }
      if (this.field.hint) {
        hint += ` ${this.field.hint}`;
      }

      return hint;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return this.filteredByField && !this.masterFieldValue;
    },
  },
  async created() {
    if (this.field.default) {
      this.$set(this, "defaultValue", this.field.default);
      this.handleSelection(this.defaultValue);
    }
    if (this.field.linked_attributes.filter_by_field) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.flowData,
          this.field.linked_attributes.filter_field
        )
      ) {
        this.masterFieldValue =
          this.flowData[this.field.linked_attributes.filter_field];
        this.itemsFilters = [
          {
            filter_name: this.field.linked_attributes.filter_field_key,
            filter_value: this.masterFieldValue,
          },
        ];
        await this.fetchItems();
      }
      this.subscribeToMasterFieldChanges();
      this.filteredByField = true;
      if (
        this.staticValues
          .map((item) => item.value)
          .includes(this.field.linked_attributes.filter_field)
      ) {
        this.masterFieldValue = this.defaultValue;
        this.itemsFilters = [
          {
            filter_name: this.field.linked_attributes.filter_field_key,
            filter_value: this.masterFieldValue,
          },
        ];
        await this.fetchItems();
        return;
      }
      this.isLoading = false;
    } else {
      await this.fetchItems();
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      this.controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_values
        );
      this.subscribeToMasterFieldChanges();
    },
    handleVisibilityFieldValueChange(mutation) {
      const controlFieldKey = this.controllerField.key;
      const value = mutation.payload[controlFieldKey];
      if (this.field.conditional_visibility_values.includes(value)) {
        this.$emit("manage-field-visibility", {
          visibility: false,
          field: this.field.key,
        });
        return;
      }
      this.$emit("manage-field-visibility", {
        visibility: true,
        field: this.field.key,
      });
    },
    handleFilterFieldValueChange(mutation) {
      const masterField = this.field.linked_attributes.filter_field;
      const orchInput = this.field.linked_attributes.filter_field_key;
      if (this.masterFieldValue === mutation.payload[masterField]) return;
      this.masterFieldValue = mutation.payload[masterField];
      this.masterFieldValue = mutation.payload[masterField];
      this.itemsFilters = [
        { filter_name: orchInput, filter_value: this.masterFieldValue },
      ];
      this.fetchItems();
    },
    subscribeToMasterFieldChanges() {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const masterField = this.field.linked_attributes.filter_field;
          const visibilityField = this.controllerField?.key;
          if (
            Object.prototype.hasOwnProperty.call(mutation.payload, masterField)
          ) {
            this.handleFilterFieldValueChange(mutation);
          }
          if (
            Object.prototype.hasOwnProperty.call(
              mutation.payload,
              visibilityField
            )
          ) {
            this.handleVisibilityFieldValueChange(mutation);
          }
        }
      });
    },
    async fetchItems() {
      this.isLoading = true;
      this.$emit("delete-payload-field", this.field.key);
      const orchObject = this.field.linked_attributes;
      const payload = {
        orchName: orchObject.orch_name,
        user_email: Office.context.mailbox.userProfile.emailAddress,
        orchFilters: this.itemsFilters || [],
        ...this.companySettings,
        ...this.currentFlow,
      };
      const config = {
        orchName: orchObject.orch_name,
        valueColumn: orchObject.value_column || "",
        displayColumn: orchObject.display_column || "",
        filterColumns: orchObject.filter_columns || [],
        nestedFields: orchObject.nested_fields || [],
      };
      try {
        this.items = await fieldsAPI.fetchDataFromOrchestrator(payload, config);
      } catch (error) {
        let message = error.message;
        if (error.response?.data?.result) {
          message = error.response.data.result;
        }
        this.$logError(error);
        this.showSnackbar({
          type: "error",
          message: `Field: ${this.field.published_name}, Error: ${message}`,
        });
        this.showRefresh = true;
      }
      if (this.items[0]) {
        if (typeof this.items[0].value === "string") {
          this.defaultValue = `${this.defaultValue}`;
        }
        if (typeof this.items[0].value === "number") {
          this.defaultValue = parseInt(`${this.defaultValue}`);
        }
      }
      if (this.defaultValue && !this.defaultValueSet && !this.currentValue) {
        this.currentValue = this.defaultValue;
        this.defaultValueSet = true;
      } else {
        this.currentValue = null;
      }
      this.isLoading = false;
    },
    handleSelection(value) {
      this.$emit("selected", { [this.field.key]: value });
    },
  },
};
</script>
