import Vue from "vue";
import VueRouter from "vue-router";
import Search from "@/views/Search.vue";
import FindCustomer from "@/views/FindCustomer.vue";
import GetLastSO from "@/views/GetLastSO.vue";
import CreateSO from "@/views/CreateSO.vue";
import About from "@/views/About.vue";
import Welcome from "@/views/Welcome.vue";
import AttachEmail from "@/views/AttachEmail.vue";
import AutoSuggestion from "@/views/AutoSuggestion.vue";
import AddContact from "@/views/AddContact.vue";
import AvailableFlows from "@/views/AvailableFlows.vue";
import JDEFlow from "@/views/JDEFlow.vue";
import SalesforceFlow from "@/views/SalesforceFlow.vue";
import FileViewer from "@/views/FileViewer.vue";

Vue.use(VueRouter);

const routes = [
  // This is the default route
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  // Do not make this async because file viewing will fail
  {
    path: "/file-viewer",
    name: "FileViewer",
    component: FileViewer,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/findCustomer",
    name: "FindCustomer",
    component: FindCustomer,
  },
  {
    path: "/getLastSO",
    name: "GetLastSO",
    component: GetLastSO,
  },
  {
    path: "/createSO",
    name: "CreateSO",
    component: CreateSO,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/attachEmail",
    name: "AttachEmail",
    component: AttachEmail,
  },
  {
    path: "/auto-suggest",
    name: "AutoSuggest",
    component: AutoSuggestion,
  },
  {
    path: "/add-contact",
    name: "AddContact",
    component: AddContact,
  },
  {
    path: "/flows",
    name: "AvailableFlows",
    component: AvailableFlows,
  },
  {
    path: "/jde-flow",
    name: "JDEFlow",
    component: JDEFlow,
  },
  {
    path: "/sf-flow",
    name: "SalesForceFlow",
    component: SalesforceFlow,
  },
  {
    path: "/flow",
    name: "Flow",
    component: () => import(/* webpackChunkName: "Flow" */ "@/views/Flow.vue"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "Notifications" */ "@/views/Notifications.vue"
      ),
  },
  {
    path: "/app-menus",
    name: "AppMenus",
    component: () =>
      import(/* webpackChunkName: "AppMenus" */ "@/views/AppMenus.vue"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import(/* webpackChunkName: "Menu" */ "@/views/Menu.vue"),
  },

  {
    path: "/approval",
    name: "Approval",
    component: () =>
      import(/* webpackChunkName: "Approval" */ "@/views/Approval.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
