<template>
  <v-app>
    <div class="page-container" v-if="createContactBotflowNotSet">
      <v-alert type="warning" outlined>
        You don't have <b>Outlook Create Contact Botflow</b> specified in your
        organization. Please, contact your organization administrator.
      </v-alert>
    </div>
    <flow v-if="!createContactBotflowNotSet" />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Flow from "./Flow.vue";
export default {
  name: "AddContact",
  components: { Flow },
  data: () => ({
    selectedBotflow: {},
    createContactBotflowNotSet: false,
  }),
  computed: {
    ...mapState({
      companySettings: (state) => state.company.settings,
      botflows: (state) => state.company.botFlows,
    }),
  },
  created() {
    if (this.companySettings.outlook_create_contact_botflow) {
      this.selectedBotflow = this.botflows.find(
        (botflow) =>
          botflow.name === this.companySettings.outlook_create_contact_botflow
      );
      this.$store.commit("company/setCurrentFlow", this.selectedBotflow);
    } else {
      this.createContactBotflowNotSet = true;
    }
  },
};
</script>
