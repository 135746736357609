<template>
  <div>
    <a href="/" aria-label="Home">
      <img
        v-if="!isLoadingLogo"
        :src="brandLogoUrl"
        alt="OrchestratorBot"
        class="logo rounded"
        :class="{ loading: isLoading }"
      />
    </a>
  </div>
</template>

<script>
/**
 * @module components/Logo
 * @description Application Logo
 */
import { mapState } from "vuex";
export default {
  name: "Logo",
  /**
   * @vue prop {Boolean} isLoading
   */
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoadingLogo: false,
    brandLogoUrl: "",
  }),
  computed: {
    ...mapState({
      getLoaderImage: (state) => state.common.loaderImage,
    }),
  },
  async created() {
    if (this.getLoaderImage?.length == 0 || !this.getLoaderImage) {
      this.brandLogoUrl = require("@/../public/logo.png");
      return;
    }
    try {
      this.isLoadingLogo = true;
      const result = await fetch(this.getLoaderImage);
      const blob = await result.blob();
      const url = URL.createObjectURL(blob);
      this.brandLogoUrl = url;
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoadingLogo = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.logo {
  max-width: 150px;
  width: 100%;
  img {
    width: 100%;
  }
  margin: 0.5rem;
  &.loading {
    animation: pulse linear 1.5s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>
