import { getCompanyUrl } from "@/config/endpointsUrl";

const getInstance = (axios) => {
  axios.setBaseURL(getCompanyUrl());
  return axios;
};

export default (axios) => ({
  fetchCompany: async (outlookDomain) => {
    const { data } = await getInstance(axios).get("/settingsbyoutlookid", {
      params: { tenant_id_outlook: outlookDomain },
    });
    return data.settings;
  },
  getConnectors: async (user) => {
    const { data } = await getInstance(axios).get("/connectors", {
      params: { company_id: user.company_id },
      headers: {
        "x-api-key": localStorage.getItem("x-api-key"),
      },
    });
    return data.connectors;
  },
});
