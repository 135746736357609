<template>
  <div>
    <v-text-field
      v-money="money"
      outlined
      :rules="isItRequired"
      :label="generateLabel"
      :value="defaultValue"
      :hint="field.hint"
      :disabled="isDisabled"
      @change="handleChange"
      v-model="inputValue"
      :key="defaultValue"
      dense
    />
  </div>
</template>

<script>
import { VMoney } from "v-money";
import { mapState } from "vuex";
import BaseField from "@/mixins/BaseField";

export default {
  name: "HTML5Money",
  directives: { money: VMoney },
  mixins: [BaseField],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    unsubscribe: null,
    inputValue: "",
  }),
  watch: {
    defaultValue(newVal) {
      if (!newVal) return;
      let amount = 0;
      const possibleDelimiters = [this.money.decimal, ",", "."];
      let currentDelimiter = null;
      for (let i = 0; i < possibleDelimiters.length; i++) {
        const element = possibleDelimiters[i];
        if (newVal.includes(element)) {
          currentDelimiter = element;
          break;
        }
      }
      if (currentDelimiter) {
        const valueAsArray = newVal.split(currentDelimiter);
        let index = 0;
        amount = `${valueAsArray[0]}${currentDelimiter}`;
        while (this.money.precision > index) {
          amount += valueAsArray[1][index];
          index++;
        }
      } else {
        amount = `${newVal}`;
        let index = 0;
        while (this.money.precision > index) {
          amount += "0";
          index++;
        }
      }
      this.inputValue = amount;
      this.handleChange(amount);
    },
  },
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    money() {
      return Object.assign(
        {
          decimal: ".",
          thousands: ",",
          prefix: "",
          suffix: "",
          precision: 2,
        },
        {
          ...this.field.linked_attributes,
          prefix: `${this.field.linked_attributes.symbol} `,
          suffix: ` ${this.field.linked_attributes.currency}`,
        }
      );
    },
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required, ...this.customRules]
        : [...this.customRules];
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    handleChange(value) {
      const parsedValue = value.split("").filter((el) => {
        if (el === this.money.decimal) {
          return true;
        }
        return /[0-9]/.test(el);
      });
      const indexOfDecimalDelimiter = parsedValue.indexOf(this.money.decimal);
      parsedValue.splice(indexOfDecimalDelimiter, 1, ".");
      this.$emit("text-change", { [this.field.key]: parsedValue.join("") });
    },
  },
};
</script>
