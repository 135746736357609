<template>
  <div class="flex justify__center mb-4">
    <v-rating
      :label="generateLabel"
      :rules="isItRequired"
      :value="defaultValue"
      :hint="field.hint"
      length="5"
      size="30"
      hover
      :disabled="isDisabled"
      @input="handleSelection"
    />
  </div>
</template>

<script>
/**
 * @module components/DynamicForm/Fields/RatingField
 * @desc Rating field component of dynamic form
 */
import { mapState } from "vuex";
export default {
  name: "RatingField",
  /**
   * @vue-prop {Object} field
   */
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    defaultValue: 0,
    unsubscribe: null,
  }),
  /**
   * @vue-computed {Boolean} isItRequired
   * @vue-computed {String} generateLabel
   */
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.default) {
        return true;
      }
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.field.default) {
      if (isNaN(this.field.default)) return;
      this.defaultValue = parseInt(this.field.default);
      this.handleSelection(this.defaultValue);
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  /**
   * @vue-event {Object} selected
   */
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField = this.currentFlow.config.payload_config.root_elements.find(
        (field) =>
          field.published_name === this.field.conditional_visibility_input
      );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    /**
     * Called on form change
     * Emits selected event with passed in value as data
     */
    handleSelection(value) {
      this.$emit("selected", { [this.field.key]: `${value}` });
    },
  },
};
</script>
