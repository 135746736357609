import * as XLSX from "xlsx";
import Papa from "papaparse";

const convertToJSON = (workbook) => {
  const headers = [];
  const formattedSpreadsheet = [];
  workbook.SheetNames.forEach((name) => {
    const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[name]);
    const mappedSheet = sheet.map((row) => {
      const obj = {};
      Object.keys(row).forEach((key) => {
        if (!headers.includes(key)) {
          headers.push(key);
        }
        obj[key.replace(/ /gi, "_")] = row[key];
      });
      return obj;
    });
    formattedSpreadsheet.push(mappedSheet);
  });
  return { formattedSpreadsheet, headers, workbook };
};

export const convertCSVToJSON = (file, generateHeaders) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: generateHeaders === false,
      complete: (results) => {
        let records = results.data;
        if (generateHeaders) {
          const fieldsCount = records.reduce(
            (entry, max) => (entry > max.length ? entry : max.length),
            0
          );
          const headers = Array.from(
            Array(fieldsCount),
            (x, i) => `Column ${i + 1}`
          );
          const recordsWithHeaders = records.map((record) => {
            const object = {};
            headers.forEach((head, i) => {
              object[head] = record[i];
            });
            return object;
          });
          records = recordsWithHeaders;
        }

        if (results.errors.length) {
          console.error(JSON.stringify(results.errors));
        }
        const fatalError = results.errors.find(
          (err) => err.code === "MissingQuotes"
        );
        if (fatalError) {
          reject(new Error(fatalError.message));
        }
        const workbook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(records);
        XLSX.utils.book_append_sheet(workbook, ws, "Converted from CSV");
        resolve(convertToJSON(workbook));
      },
    });
  });
};
