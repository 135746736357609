import sfAPI from "@/api/salesforce";

export default {
  state: () => ({
    sfObjectFields: [],
    isFetchingSFObjectFields: false,
  }),

  mutations: {
    setSFObjectFields(state, payload) {
      state.sfObjectFields = payload;
    },
    setIsFetchingSFObjectFields(state, payload) {
      state.isFetchingSFObjectFields = payload;
    },
  },
  actions: {
    async fetchSFObjectFields(
      { commit, rootState },
      { sfObject, connectorName }
    ) {
      commit("setIsFetchingSFObjectFields", true);
      const currentUser = rootState.users.currentUser;
      const fields = await sfAPI.fetchSFObjectFields({
        ...currentUser,
        connector: connectorName,
        sfObject,
      });
      const mappedFields = fields.map((field) => {
        const baseField = {
          ...field,
          description: field.label,
        };
        if (field.type === "reference") {
          baseField.referenceTo = field.referenceTo;
        }
        return baseField;
      });

      commit("setSFObjectFields", mappedFields);
      commit("setIsFetchingSFObjectFields", false);
    },
  },
  namespaced: true,
};
