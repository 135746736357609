require("es6-promise").polyfill();
// Transpiler for older browsers
import "core-js/stable";
// optional but required for transforming generator fns.
import "regenerator-runtime/runtime";
import Vue from "vue";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import vuetify from "@/plugins/vuetify";
import pubNubInstace from "@/plugins/pubnub.js";
import i18n from "@/plugins/i18n";
import api from "@/plugins/api";
import "@/plugins/vue-currency-filter";

import "@/scss/main.scss";

import { formValidationMixin } from "@/mixins/FormValidation";
import { version } from "../package.json";
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;
router.replace("/");

Vue.mixin(formValidationMixin);
Vue.prototype.$pubNub = pubNubInstace;

Sentry.init({
  Vue,
  dsn: "https://4ebee8923d2e4e9193c24e6e458a3019@o881318.ingest.sentry.io/4504966243680256",
  replaysSessionSampleRate: 0.0,
  enabled: process.env.VUE_APP_ENV !== "dev",
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.5,
  release: "orchestratorbot-outlook@" + version,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "localhost",
        "outlook.orchestratorbot.com",
        /^\//,
      ],
    }),
  ],
});

Vue.prototype.$logError = (error) => {
  if (process.env.VUE_APP_ENV === "dev") {
    console.error(error);
    return;
  }
  Sentry.captureException(error);
};

Vue.prototype.$sentry = Sentry;
const theme = localStorage.getItem("theme");
if (theme === "dark") {
  vuetify.framework.theme.isDark = true;
  document.documentElement.classList.add("dark-mode");
  document.documentElement.classList.remove("light-mode");
} else {
  vuetify.framework.theme.isDark = false;
  document.documentElement.classList.add("light-mode");
  document.documentElement.classList.remove("dark-mode");
}
Vue.use(api);
localStorage.removeItem("isFileViewer");
const isFileViewer = location.href.includes("isViewer=true");
if (isFileViewer) {
  localStorage.setItem("isFileViewer", true);
  router.replace("/file-viewer");
}
window.Office.onReady()
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((err) => {
    console.error("Office.js was not able to load due to: ", err);
  });
