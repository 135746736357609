export const generateMoKey = (item, fileMeta) => {
  const strippedMappedFieds = fileMeta.moKeyRaw.map((item) => {
    const key = Object.keys(item).find((key) => key !== "type");
    const fieldName = item[key];
    if (item.type === "static") {
      return fieldName;
    }
    // TODO: We should modify the identifier of response fields that are part of an array []
    if (fieldName.split("]").length > 2) {
      return fieldName.slice(fieldName.indexOf("]") + 1);
    }
    return fieldName.split("]")[1];
  });
  let moKeyPiped = "";
  strippedMappedFieds.forEach((field) => {
    moKeyPiped += `${item[field] || field}|`;
  });
  return moKeyPiped.slice(0, -1);
};

export const setCompanyColors = (currentThemeColors) => {
  if (!currentThemeColors) return;
  Object.keys(currentThemeColors).forEach((key) => {
    const color = currentThemeColors[key];
    document.documentElement.style.setProperty(key, color);
  });
};
