export default {
  state: () => ({
    snackbars: [],
    snackbar: {
      type: "",
      message: "",
      timeout: 5000,
    },
    outlookState: "Read",
    loaderImage: null,
  }),
  mutations: {
    addSnackbar(state, payload) {
      state.snackbars.push(payload);
    },
    removeSnackbar(state, payload) {
      const snackbar = state.snackbars.find(
        (snackbar) => snackbar.id === payload.id
      );
      const index = state.snackbars.indexOf(snackbar);
      state.snackbars.splice(index, 1);
      if (snackbar.onClose) {
        snackbar.onClose(snackbar);
      }
    },
    setOutlookState(state, payload) {
      state.outlookState = payload;
    },
    setLoaderImage(state, payload) {
      state.loaderImage = payload;
    },
  },
  actions: {
    showSnackbar({ commit, state }, payload) {
      const snackbar = Object.assign(
        { id: Date.now() },
        state.snackbar,
        payload
      );
      commit("addSnackbar", snackbar);
      if (!snackbar.timeout) {
        setTimeout(() => {
          commit("removeSnackbar", snackbar);
        }, snackbar.timeout);
      }
    },
  },
  namespaced: true,
};
