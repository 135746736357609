<template>
  <div>
    <!-- <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          :label="generateLabel"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          dense
          outlined
          :rules="isItRequired"
          @change="handleDatePicked"
          v-on="on"
          :hint="field.hint"
          :disabled="isDisabled"
        />
      </template>
      <v-date-picker
        v-model="date"
        color="light-blue"
        header-color="primary"
        @change="handleDatePicked"
        @input="menu = false"
        full-width
      />
    </v-menu> -->
    <v-text-field
      v-model="formattedDate"
      :label="generateLabel"
      prepend-inner-icon="mdi-calendar"
      readonly
      outlined
      dense
      :rules="isItRequired"
      :hint="field.hint"
      :disabled="isDisabled"
      @change="handleDatePicked"
      @click="dateDialog = true"
    />
    <v-dialog v-model="dateDialog" persistent max-width="320">
      <v-sheet class="d-flex flex-column">
        <transition-group>
          <v-date-picker
            v-show="currentPicker === 'date'"
            key="date"
            v-model="date"
            color="light-blue"
            header-color="primary"
            width="320"
            @change="handleDateSelection"
          />
          <v-time-picker
            v-show="currentPicker === 'time'"
            key="time"
            v-model="time"
            color="light-blue"
            header-color="primary"
            format="24hr"
            width="320"
            use-seconds
          />
        </transition-group>
        <div class="d-flex mt-2 pa-2 justify-space-between">
          <v-btn outlined color="error" @click="closeDialog"> Cancel </v-btn>
          <v-btn
            v-if="hasTimeEnabled"
            outlined
            color="blue dark-2"
            class="mx-2"
            @click="switchPicker"
          >
            Switch
          </v-btn>
          <v-btn outlined color="success" @click="saveDate"> Save </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @module components/DynamicForm/Fields/DateField
 * @desc Static field component of dynamic form
 */
import { DateTime } from "luxon";
import fieldsAPI from "@/api/fields";
import { mapState } from "vuex";
export default {
  name: "DateField",
  /**
   * @vue-prop {Object} field
   * @vue-prop {String|null} value
   */
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, null],
      default: null,
    },
  },
  data: () => ({
    dateDialog: false,
    date: "",
    format: "",
    formattedDate: "",
    unsubscribe: null,
    currentPicker: "date",
    time: "",
  }),
  /**
   * @vue-computed {Array|undefined} isItRequired
   * @vue-computed {String} generateLabel
   */
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
    hasTimeEnabled() {
      return this.field.linked_attributes?.time;
    },
  },
  created() {
    this.fetchDateFormat();
    if (this.field.default) {
      this.date = this.field.default;
      this.handleDatePicked(this.field.default);
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  /**
   * @vue-event {Object} selected
   */
  methods: {
    closeDialog() {
      this.date = "";
      this.time = "";
      this.currentPicker = "date";
      this.dateDialog = false;
    },
    saveDate() {
      if (this.hasTimeEnabled) {
        this.handleDatePicked(`${this.date}T${this.time}`);
      } else {
        this.handleDatePicked(`${this.date}`);
      }
      this.dateDialog = false;
      this.currentPicker = "date";
    },
    switchPicker() {
      if (this.currentPicker === "time") {
        this.currentPicker = "date";
        return;
      }
      this.currentPicker = "time";
    },
    handleDateSelection() {
      if (this.hasTimeEnabled) {
        this.currentPicker = "time";
      }
    },
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    /**
     * Caled on form change
     * Emits selected event with passed in value as data
     * @param {String} value
     */
    handleDatePicked(value) {
      if (this.currentFlow.type === "SALESFORCE") {
        this.formattedDate = DateTime.fromISO(value).toFormat(
          `yyyy-MM-dd${this.hasTimeEnabled ? ', HH:mm:ss"' : ""}`
        );
        this.$emit("selected", {
          [this.field.key]: this.hasTimeEnabled
            ? DateTime.fromISO(value).toISO()
            : DateTime.fromISO(value).toFormat("yyyy-MM-dd"),
        });
        return;
      } else {
        this.formattedDate = DateTime.fromISO(value).toFormat(this.format);
      }
      this.$emit("selected", { [this.field.key]: this.formattedDate });
    },
    /**
     * Fetching the format to be used when submitting the data to BE
     * @async
     */
    async fetchDateFormat() {
      try {
        const dateFormat = await fieldsAPI.fetchDateFormat(
          this.$store.state.users.currentUser
        );
        this.format = dateFormat;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
