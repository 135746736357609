<template>
  <div class="name-wrapper">
    <p>
      {{ getDescription }}
    </p>
  </div>
</template>

<script>
export default {
  name: "HTML5Description",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDescription() {
      return this.field.linked_attributes?.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.name-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  p {
    text-align: center;
    max-width: 400px;
    margin-bottom: ($page-spacing / 2);
  }
}
</style>
