<template>
  <div id="viewer" ref="viewer" class="pdfjs-container" />
</template>

<script>
export default {
  name: "PDFViewer",
  props: {
    fileDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    PDFJSExpressInstance: {},
  }),
  mounted() {
    const url = this.fileDetails.fileContent?.size
      ? URL.createObjectURL(this.fileDetails.fileContent)
      : this.fileDetails.fileContent;
    const PDFJSExpress = require("@pdftron/pdfjs-express-viewer").default;
    const webViewer = document.getElementById("viewer");
    PDFJSExpress(
      {
        path: "/pdfjs-express",
        licenseKey: process.env.VUE_APP_PDFJS_EXPRESS_API_KEY,
        disableLogs: true,
        fullAPI: false,
        disabledElements: [
          "filePickerButton",
          "toggleNotesButton",
          "ribbons",
          "panToolButton",
          "viewControlsButton",
          "selectToolButton",
          "leftPanelButton",
          "contextMenuPopup",
          "textPopup",
          "toolsHeader",
        ],
        initialDoc: url,
      },
      webViewer
    ).then((instance) => {
      this.PDFJSExpressInstance = instance;
      const iframeDoc = instance.iframeWindow.document;
      iframeDoc.querySelector(
        ".App"
      ).style = `--document-background-color: ${"#1f2937;"}; border-radius: 6px;`;
      this.customize();
    });
  },
  methods: {
    customize() {
      const LayoutMode = this.PDFJSExpressInstance.LayoutMode;
      this.PDFJSExpressInstance.setLayoutMode(LayoutMode.Single);
      this.PDFJSExpressInstance.setTheme("dark");
    },
  },
};
</script>

<style scoped>
.pdfjs-container {
  height: 80vh;
}
</style>
