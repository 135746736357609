<template>
  <div class="pa-2" :key="getCurrentFlowName">
    <v-alert v-if="noStandaloneExecution" class="mb-8" outlined type="warning">
      This botflow cannot be executed standalone! It depends on data coming from
      associated botflow!
    </v-alert>
    <div v-if="isAuthenticated">
      <dynamic-form
        v-if="!isSubmitted && !fetchingLinkedData && !tableResponseDialog"
        ref="dynamicForm"
        :fields="currentFlow.config.payload_config"
        :disable-execution="noStandaloneExecution"
        @submitted="handleSubmittion"
      />
      <div
        v-if="fetchingLinkedData"
        class="flex flex__column justify__center align__center"
      >
        <v-progress-circular size="35" indeterminate color="primary" />
        <h3 class="mt-4">Fetching linked data!</h3>
      </div>
      <div v-if="isSubmitted">
        <v-alert
          color="light-blue lighten-1"
          outlined
          v-if="responseAsArray.length"
        >
          <p
            class="theme-text-color mb-2"
            v-for="row in responseAsArray"
            :key="row.value"
          >
            {{ row.key }}: <span class="green--text">{{ row.value }}</span>
          </p>
        </v-alert>
        <div class="flex justify__center align__center">
          <v-tooltip bottom v-if="isComposeMode">
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="mr-4"
                v-on="on"
                x-large
                @click="addToEmailBody"
                v-bind="attrs"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Add to email body</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="mr-4"
                v-on="on"
                x-large
                @click="resubmit"
                v-bind="attrs"
              >
                mdi-plus-circle-outline
              </v-icon>
            </template>
            <span>Add new</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                x-large
                color="primary"
                v-on="on"
                @click="close"
                v-bind="attrs"
              >
                mdi-microsoft-xbox-controller-menu
              </v-icon>
            </template>
            <span>Return to menu</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div v-if="!isAuthenticated">
      <v-alert type="error" outlined>
        You are not authorized to execute this Flow!
      </v-alert>
    </div>
    <v-dialog
      max-width="800"
      width="100%"
      persistent
      v-model="additionalDataDialog"
    >
      <additional-data
        :response-as-array="responseAsArray"
        @close="close"
        :file-meta="fileMeta"
      />
    </v-dialog>
    <div v-if="tableResponseDialog">
      <response-table :key="updateTableData" :response="tableData">
        <template
          #actions="{ item }"
          v-if="hasFileMeta || hasLinkedFlow || hasGridAction"
        >
          <v-tooltip bottom v-if="isComposeMode">
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="mr-4"
                v-on="on"
                @click="addToEmailBodyFromTable(item)"
                v-bind="attrs"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Add to Email body</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="hasFileMeta"
                class="mr-2"
                color="light-blue"
                @click="showMODialog(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-archive-eye-outline
              </v-icon>
            </template>
            <span>View Files</span>
          </v-tooltip>
          <v-menu
            v-if="hasLinkedFlow && !item.isLoading"
            open-on-hover
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-icon class="mr-2" color="light-blue" v-bind="attrs" v-on="on">
                mdi-link-variant
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="linkedFlow in associatedBotflows.linked_botflows"
                :key="linkedFlow.name"
                dense
                @click="linkRowData(item, linkedFlow)"
              >
                <v-list-item-title>
                  Create Link {{ linkedFlow.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="hasGridAction && !item.isLoading"
            open-on-hover
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-icon class="mr-2" color="light-blue" v-bind="attrs" v-on="on">
                mdi-cube-send
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="linkedFlow in associatedBotflows.grid_action_botflows"
                :key="linkedFlow.name"
                dense
                @click="triggerGridAction(item, linkedFlow)"
              >
                <v-list-item-title>
                  Grid Action
                  {{ linkedFlow.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-progress-circular
            v-if="item.isLoading"
            size="22"
            indeterminate
            color="primary"
          />
        </template>
      </response-table>
    </div>
    <authenticate-jde @authenticated="handleUserAuthenticated" />
    <view-files v-if="hasFileMeta" ref="viewFiles" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import DynamicForm from "@/components/DynamicForm";
import AdditionalData from "@/components/AdditionalData";
import AuthenticateJDE from "@/components/AuthenticateJDE.vue";
import ResponseTable from "@/components/ResponseTable";
import ViewFiles from "@/components/ViewFiles.vue";
import { generateMoKey } from "@/utils/common";
export default {
  name: "Flow",
  components: {
    DynamicForm,
    AdditionalData,
    ResponseTable,
    ViewFiles,
    "authenticate-jde": AuthenticateJDE,
  },
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.query).length) {
      const query = to.query;
      if (query.from === "notification") {
        next((vm) => {
          vm.linkedExecution = true;
          vm.fromWebhook = !!query.webhook;
          vm.notificationId = query.id;
        });
        return;
      }
      if (query.from === "grid") {
        next((vm) => {
          vm.gridExecution = true;
        });
        return;
      }
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("requires-auth");
    localStorage.removeItem("JDEToken");
    if (this.setToDone || !this.linkedExecution) {
      next();
      return;
    }
    this.updateLinkedInformationStatus("PENDING");
    next();
  },
  data: () => ({
    additionalDataDialog: false,
    fileMeta: {},
    tableResponseDialog: false,
    tableData: {},
    updateTableData: false,
    isSubmitted: false,
    isAuthenticated: false,
    associatedBotflows: {},
    setToDone: false,
    notificationId: 0,
    fetchingLinkedData: false,
    linkedExecution: false,
    gridExecution: false,
    fromWebhook: false,
    responseAsArray: [],
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      outlookState: (state) => state.common.outlookState,
      selectedConnector: (state) => state.connectors.selectedConnector,
      botUsers: (state) => state.users.botUsers,
    }),
    ...mapGetters({
      availableFlows: "company/getAvailableBotFlows",
    }),
    isDark() {
      return this.$vuetify.theme.isDark;
    },
    hasFileMeta() {
      return Object.keys(this.fileMeta).length > 0;
    },
    hasLinkedFlow() {
      return this.associatedBotflows.linked_botflows?.length > 0;
    },
    hasGridAction() {
      return this.associatedBotflows.grid_action_botflows?.length > 0;
    },
    getLinkedBotflowName() {
      return this.associatedBotflows.linked_botflow?.name;
    },
    getGridActionBotflowName() {
      return this.associatedBotflows.grid_action_botflow?.name;
    },
    noStandaloneExecution() {
      const mainConfig = this.currentFlow.config.main_config;
      if (
        !this.gridExecution &&
        !this.linkedExecution &&
        (mainConfig.no_standalone_execution_ga ||
          mainConfig.no_standalone_execution_lb)
      ) {
        return true;
      }
      return false;
    },
    isComposeMode() {
      return this.outlookState === "Compose";
    },
    getCurrentFlowName() {
      if (!this.currentFlow) return "";
      return this.currentFlow.name;
    },
  },
  created() {
    if (this.currentFlow?.config.main_config?.has_template_option) {
      this.$store.dispatch(
        "templates/fetchTemplatesPerBotflow",
        this.currentFlow.id
      );
    }
  },
  async mounted() {
    if (this.currentFlow.config.main_config.auth_on_exec) {
      this.isAuthenticated = false;
    } else {
      this.isAuthenticated = true;
    }
    if (this.$route.query.from === "notification") {
      this.linkedExecution = true;
    }
    if (
      (this.currentFlow.config.main_config.linked_botflow ||
        this.fromWebhook) &&
      this.linkedExecution
    ) {
      await this.getLinkedBotflowData();
    }
    if (
      this.currentFlow.config.main_config.has_grid_action &&
      this.gridExecution
    ) {
      this.checkForGridData();
    }
    this.fetchAssociatedLinks();
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
      updateNotification: "notifications/updateNotification",
    }),
    ...mapMutations({
      setCurrentFlow: "company/setCurrentFlow",
      setLinkedData: "company/setLinkedData",
    }),
    async addToEmailBodyFromTable(item) {
      try {
        Office.context.mailbox.item.body.prependAsync(
          `<p> ${Object.keys(item)
            .filter((key) => key !== "search")
            .map((key) => key + ": " + item[key])
            .join("<br/>")} </p>`,
          {
            coercionType: Office.CoercionType.Html,
            asyncContext: {},
          },
          function (asyncResult) {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              throw new Error(asyncResult.error.message);
            }
          }
        );
      } catch (error) {
        this.$logError(error);
      }
    },
    async addToEmailBody() {
      try {
        Office.context.mailbox.item.body.prependAsync(
          `<p>${this.responseAsArray
            .map((res) => res.key + ": " + res.value)
            .join("<br/>")}</p>`,
          {
            coercionType: Office.CoercionType.Html,
            asyncContext: {},
          },
          function (asyncResult) {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              throw new Error(asyncResult.error.message);
            }
          }
        );
      } catch (error) {
        this.$logError(error);
      }
    },
    resubmit() {
      this.$store.commit("company/setFlowData", {});
      this.isSubmitted = false;
      this.fileMeta = {};
      this.tableData = {};
      this.responseAsArray = [];
    },
    parseResponseAsString(response) {
      if (!response) return;
      const mappedResponse =
        this.currentFlow.config.main_config.response_mapping;
      if (mappedResponse && mappedResponse?.length) {
        mappedResponse.forEach((field) => {
          this.responseAsArray.push({
            key: field.label,
            value: response[field.value],
          });
        });
      } else {
        Object.keys(response).forEach((key) => {
          this.responseAsArray.push({
            key: key,
            value: response[key],
          });
        });
      }
    },
    navigateBackToGrid() {
      const linkedTableFlow =
        this.currentFlow.config.main_config.grid_action_botflow;
      const botflowToRedirect = this.availableFlows.find(
        (botflow) => botflow.name == linkedTableFlow
      );
      this.gridExecution = false;
      this.setCurrentFlow(botflowToRedirect);
      localStorage.removeItem("grid-information");
    },
    checkForGridData() {
      const gridData = window.localStorage.getItem("grid-information");
      if (gridData.length) {
        const parsedGridData = JSON.parse(gridData);
        this.currentFlow.config.payload_config.root_elements.forEach(
          (field) => {
            if (parsedGridData[field.key]) {
              field.default = parsedGridData[field.key];
            }
          }
        );
      }
    },
    triggerGridAction(row, gridActionFlow) {
      const extractedInformation = {};
      const rowKeys = Object.keys(row);
      gridActionFlow.config.payload_config.root_elements.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(field, "grid_action_value")) {
          const currentKey = rowKeys.find((key) =>
            field.grid_action_value.includes(key)
          );
          extractedInformation[field.key] = row[currentKey];
        }
      });
      localStorage.setItem(
        "grid-information",
        JSON.stringify(extractedInformation)
      );
      this.setCurrentFlow(gridActionFlow);
      this.gridExecution = true;
      this.tableResponseDialog = false;
      this.checkForGridData();
    },
    showMODialog(item) {
      const moKey = generateMoKey(item, this.fileMeta);
      this.$refs.viewFiles.showDialog(moKey);
    },
    async linkRowData(item, linkedFlow) {
      this.$set(item, "isLoading", true);
      const result = {};
      this.currentFlow.config.main_config.response_mapping.forEach((map) => {
        const parentBracketEnd = map.value.indexOf("]");
        result[map.value] = item[map.value.slice(parentBracketEnd + 1)];
      });
      await this.storeLinkedData(result, linkedFlow);
      this.$set(item, "isLoading", false);
    },
    async fetchAssociatedLinks() {
      this.associatedBotflows = await this.$api.config.checkForActiveLinks({
        connector_id: this.selectedConnector.connector_id,
        botflow_name: this.getCurrentFlowName,
      });
    },
    async storeLinkedData(data, linkedBotflow) {
      try {
        const notificationRecipients = [];
        linkedBotflow.config.main_config.notification_recipients.forEach(
          (email) => {
            const botUser = this.botUsers.find((user) => user.email === email);
            notificationRecipients.push({
              email,
              notification_channels: botUser.notification_channels || [],
            });
          }
        );
        await this.$api.config.storeLinkedData({
          company_id: this.currentUser.company_id,
          user_id: this.currentUser.bot_user_id,
          link_name: `${this.getCurrentFlowName}|-|-|${linkedBotflow.name}`,
          linked_data: data,
          connector_id: this.selectedConnector.id,
          type: "LINKEAGE",
          notification_recipients: notificationRecipients,
        });
        this.showSnackbar({
          type: "success",
          message:
            "Linking was successfull, please use Teams or our App to activate the linking!",
        });
      } catch (error) {
        this.$logError(error);
        this.showSnackbar({ type: "error", message: "Linking failed!" });
      }
    },
    handleUserAuthenticated(token) {
      if (this.$refs.dynamicForm) {
        this.$refs.dynamicForm.token = token;
      }
      this.isAuthenticated = true;
    },
    async getLinkedBotflowData() {
      try {
        this.fetchingLinkedData = true;
        this.notificationId = this.$route.query.id;
        const mainConfig = this.currentFlow.config.main_config;
        let linkName = `${mainConfig.linked_botflow}|-|-|${this.getCurrentFlowName}`;
        if (this.fromWebhook) {
          linkName = `WEBHOOK|-|-|${this.getCurrentFlowName}`;
        }
        const linkedInfromation = await this.$api.config.getLinkedData({
          company_id: this.currentUser.company_id,
          link_name: linkName,
          id: parseInt(this.notificationId),
        });
        if (!linkedInfromation) return;

        this.currentFlow.config.payload_config.root_elements.forEach(
          (field) => {
            if (field.linked_value) {
              field.default = `${
                linkedInfromation.linked_data[field.linked_value]
              }`;
            }
            const mappedFieldValue = linkedInfromation.linked_data[field.key];
            if (!mappedFieldValue) return;
            if (this.fromWebhook) {
              field.protected = true;
              field.default = `${mappedFieldValue}`;
            }
          }
        );
        this.setLinkedData(linkedInfromation);
        await this.updateLinkedInformationStatus("IN PROGRESS");
      } catch (error) {
        this.$logError(error);
      } finally {
        this.fetchingLinkedData = false;
      }
    },
    async updateLinkedInformationStatus(status) {
      if (this.setToDone) return;
      try {
        await this.updateNotification({
          status,
          id: parseInt(this.notificationId),
        });
      } catch (error) {
        this.$logError(error);
      }
    },
    async handleSubmittion(payload) {
      const { response } = payload;
      if (response.tableData) {
        this.fileMeta = response.fileMeta || {};
        this.tableData = response.tableData;
        this.updateTableData = !this.updateTableData;
        this.tableResponseDialog = true;
        return;
      }
      if (this.hasLinkedFlow) {
        this.storeLinkedData(response.result);
      }
      this.parseResponseAsString(response.result);
      this.isSubmitted = true;
      if (response.fileMeta) {
        this.additionalDataDialog = true;
        this.fileMeta = response.fileMeta;
      }
      if (this.linkedExecution || this.fromWebhook) {
        this.updateLinkedInformationStatus("DONE");
        this.setToDone = true;
      }
    },
    close() {
      this.additionalDataDialog = false;
      if (this.gridExecution) {
        this.navigateBackToGrid();
        return;
      }
      this.$router.push("/app-menus");
    },
  },
};
</script>
