<template>
  <div class="page-container" v-if="currentFlow">
    <div v-if="!hideForm">
      <h3 class="text__center mb-3">{{ currentFlow.name }}</h3>
      <p class="text__center">{{ currentFlow.description }}</p>
      <v-form ref="poForm">
        <v-autocomplete
          outlined
          dense
          v-model="form.headerBranchPlant"
          label="Company"
          :items="headerBranchPlantItems"
          :rules="[rules.required]"
          item-text="short_company"
          return-object
          @change="handleHeaderBranchChange"
          :loading="isFetchingBranchData"
        />
        <v-select
          outlined
          dense
          v-model="form.poType"
          label="PO Type"
          item-text="title"
          item-value="value"
          :items="poTypes"
          @change="handlePOTypeChange"
          :disabled="!form.headerBranchPlant"
          :rules="[rules.required]"
        />
        <v-autocomplete
          outlined
          dense
          v-model="form.detailBranchPlant"
          label="Cost Center/Project"
          :items="detailBranchPlantItems"
          :rules="[rules.required]"
          item-text="label"
          return-object
          :disabled="!form.headerBranchPlant"
          :loading="isFetchingDetailBranchData"
        />
        <v-select
          outlined
          dense
          v-model="form.currencyCode"
          label="Currency Code"
          :items="currencyCodes"
          item-text="title"
          item-value="value"
          :disabled="!form.headerBranchPlant"
          :rules="[rules.required]"
        />
        <v-autocomplete
          outlined
          dense
          v-model="form.supplier"
          label="Supplier"
          :items="suppliers"
          item-text="searchLabel"
          item-value="returnAB8"
          :disabled="!form.headerBranchPlant"
          :rules="[rules.required]"
          :loading="isFetchingSuppliers"
        >
          <template v-slot:selection="{ item }">
            <span>{{ item.returnALPH }}</span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.returnALPH"></v-list-item-title>
              <v-list-item-subtitle>
                {{ generateAdrress(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-textarea
          outlined
          dense
          v-model="form.poDescription"
          label="PO Description"
          rows="3"
          hide-details
          :disabled="!form.headerBranchPlant"
          class="mb-5"
        />
        <h2 class="mb-4">Details</h2>
        <v-expansion-panels class="mb-5" :disabled="!form.headerBranchPlant">
          <v-expansion-panel
            outlined
            :key="index"
            v-for="(line, index) in detailData"
          >
            <v-expansion-panel-header>
              <div class="flex justify__between align__center">
                <h3>PO Line #{{ index + 1 }}</h3>
                <v-icon
                  v-if="index > 0"
                  @click="deleteLine($event, index)"
                  color="red"
                  class="mr-3"
                >
                  mdi-delete-outline
                </v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-autocomplete
                outlined
                dense
                v-model="line.poItem"
                :items="poItems"
                :rules="[rules.required]"
                label="PO Item"
                :loading="isFetchingPOItems"
              />
              <v-text-field
                outlined
                dense
                v-model="line.quantity"
                type="number"
                :rules="[rules.required]"
                label="Quantity"
              />
              <v-text-field
                outlined
                dense
                v-model="line.price"
                type="text"
                :rules="[rules.required, rules.biggerThanZero]"
                label="Price"
                v-money="money"
                @keydown="handleKeyDown"
                :key="updatePriceFields"
              />
              <v-textarea
                outlined
                dense
                v-model="line.lineDescription"
                label="Line Description"
                rows="3"
                hide-details
                class="mb-5"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <div class="flex justify__end mb-5">
        <v-btn color="accent" @click="addLine">add line</v-btn>
      </div>
      <div>
        <v-checkbox v-model="attachEmailToJDE" label="Attach email to JDE" />
      </div>
      <div>
        <v-btn block color="primary" :loading="isCreatingPO" @click="createPO">
          submit
        </v-btn>
      </div>
    </div>
    <v-snackbar
      :timeout="-1"
      v-model="showSnackbar"
      absolute
      :color="snackbar.type"
      top
      right
      width="350px"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon color="white" v-bind="attrs" @click="handleSnackbarClose">
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import jdeAPI from "@/api/jde";
import { VMoney } from "v-money";
import currencies from "@/assets/currencies.js";
import axios from "axios";
export default {
  name: "SelectedFlow",
  directives: { money: VMoney },
  data: () => ({
    payloadConfig: [],
    form: {},
    headerBranchPlantItems: [],
    detailBranchPlantItems: [],
    isFetchingBranchData: true,
    isFetchingSuppliers: false,
    isFetchingDetailBranchData: false,
    isFetchingPOItems: false,
    attachEmailToJDE: false,
    isCreatingPO: false,
    showSnackbar: false,
    snackbar: {
      type: "",
      message: "",
    },
    poTypes: [
      {
        title: "PO General Expenses",
        value: "potype1",
      },
      {
        title: "PO Site Expenses",
        value: "potype2",
      },
    ],
    currencyCodes: currencies,
    poItems: [],
    suppliers: [],
    updatePriceFields: false,
    hideForm: false,
    detailData: [
      {
        quantity: 0,
      },
    ],
    money: {
      decimal: ".",
      thousands: ",",
      prefix: "",
      suffix: "",
      precision: 2,
    },
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    rules() {
      return {
        required: (v) => {
          if (v === 0) return true;
          return !!v || "This field is required";
        },
        lessThan10k: (v) => {
          if (!v) return "This field is required";
          const value = v.replace(/[^0-9]/g, "");
          if (parseInt(value.substring(0, value.length - 2)) > 10000) {
            return "Value should be less than 10.000";
          }
          return true;
        },
        biggerThanZero: (v) => {
          return parseInt(v) > 0 || "Value should be bigger than 0";
        },
      };
    },
  },
  methods: {
    handleKeyDown(event) {
      if (event.keyCode === 189 || event.keyCode === 109) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    handleSnackbarClose() {
      if (this.snackbar.type === "success") {
        this.showSnackbar = false;
        this.$router.replace("/");
        return;
      }
      this.showSnackbar = false;
    },
    async attachEmail(attachTo) {
      let ewsData = {};
      Office.context.mailbox.getCallbackTokenAsync(
        { isRest: true },
        async (result) => {
          const ewsId = Office.context.mailbox.item.itemId;
          const token = result.value;
          const restId = Office.context.mailbox.convertToRestId(
            ewsId,
            Office.MailboxEnums.RestVersion.v2_0
          );
          const getMailUrl =
            Office.context.mailbox.restUrl +
            "/v2.0/me/messages/" +
            restId +
            "/$value";

          const headers = { Authorization: "Bearer " + token };
          const options = { headers: headers };
          ewsData.ewsId = ewsId;
          ewsData.url = getMailUrl;
          ewsData.token = token;
          ewsData.restId = restId;
          try {
            const response = await axios.get(getMailUrl, options);
            let postDataAttachment = {
              mail_content_v2: response.data,
              action: "mail_attach",
              email_id: ewsData.ewsId,
              email_rest: ewsData.restId,
              attach_to: attachTo,
              environment: this.currentFlow.name.split("_")[1],
              source: "JDE",
            };

            await axios.post(
              "https://s3skv5gm1l.execute-api.us-east-1.amazonaws.com/production/api/outlookbot",
              postDataAttachment
            );
          } catch (error) {
            this.snackbar = {
              type: "error",
              message: error.message,
            };
          }
        }
      );
    },
    generateAdrress(supplier) {
      return `${supplier.returnCTR}_${supplier.returnCTY1}_${supplier.returnADD1}`;
    },
    handlePOTypeChange() {
      this.fetchPOItems();
      this.fetchDetailBranchData();
    },
    async fetchPOItems() {
      this.isFetchingPOItems = true;
      try {
        this.poItems = await jdeAPI.fetchPOItems({
          flowName: this.currentFlow.name,
          poType: this.form.poType,
        });
      } catch (error) {
        console.log(error);
      }
      this.isFetchingPOItems = false;
    },
    async fetchSuppliers() {
      this.isFetchingSuppliers = true;
      try {
        this.suppliers = await jdeAPI.fetchSuppliers({
          flowName: this.currentFlow.name,
          bu: this.form.headerBranchPlant.bu_security,
        });
      } catch (error) {
        console.log(error);
      }
      this.isFetchingSuppliers = false;
    },
    async fetchHeaderData() {
      this.isFetchingBranchData = true;
      try {
        this.headerBranchPlantItems = await jdeAPI.fetchHeaderData(
          this.currentFlow.name
        );
      } catch (error) {
        console.log(error);
      }
      this.isFetchingBranchData = false;
    },
    async fetchDetailBranchData() {
      this.isFetchingDetailBranchData = true;
      try {
        if (this.form.poType === "potype1") {
          this.detailBranchPlantItems = await jdeAPI.fetchDetailBranchPlant({
            flowName: this.currentFlow.name,
            company: this.form.headerBranchPlant.company,
          });
        } else {
          this.detailBranchPlantItems = await jdeAPI.fetchDetailBranchPlantV2({
            flowName: this.currentFlow.name,
            company: this.form.headerBranchPlant.company,
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.isFetchingDetailBranchData = false;
    },
    handleHeaderBranchChange() {
      this.fetchSuppliers();
    },
    async createPO() {
      if (!this.$refs.poForm.validate()) return;
      this.isCreatingPO = true;
      try {
        const parsedLines = this.detailData.map((line) => ({
          ...line,
          price: `${line.price
            .slice(0, -2)
            .replace(/\D/g, "")}.${line.price.slice(-2)}`,
        }));
        const payload = {
          ...this.form,
          dataLines: parsedLines,
          userEmail: Office.context.mailbox.userProfile.emailAddress,
          flowName: this.currentFlow.name,
        };
        const response = await jdeAPI.createPO(payload);
        this.snackbar = {
          type: "success",
          message: `${response.po_data} created successfully!`,
        };
        if (this.attachEmailToJDE) {
          await this.attachEmail(response.po_data);
        }
        this.detailData = [{ quantity: 0 }];
        this.updatePriceFields = !this.updatePriceFields;
        this.$refs.poForm.reset();
        this.$nextTick(() => {
          this.hideForm = true;
        });
      } catch (error) {
        this.snackbar = {
          type: "error",
          message: error.message,
        };
      }
      this.showSnackbar = true;
      this.isCreatingPO = false;
    },
    addLine() {
      this.detailData.push({ quantity: 0 });
    },
    deleteLine(event, index) {
      event.stopPropagation();
      this.detailData.splice(index, 1);
    },
  },
  created() {
    if (!this.currentFlow) {
      this.$router.replace("/");
      return;
    }
    this.payloadConfig = this.currentFlow.config.payload_config.map((item) => ({
      key: item.key,
      required: item.required,
      type: item.element_type,
      name: item.published_name,
      visibility: item.visibility,
      attrType: item.attribute_type || "N/A",
      defaultValue: item.default || "N/A",
    }));
    this.fetchHeaderData();
  },
};
</script>

<style scoped>
.mini-table__row {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 5px;
  position: relative;
}
.mini-table span {
  font-size: 14px;
  display: inline-block;
  width: 100%;
}
.mini-table__row .label {
  max-width: calc((100% / 5) * 2);
}
.mini-table__row .value {
  max-width: calc((100% / 5) * 3);
  text-align: right;
  word-break: break-all;
}
</style>
