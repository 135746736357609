import axios from "axios";

const getBaseUrl = () => {
  return "https://2p44e7wlfnvkhqsud4kneh6mym0jldvh.lambda-url.us-east-1.on.aws";
};

const getToken = () => {
  try {
    const token = localStorage.getItem("JDEToken");
    return token;
  } catch (error) {
    console.log(error);
    return "";
  }
};

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Authorization: "KEY k3RT!z8Zt2WW1-90A#23?7highTTT",
  },
  timeout: 300000,
});

const fieldsIntance = () => {
  const token = getToken();
  const shouldAddToken = localStorage.getItem("requires-auth");
  // TODO: Waiting on Jan to modify the BE, this should be send only when there is a need for it
  axiosInstance.defaults.headers["x-api-obotauth"] =
    shouldAddToken && token ? token : undefined;
  axiosInstance.defaults.headers["x-api-key"] =
    localStorage.getItem("x-api-key");
  axiosInstance.defaults.headers["x-env"] =
    process.env.VUE_APP_ENV === "dev" ? "dev" : "prod";
  axiosInstance.defaults.headers["x-locale"] = localStorage.getItem("x-locale");
  axiosInstance.defaults.headers["x-jde-locale"] =
    localStorage.getItem("JDELocale") || "";
  return axiosInstance;
};
const generateGenericPayload = (payload) => ({
  org_id: payload.company_id,
  connector_name: payload.connector,
  channel: "OUTLOOK",
});

const generateGetAllSFObjectPayload = (payload) => ({
  route: "orchestratorbot_route_SF_GetAllSObject",
  ...generateGenericPayload(payload),
  user_email: payload.user_email,
});

const generateFetchSFObjectFieldsPayload = (payload) => ({
  route: "orchestratorbot_route_SF_GetSObject",
  ...generateGenericPayload(payload),
  sfObject: payload.sfObject,
});

const generateCreateRecordPayload = (config, payload) => ({
  route: "orchestratorbot_route_SF_CreateRecord",
  channel: "OUTLOOK",
  sf_payload: payload,
  org_id: config.org_id,
  connector_name: config.connector_name,
  user_email: config.user_email,
  sfObject: config.sfObject,
});

const generateGetAllRecordsPayload = (payload) => ({
  route: "orchestratorbot_route_SF_GetAllRecords",
  channel: "OUTLOOK",
  org_id: payload.org_id,
  user_email: payload.user_email,
  connector_name: payload.ais_config,
  sfObject: payload.sfObject,
});

export default {
  getAllSFObjects: async (payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateGetAllSFObjectPayload(payload)
    );
    return data.sf_sobject_list?.sobjects || [];
  },
  fetchSFObjectFields: async (payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateFetchSFObjectFieldsPayload(payload)
    );
    return data.sf_sobject_meta?.fields || [];
  },
  createRecord: async (config, payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateCreateRecordPayload(config, payload)
    );
    return data;
  },
  getAllRecordsPerObject: async (payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateGetAllRecordsPayload(payload)
    );
    return data.sf_records?.records || [];
  },
};
