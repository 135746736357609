<template>
  <div>
    <v-autocomplete
      outlined
      :items="items"
      :label="generateLabel"
      :rules="isItRequired"
      :readonly="isLoading"
      @change="handleSelection"
      :value="field.default"
      dense
      :hint="field.hint"
      :disabled="isDisabled"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          size="24"
          color="primary"
          indeterminate
        />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "TableField",
  data: () => ({
    isLoading: true,
    items: [],
  }),
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.requiredAllowEmpty]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (
        (this.field.grid_action_value || this.field.linked_value) &&
        this.field.default
      ) {
        return true;
      }
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async fetchItems() {
      const tableObject = this.field.linked_attributes;
      console.log("JDE Table", tableObject.table_name);
      console.log("JDE Table Column", tableObject.column_name);
      const sleep = (interval) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(["This is fetched", "based on", "JDE Table"]);
          }, interval);
        });
      };
      return await sleep(3000);
    },
    handleSelection(value) {
      this.$emit("selected", { [this.field.key]: value });
    },
  },
  async created() {
    try {
      this.items = await this.fetchItems();
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
};
</script>
