import axios from "axios";
import genericModel from "@/models/generic";

const getBaseUrl = () => {
  return "https://2p44e7wlfnvkhqsud4kneh6mym0jldvh.lambda-url.us-east-1.on.aws";
};

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Authorization: "KEY k3RT!z8Zt2WW1-90A#23?7highTTT",
  },
  timeout: 300000,
});

const getToken = () => {
  try {
    const token = localStorage.getItem("JDEToken");
    return token;
  } catch (error) {
    console.log(error);
    return "";
  }
};

const getConnectorName = () => {
  try {
    const connector = localStorage.getItem("selectedConnector");
    return JSON.parse(connector).connector_name;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const fieldsIntance = () => {
  const token = getToken();
  const shouldAddToken = localStorage.getItem("requires-auth");
  // TODO: Waiting on Jan to modify the BE, this should be send only when there is a need for it
  axiosInstance.defaults.headers["x-api-obotauth"] =
    shouldAddToken && token ? token : "undefined";
  axiosInstance.defaults.headers["x-api-key"] =
    localStorage.getItem("x-api-key");
  axiosInstance.defaults.headers["x-env"] =
    process.env.VUE_APP_ENV === "dev" ? "dev" : "prod";
  axiosInstance.defaults.headers["x-locale"] = localStorage.getItem("x-locale");
  axiosInstance.defaults.headers["x-jde-locale"] =
    localStorage.getItem("JDELocale") || "";
  return axiosInstance;
};

const extractJDESpecificFields = () => {
  return {
    static_jde_ab: localStorage.getItem("STATIC_JDE_AB") || "",
    static_jde_company: localStorage.getItem("STATIC_JDE_COMPANY") || "",
    static_jde_user_id: localStorage.getItem("STATIC_JDE_USER_ID") || "",
  };
};

const generateFetchDataFromUDCPayload = (data) => ({
  route: "attribute_udc",
  org_id: data.company_id,
  connector_name: getConnectorName(),
  udc_sy: data.udc_key,
  udc_rt: data.udc_code,
  channel: "OUTLOOK",
  ...extractJDESpecificFields(),
});

const generateSubmitPayload = (config, payload) => ({
  route: "execute_orchestration_post",
  org_id: config.org_id,
  connector_name: getConnectorName(),
  orchestration_name: config.orchestration_name,
  botflow_name: config.botflow_name,
  user_email: config.user_email,
  orchestration_inputs: payload,
  // TODO: We need to figure which one are we gonna use this one or the one in the headers
  token: config.token,
  response_format: config.response_format,
  channel: "OUTLOOK",
  ...extractJDESpecificFields(),
});

const generateFetchDataFromSearchformPayload = (payload) => ({
  route: "attribute_searchform",
  org_id: payload.company_id,
  connector_name: getConnectorName(),
  search_form: payload.searchform,
  channel: "OUTLOOK",
  ...extractJDESpecificFields(),
});
const generateFetchDataFromOrchestratorPayload = (payload) => ({
  route: "attribute_orch",
  channel: "OUTLOOK",
  org_id: payload.company_id,
  connector_name: getConnectorName(),
  user_email: payload.user_email,
  orchestration_fetch_name: payload.orchName,
  filters: payload.orchFilters,
  ...extractJDESpecificFields(),
});

const generateCreateTextAttachmentPayload = (data) => ({
  route: "attachment_flow_addtext",
  org_id: data.company_id,
  connector_name: getConnectorName(),
  user_email: data.user_email,
  channel: "OUTLOOK",
  mo_object: data.moObject,
  mo_key: data.moKeysAsArray,
  mo_key_piped: data.moKeysPiped,
  mo_text: data.text,
  mo_itemname: "obotoutlook",
  ...extractJDESpecificFields(),
});

const generateSearchFormResponse = (data, config) => {
  const { searchform, valueColumn, filterColumns, displayColumn } = config;
  return data.searchform_data[`fs_${searchform}`].data.gridData.rowset.map(
    (row) => {
      const mappedObject = {
        value: `${row[valueColumn].value}`.trim(),
        label: row[displayColumn].value,
      };
      const filters = [];
      filterColumns.forEach((column) => {
        filters.push(row[column].value);
      });
      mappedObject.filter = filters.join("_");
      return mappedObject;
    }
  );
};

const generateOrchestratorResponse = (data, config) => {
  const { orchName, nestedFields, valueColumn, filterColumns, displayColumn } =
    config;
  let orchData = data.orch_data;
  if (nestedFields.length) {
    nestedFields.forEach((lvl) => {
      orchData = orchData[lvl];
    });
  } else {
    orchData = data.orch_data[orchName].rowset;
  }
  return orchData.map((row) => {
    const mappedObject = {
      value: `${row[valueColumn]}`.trim(),
      label: row[displayColumn],
    };
    const filters = [];
    filterColumns.forEach((column) => {
      filters.push(row[column]);
    });
    mappedObject.filter = filters.join("_");
    return mappedObject;
  });
};

const generateUDCResponse = (data, config) => {
  const { valueColumn, displayColumn, filterColumns } = config;
  return data.udc_data.gridData.rowset.map((row) => {
    const mappedObject = {
      value: `${row[valueColumn]}`.trim(),
      label: row[displayColumn],
    };
    const filters = [];
    filterColumns.forEach((column) => {
      filters.push(row[column]);
    });
    mappedObject.filter = filters.join("_");
    const locale = localStorage.getItem("JDELocale");
    if (
      locale?.length &&
      locale !== "en" &&
      locale !== "E" &&
      locale.toLowerCase() === row.F0005D_LNGP?.toLowerCase()
    ) {
      mappedObject.label = row.F0005D_DL01 || `${row[valueColumn]}`.trim();
    }
    return mappedObject;
  });
};

const generateAuthenticateJDEPayload = (payload) => ({
  route: "obot_user_auth",
  user_email: payload.user_email,
  user_id: payload.userId,
  user_pwd: payload.userPassword,
  org_id: payload.company_id,
  connector_name: getConnectorName(),
  channel: "OUTLOOK",
  ...extractJDESpecificFields(),
});

const generateFetchDateFormatPayload = (user) => ({
  route: "obot_standard_auth",
  user_email: user.email,
  org_id: user.company_id,
  connector_name: getConnectorName(),
  channel: "OUTLOOK",
  ...extractJDESpecificFields(),
});

const generateFetchWatchlistData = (payload) => ({
  route: "widget_execute_watchlist",
  user_email: payload.user_email,
  widget_id: payload.watchlistId,
  ...genericModel.formatJDEGenericPayload(payload),
  ...extractJDESpecificFields(),
});

export default {
  createTextAttachment: async (payload) => {
    await fieldsIntance().post(
      "",
      generateCreateTextAttachmentPayload(payload)
    );
  },
  fetchDataFromUDC: async (payload, config) => {
    const { data } = await fieldsIntance().post(
      "",
      generateFetchDataFromUDCPayload(payload)
    );
    if (data.result !== "SUCCESS") {
      throw new Error(data.result);
    }
    return generateUDCResponse(data, config);
  },
  fetchDataFromSearchform: async (payload, config) => {
    const { data } = await fieldsIntance().post(
      "",
      generateFetchDataFromSearchformPayload(payload)
    );
    if (data.result !== "SUCCESS") {
      throw new Error(data.result);
    }
    return generateSearchFormResponse(data, config);
  },
  fetchDataFromOrchestrator: async (payload, config) => {
    const { data } = await fieldsIntance().post(
      "",
      generateFetchDataFromOrchestratorPayload(payload)
    );
    if (data.result !== "SUCCESS") {
      throw new Error(data.result);
    }
    return generateOrchestratorResponse(data, config);
  },
  authenticateJDE: async (payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateAuthenticateJDEPayload(payload)
    );
    if (data.result !== "SUCCESS") {
      throw new Error("Something went wrong");
    }
    return data;
  },
  fetchDateFormat: async (user, connectorName) => {
    const { data } = await fieldsIntance().post(
      "",
      generateFetchDateFormatPayload(user, connectorName)
    );

    return data.standard_auth?.userInfo.simpleDateFormat || "MM/dd/yyyy";
  },
  submit: async (config, payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateSubmitPayload(config, payload)
    );
    if (data.result !== "SUCCESS") {
      throw new Error(data.result);
    }
    return data;
  },
  fetchWatchlistData: async (payload) => {
    const { data } = await fieldsIntance().post(
      "",
      generateFetchWatchlistData(payload)
    );
    if (data.watchlist_data.message) {
      throw new Error(data.watchlist_data.message);
    }
    return {
      value: data.watchlist_data.rowcount.records,
      warningThreshold: data.watchlist_data.warningThreshold,
      maxRecords: data.watchlist_data.maxRecords,
      criticalThreshold: data.watchlist_data.criticalThreshold,
    };
  },
  fetchMOObjects: async (payload) => {
    const { data } = await fieldsIntance().post("", payload);
    return data.attachment_mo_list;
  },
  downloadFile: async (payload) => {
    const { data } = await fieldsIntance().post("", payload);
    return data.mo_download_data_binarystring;
  },
};
