<template>
  <div class="page-container ">
    <div class="flows" v-if="getAvailableBotFlows.length">
      <v-btn
        large
        color="primary"
        v-for="flow in getFilteredBotFlows"
        :key="flow.name"
        @click="handleFlowPick(flow)"
      >
        <div class="flex align__center">
          <p class="flex__1 mb__0 text-center">{{ flow.name }}</p>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon class="ml-2" v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ flow.description }}</span>
          </v-tooltip>
        </div>
      </v-btn>
    </div>
    <div v-else>
      <h2 class="mb-2 red--text font__inter text__center">MENU INACTIVE</h2>
      <p class="font__inter">
        You don’t have access to this Menu. Please contact your Office365 admin.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "AvailableFlows",
  computed: {
    ...mapGetters({
      getAvailableBotFlows: "company/getAvailableBotFlows",
    }),
    ...mapState({
      currentMenuItem: (state) => state.company.currentMenuItem,
    }),
    getFilteredBotFlows() {
      return this.getAvailableBotFlows.filter((flow) => {
        const flowMenuItem = flow.config.main_config.menu_name;
        return flowMenuItem === this.currentMenuItem.name;
      });
    },
  },
  created() {
    if (!this.currentMenuItem) {
      this.$router.replace("/");
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.getAvailableBotFlows.length) {
        setTimeout(() => {
          vm.$router.push("/");
        }, 3000);
      }
    });
  },
  methods: {
    ...mapMutations({
      setCurrentFlow: "company/setCurrentFlow",
    }),

    handleFlowPick(flow) {
      this.setCurrentFlow(flow);
      if (flow.name === "CENTRICITY_RISK_SANDBOX") {
        this.$router.push("/sf-flow");
        return;
      }
      this.$router.push("/jde-flow");
    },
  },
};
</script>

<style scoped>
.flows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
</style>
