<template>
  <div class="page-container" v-if="currentFlow">
    <div v-if="!hideForm">
      <h3 class="text__center mb-3">{{ currentFlow.name }}</h3>
      <p class="text__center">{{ currentFlow.description }}</p>
      <v-form ref="sfForm" class="mb-5">
        <v-autocomplete
          label="Contract"
          v-model="sfObject.contract"
          outlined
          dense
          :items="contracts"
          item-text="searchLabel"
          item-value="Name"
          :loading="isFetchingContracts"
          :rules="[rules.required]"
        >
          <template v-slot:selection="{ item }">
            <span>{{ item.Name }}</span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.Name"></v-list-item-title>
              <v-list-item-subtitle>
                {{ item.Contract_Type__c }}_{{ item.Stage__c }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-text-field
          :disabled="!sfObject.contract"
          label="Subject"
          v-model="sfObject.subject"
          outlined
          dense
          :rules="[rules.required]"
        />
        <v-autocomplete
          :disabled="!sfObject.contract"
          label="Category"
          v-model="sfObject.category"
          outlined
          dense
          item-text="label"
          return-object
          :items="categories"
          :rules="[rules.required]"
        />
        <v-autocomplete
          :disabled="!sfObject.category && !sfObject.contract"
          label="Criteria"
          v-model="sfObject.criteria"
          outlined
          dense
          item-text="label"
          item-value="value"
          :items="filteredCriteria"
          :rules="[rules.required]"
        />
        <v-autocomplete
          :disabled="!sfObject.contract"
          label="Level"
          v-model="sfObject.level"
          outlined
          item-text="label"
          item-value="value"
          dense
          :items="levels"
          :rules="[rules.required]"
        />
        <v-text-field
          label="Risk Probability"
          outlined
          class="text-end"
          dense
          append-icon="mdi-percent"
          type="number"
          v-model="sfObject.riskProbability"
          :rules="[rules.between0and100]"
        />
        <v-text-field
          label="Amount At Risk"
          outlined
          class="text-end"
          dense
          v-money="money"
          :key="'amount-at-risk' + updateMoneyFields"
          v-model="sfObject.amountAtRisk"
          :rules="[rules.required]"
        />
        <v-text-field
          label="Amount in PSR"
          outlined
          class="text-end"
          dense
          v-money="money"
          :key="'amount-in-prs' + updateMoneyFields"
          v-model="sfObject.amountInPSR"
          :rules="[rules.required]"
        />
        <v-textarea
          outlined
          dense
          label="Description"
          rows="3"
          hide-details
          v-model="sfObject.description"
        />
      </v-form>
      <div>
        <v-btn
          :loading="isCreatingRisk"
          block
          color="primary"
          @click="createSFObject"
        >
          submit
        </v-btn>
      </div>
    </div>
    <v-snackbar
      :timeout="-1"
      v-model="showSnackbar"
      absolute
      :color="snackbar.type"
      top
      right
      width="350px"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon color="white" v-bind="attrs" @click="handleSnackbarClose">
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VMoney } from "v-money";
import sfAPI from "@/api/salesforce-hamon";
export default {
  name: "SalesForceFlow",
  directives: { money: VMoney },
  data: () => ({
    sfObject: {},
    contracts: [],
    levels: [],
    categories: [],
    criteria: [],
    showSnackbar: false,
    updateMoneyFields: false,
    isFetchingContracts: false,
    isCreatingRisk: false,
    snackbar: {
      type: "",
      message: "",
    },
    hideForm: false,
    money: {
      decimal: ".",
      thousands: ",",
      prefix: "",
      suffix: "",
      precision: 2,
    },
  }),

  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    filteredCriteria() {
      if (!this.sfObject.category) return [];
      const categoryIndex = this.categories.indexOf(this.sfObject.category);
      return this.criteria.filter((criteria) =>
        criteria.validFor.includes(categoryIndex)
      );
    },
    rules() {
      return {
        required: (v) => {
          if (v === 0) return true;
          return !!v || "This field is required";
        },
        between0and100: (v) => {
          if (v > -1 && v < 101) {
            return true;
          } else {
            return "The value should be between 0 and 100";
          }
        },
      };
    },
  },
  methods: {
    async fetchContracts() {
      this.isFetchingContracts = true;
      this.contracts = await sfAPI.fetchContracts();
      this.isFetchingContracts = false;
    },
    async fetchPicklistValues() {
      this.isFetchingValues = true;
      const picklistValues = await sfAPI.fetchPicklistValues();
      Object.keys(picklistValues).forEach((key) => {
        this.$set(this, key, picklistValues[key]);
      });
      this.isFetchingValues = false;
    },
    async createSFObject() {
      if (!this.$refs.sfForm.validate()) return;
      this.isCreatingRisk = true;
      try {
        const payload = {
          ...this.sfObject,
          category: this.sfObject.category.value,
          amountAtRisk: `${this.sfObject.amountAtRisk
            .slice(0, -2)
            .replace(/\D/g, "")}.${this.sfObject.amountAtRisk.slice(-2)}`,
          amountInPSR: `${this.sfObject.amountInPSR
            .slice(0, -2)
            .replace(/\D/g, "")}.${this.sfObject.amountInPSR.slice(-2)}`,
        };
        const response = await sfAPI.createRisk(payload);
        this.snackbar = {
          type: "success",
          message: `${response.Id} created successfully!`,
        };
        this.updateMoneyFields = !this.updateMoneyFields;
        this.$refs.sfForm.reset();
        this.$nextTick(() => {
          this.hideForm = true;
        });
      } catch (error) {
        this.snackbar = {
          type: "error",
          message: error.message,
        };
      }
      this.showSnackbar = true;
      this.isCreatingRisk = false;
    },
    handleSnackbarClose() {
      if (this.snackbar.type === "success") {
        this.showSnackbar = false;
        this.$router.replace("/");
        return;
      }
      this.showSnackbar = false;
    },
  },
  async created() {
    if (!this.currentFlow) {
      this.$router.replace("/");
      return;
    }
    try {
      this.fetchPicklistValues();
      this.fetchContracts();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="scss">
.text-end {
  input {
    text-align: end;
  }
}
</style>
