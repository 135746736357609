import { parseNotification } from "@/models/Notifications";

export default {
  state: () => ({
    notifications: [],
    isLoading: false,
  }),
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setLoadingState(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async init({ rootState, state, dispatch }) {
      const currentUser = rootState.users.currentUser;
      const currentConnector = rootState.connectors.selectedConnector;
      const pubNubChannels =
        currentUser?.groups.map(
          (group) =>
            `${currentUser.company_id}_${group}_${currentConnector.connector_id}-outlook`
        ) || [];
      this._vm.$pubNub.setUUID(currentUser.bot_user_id);
      this._vm.$pubNub.subscribe({
        channels: [
          ...pubNubChannels,
          `${currentUser.company_id}_${currentUser.email}_${currentConnector.connector_id}-outlook`,
        ],
      });
      this._vm.$pubNub.addListener({
        message: (event) => {
          const message = event.message;
          if (message.status === "PENDING") {
            if (
              !state.notifications.find(
                (not) => not.id === message.notification_id
              )
            ) {
              dispatch(
                "common/showSnackbar",
                {
                  type: "info",
                  message: "You have new notification!",
                },
                { root: true }
              );
            }
            dispatch("fetchNotifications");
          }
        },
      });
      dispatch("fetchNotifications");
    },
    async fetchNotifications({ commit, rootState }) {
      const currentUser = rootState.users.currentUser;
      const selectedConnector = rootState.connectors.selectedConnector;

      try {
        commit("setLoadingState", true);
        const notifications = await this._vm.$api.config.getNotifications({
          company_id: currentUser.company_id,
          status: "PENDING,IN PROGRESS",
          connector_id: selectedConnector.connector_id,
          notification_recipients: [
            ...currentUser.groups,
            currentUser.email,
          ].join(";;;"),
          channel: "outlook",
        });
        const parsedNotificaions = notifications.map((item) =>
          parseNotification(item)
        );
        commit("setNotifications", parsedNotificaions);
      } catch (error) {
        this._vm.$logError(error);
      } finally {
        commit("setLoadingState", false);
      }
    },
    async deleteNotification({ commit, dispatch, rootState }, id) {
      const currentUser = rootState.users.currentUser;
      try {
        commit("setLoadingState", true);
        await this._vm.$api.config.deleteNotification(
          id,
          currentUser.company_id
        );
        await dispatch("fetchNotifications");
      } catch (error) {
        this._vm.$logError(error);
      } finally {
        commit("setLoadingState", false);
      }
    },
    async updateNotification({ commit, dispatch }, payload) {
      try {
        commit("setLoadingState", true);
        await this._vm.$api.config.updateLinkedInformation(payload);
        await dispatch("fetchNotifications");
      } catch (error) {
        this._vm.$logError(error);
      } finally {
        commit("setLoadingState", false);
      }
    },
    async updateApprovalNotification({ commit }, payload) {
      try {
        commit("setLoadingState", true);
        await this._vm.$api.config.updateApprovalNotification(payload);
      } catch (error) {
        this._vm.$logError(error);
      } finally {
        commit("setLoadingState", false);
      }
    },
    async clearNotifications({ commit, rootState }) {
      try {
        const currentUser = rootState.users.currentUser;
        commit("setLoadingState", true);
        await this._vm.$api.config.clearNotifications(currentUser || {});
        commit("setNotifications", []);
      } catch (error) {
        this._vm.$logError(error);
      } finally {
        commit("setLoadingState", false);
      }
    },
  },
  namespaced: true,
};
