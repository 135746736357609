<template>
  <div>
    <v-autocomplete
      outlined
      :items="picklistValues"
      :label="generateLabel"
      :rules="isItRequired"
      :value="defaultValue"
      :hint="field.hint"
      :disabled="isDisabled"
      item-text="label"
      item-value="value"
      item-key="label"
      dense
      :loading="isFetchingSFObjectFields"
      @change="handleValueChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseField from "@/mixins/BaseField";

export default {
  name: "SFPicklistField",
  mixins: [BaseField],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },
  data: () => ({
    unsubscribe: null,
    picklistValues: [],
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      isFetchingSFObjectFields: (state) =>
        state.salesforce.isFetchingSFObjectFields,
      sfObjectFields: (state) => state.salesforce.sfObjectFields,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.requiredAllowEmpty]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  watch: {
    sfObjectFields() {
      if (this.preview) return;
      this.extractPicklistValues();
    },
  },
  created() {
    if (!this.isFetchingSFObjectFields) {
      this.$store.dispatch("salesforce/fetchSFObjectFields", {
        sfObject: this.currentFlow.linked_orch_name,
        connectorName: this.currentFlow.ais_config,
      });
      return;
    }
    this.extractPicklistValues();
  },
  methods: {
    handleValueChange(value) {
      this.$emit("selected", { [this.field.key]: value });
    },
    extractPicklistValues() {
      const currentPicklistField = this.sfObjectFields.find(
        (field) => field.name === this.field.key && field.type === "picklist"
      );
      this.picklistValues = currentPicklistField.picklistValues;
    },
  },
};
</script>
