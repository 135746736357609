<template>
  <div class="name-wrapper">
    <h2>{{ getTitle }}</h2>
  </div>
</template>

<script>
export default {
  name: "HTML5Title",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getTitle() {
      return this.field.linked_attributes?.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.name-wrapper {
  width: 100%;
  h2 {
    text-align: center;
    margin-bottom: ($page-spacing / 2);
  }
}
</style>
