import { getJDOXUrl } from "@/config/endpointsUrl";

const getInstance = (axios) => {
  axios.setBaseURL(getJDOXUrl());
  return axios;
};

const generateGetPresignedUrlPayload = (companyId, key) => ({
  company_id: companyId,
  bucket: "temporary-attachements-bucket",
  key,
  expires: 3600,
});

export default (axios) => ({
  uploadFile: async (formData) => {
    await getInstance(axios).post("/s3/upload-file", formData);
  },
  getPresignedUrl: async (companyId, key) => {
    try {
      const { data } = await getInstance(axios).post(
        "/s3/get-presigned-url",
        generateGetPresignedUrlPayload(companyId, key)
      );
      return data.presigned_url;
    } catch (error) {
      console.log(error);
    }
  },
  convertToPDF: async (payload) => {
    const { data } = await getInstance(axios).post(
      "/s3/convert-to-pdf",
      payload
    );
    return data.converted_url;
  },
});
