export default {
  /**
   * Function to generate payload containing information about the current user
   * @param {Object} user - Currently logged in user
   * @returns {Object} Formatted object
   */
  formatGenericPayload: (user) => ({
    cognito_sub_id: user.user_id,
    company_id: user.company_id,
    user_email: user.user_email
  }),
  /**
   * Function to generate payload containing information about the current botflow
   * @param {Object} payload
   * @returns {Object} Formatted object
   */
  formatJDEGenericPayload: (payload) => ({
    org_id: payload.company_id,
    connector_name:
      payload.connector || payload.connector_name || payload.ais_config,
    channel: "ORCH APP"
  })
}
