<template>
  <div class="response-page">
    <h3 class="mb-3">Botflow data</h3>
    <div v-if="!noData">
      <v-text-field
        dense
        outlined
        v-model="search"
        label="Search"
        persistent-hint
        hint="Enter more than 3 characters to filter the results"
        @keyup="filterItems"
        @click:clear="resetFilter"
        clearable
        class="mb-2"
      />
      <div
        v-for="(item, index) in items"
        :key="index"
        class="result__container"
      >
        <div :key="item.isLoading" class="flex mb-2">
          <slot name="actions" :item="item" />
        </div>
        <div
          class="result__row"
          v-for="header in headers.filter((header) => header.text !== 'search')"
          :key="header.text"
        >
          <span class="label">{{ header.text }}</span>
          <span class="value">{{ item[header.text] }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text__center">No data returned from the Botflow!</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ResponseTable",
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [],
    items: [],
    search: "",
    noData: false,
    rawData: [],
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
    }),
  },
  async created() {
    const objectKey = Object.keys(this.response)[0];
    const rowset = this.response[objectKey].rowset.map((item) => ({
      ...item,
      search: Object.values(item)
        .map((value) => `${value}`.toLowerCase())
        .join(","),
    }));
    if (!rowset?.length) {
      this.noData = true;
      return;
    }
    this.headers = Object.keys(rowset[0]).map((key) => ({
      text: key,
      value: key,
    }));
    this.rawData = rowset;
    this.items = rowset;
  },
  methods: {
    resetFilter() {
      this.$set(this, "items", this.rawData);
    },
    filterItems() {
      if (!this.search) {
        this.resetFilter();
      }
      if (this.search.length > 2) {
        this.items = this.rawData.filter((item) =>
          item.search.includes(this.search.toLowerCase())
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.response-page {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}
.result {
  &__container {
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 4px;
    & + & {
      margin-top: 10px;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    .label {
      font-weight: 600;
    }
    .label,
    .value {
      font-size: 14px;
    }
  }
}
</style>
