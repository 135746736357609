<template>
  <v-card>
    <v-card-title class="brand-header mb-4">
      <h6 class="text-subtitle-1">
        {{ noResponse ? "UPLOAD FILES" : "RELATED DOCUMENTS" }}
      </h6>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="!noResponse" class="mb-4" color="success" outlined>
        <p
          class="theme-text-color mb-2"
          v-for="(row, index) in responseAsArray"
          :key="index"
        >
          {{ row.key }}: <span class="green--text">{{ row.value }}</span>
        </p>
      </v-alert>
      <div class="flex justify-center mb-4" v-if="isComposeMode">
        <v-btn class="info" @click="addToEmailBody"> Add to email body </v-btn>
      </div>
      <v-form ref="additionalDataForm">
        <div>
          <v-file-input
            outlined
            label="MO File"
            dense
            small-chips
            :rules="[validation.onlyAccepted, isItRequired]"
            @change="fileAdded"
            multiple
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            v-if="additionalDataConfig.type !== 'text'"
            @click:clear="clearAll"
          />
          <v-textarea
            v-if="additionalDataConfig.type !== 'file'"
            outlined
            :rules="[isItRequired]"
            label="MO Text"
            rows="3"
            dense
            v-model="userText"
          />
        </div>
        <div class="d-flex flex-wrap flex__half-gap">
          <v-chip
            color="primary"
            v-for="(file, index) in files"
            close
            :key="file.id"
            @click:close="removeFile(index)"
          >
            {{ file.key }}
          </v-chip>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <div
        class="flex"
        :class="
          this.currentFlow?.config?.main_config?.attachments_required
            ? 'justify-center'
            : 'justify-space-between'
        "
      >
        <v-btn class="page-btn--primary" @click="submit" :loading="isLoading">
          submit
        </v-btn>
        <v-btn
          v-if="!this.currentFlow?.config?.main_config?.attachments_required"
          class="page-btn--error"
          @click="close"
        >
          close
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import flowFieldsAPI from "@/api/fields";
export default {
  name: "AdditionalData",
  props: {
    noResponse: {
      type: Boolean,
      required: false,
    },
    fileMeta: {
      type: Object,
      required: true,
    },
    responseAsArray: {
      type: Array,
      default: () => [],
    },
    forContact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      companySettings: (state) => state.company.settings,
      outlookState: (state) => state.common.outlookState,
      selectedConnector: (state) => state.connectors.selectedConnector,
    }),
    isItRequired() {
      if (this.forContact) return true;
      if (
        this.currentFlow?.config.main_config.attachments_required &&
        !this.files.length
      ) {
        return this.validation.required;
      }
      return true;
    },
    isDark() {
      return this.$vuetify.theme.isDark;
    },
    isComposeMode() {
      return this.outlookState === "Compose";
    },
  },
  data: () => ({
    additionalDataConfig: {},
    isLoading: false,
    userText: "",
    files: [],
  }),
  created() {
    this.additionalDataConfig = {
      type: this.forContact
        ? "file"
        : this.currentFlow.config.main_config.additional_data_type?.toLowerCase(),
    };
    const att = Office.context.mailbox.item.attachments;
    if (att) {
      this.fileAdded(att);
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    clearAll() {
      this.files = [];
      const att = Office.context.mailbox.item.attachments;
      if (att) {
        this.fileAdded(att);
      }
    },
    async addToEmailBody() {
      try {
        Office.context.mailbox.item.body.prependAsync(
          `<p>${this.responseAsArray
            .map((res) => res.key + ": " + res.value)
            .join("<br/>")}</p>`,
          {
            coercionType: Office.CoercionType.Html,
            asyncContext: {},
          },
          function (asyncResult) {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              throw new Error(asyncResult.error.message);
            }
          }
        );
      } catch (error) {
        this.$logError(error);
      }
    },
    async uploadFiles() {
      const uploadFile = async () => {
        const currentFile = this.files.shift();
        try {
          await this.$api.config.uploadFile(currentFile, this.handleUpload);
          if (this.files.length > 0) {
            await uploadFile();
          }
        } catch (error) {
          this.$logError(error);
        }
      };
      await uploadFile();
    },
    fileAdded(files) {
      const meta = {
        env: this.selectedConnector.environment,
        flow_name: this.forContact
          ? "Attach to contact"
          : this.currentFlow.name,
        company_teams_tid: this.companySettings.tenant_id_teams,
        po_key: this.fileMeta.mo_key,
      };

      this.files.push(
        ...files.map((file) => ({
          key: file.name,
          company_teams_tid: this.companySettings.tenant_id_teams,
          ...meta,
          file,
        }))
      );
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async createTextAttachment() {
      const payload = {
        company_id: this.currentUser.company_id,
        ais_config: this.selectedConnector.connector_name,
        user_email: this.currentUser.email,
        moObject: this.fileMeta.mo_key.split("_")[0],
        moKeysAsArray: this.fileMeta.mo_key.split("_")[1].split("|"),
        moKeysPiped: this.fileMeta.mo_key.split("_")[1],
        text: this.userText,
      };
      await flowFieldsAPI.createTextAttachment(payload);
    },
    async submit() {
      if (!this.$refs.additionalDataForm.validate()) return;
      this.isLoading = true;
      try {
        if (this.userText.length) {
          await this.createTextAttachment();
          this.showSnackbar({
            type: "success",
            message: `Text attachment uploaded succesfully!`,
          });
        }
        if (this.files.length) {
          await this.uploadFiles();
        }
        this.close();
      } catch (error) {
        this.$logError(error);
        this.showSnackbar({
          type: "error",
          message: "Something went wrong!",
        });
      }
      this.isLoading = false;
    },
    handleUpload(filename, error) {
      if (error) {
        this.showSnackbar({
          type: "error",
          message: `Upload for file: ${filename} failed!`,
        });
        return;
      }
      this.showSnackbar({
        type: "success",
        message: `Upload for file: ${filename} was successful!`,
      });
    },
    close() {
      this.additionalDataConfig = {};
      this.userText = "";
      this.$emit("close");
    },
  },
};
</script>
