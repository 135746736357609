import { DateTime } from "luxon";

const parseNotification = (notificaiton) => {
  return {
    title:
      notificaiton.type === "approval"
        ? "Approval Request"
        : notificaiton.link_name.split("|-|-|")[1],
    data: notificaiton.linked_data || {},
    id: notificaiton.id,
    type: notificaiton.type,
    status: notificaiton.status,
    createdAt: DateTime.fromJSDate(new Date(notificaiton.created_at)).toFormat(
      "dd-LLL-yyyy HH:mm"
    ),
    searchTerm: JSON.stringify(Object.values(notificaiton)),
  };
};

export { parseNotification };
