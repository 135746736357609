import VueI18n from "vue-i18n";
import Vue from "vue";

import en from "../locales/en.json";
import es from "../locales/es.json";
import nl from "../locales/nl.json";
import fr from "../locales/fr.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en, es, nl, fr },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "USD",
      },
    },
  },
});

export default i18n;
