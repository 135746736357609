import axios from "axios";

const getBaseUrl = () => {
  return "https://qbb6l7jemk.execute-api.us-east-1.amazonaws.com/production/api/inbound_bot_request";
};

const sfDataInstance = axios.create({
  baseURL: getBaseUrl(),
});

const generateFetchPicklistValuesPayload = () => ({
  action: "orchestratorbot_route_centricity_values",
  orchestratorbot_route_SF_GetSObject: true,
  environment: "SANDBOX",
  source: "SALESFORCE",
  channel: "OUTLOOK",
});
const generateFetchContractsPayload = () => ({
  action: "orchestratorbot_route_SF_GetAllContracts",
  orchestratorbot_route_SF_GetAllContracts: true,
  environment: "SANDBOX",
  source: "SALESFORCE",
  channel: "OUTLOOK",
});
const generateCreateRiskPayload = (payload) => ({
  action: "orchestratorbot_route_SF_CreateRisk",
  orchestratorbot_route_SF_CreateRisk: true,
  environment: "SANDBOX",
  source: "SALESFORCE",
  channel: "OUTLOOK",
  sf_data: {
    Contract: payload.contract,
    Subject: payload.subject,
    Category: payload.category,
    Criteria: payload.criteria,
    Level: payload.level,
    Risk_Probability: payload.riskProbability,
    Amount_At_Risk: payload.amountAtRisk || null,
    Amount_In_PSR: payload.amountInPSR || null,
    Description: payload.description,
  },
});
export default {
  fetchPicklistValues: async () => {
    const { data } = await sfDataInstance.post(
      "",
      generateFetchPicklistValuesPayload()
    );
    return {
      categories: data.obot.sf_picklistdata_category,
      criteria: data.obot.sf_picklistdata_criteria,
      levels: data.obot.sf_picklistdata_level,
    };
  },
  fetchContracts: async () => {
    const { data } = await sfDataInstance.post(
      "",
      generateFetchContractsPayload()
    );
    return data.obot.sf_data.map((contract) => ({
      ...contract,
      searchLabel: `${contract.Name},${contract.Contract_Scope__c},${contract.Product_Line__c}`,
    }));
  },
  createRisk: async (payload) => {
    const { data } = await sfDataInstance.post(
      "",
      generateCreateRiskPayload(payload)
    );
    if (data.obot.status === "ERROR") {
      throw new Error(data.obot.message);
    }
    return data.obot.sf_data_risk;
  },
};
