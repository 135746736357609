<template>
  <div class="mb-4">
    <v-text-field
      ref="googleAutocomplete"
      outlined
      :label="generateLabel"
      prepend-inner-icon="mdi-magnify"
      item-text="mainAddress"
      item-value="mainAddress"
      :rules="isItRequired"
      :hint="field.hint"
      dense
      :disabled="isDisabled"
      :value="selectedAddress"
    />
    <div
      v-show="linkedAttributes.map_visualisation"
      id="map"
      class="map-container"
    />
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { mapState } from "vuex";
import { distance } from "fastest-levenshtein";
import jdeCountries from "@/config/jdeCountries";

export default {
  name: "AddressField",
  /**
   * @vue-prop {Object} field
   * @vue-prop {Boolean} textarea
   * @vue-prop {String|Number|Object} value
   * @vue-prop {Array} filters
   * @vue-prop {Boolean} disabled
   */
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },
  data: () => ({
    selectedAddress: "",
    search: "",
    linkedAttributes: {},
    map: null,
    googleInstance: null,
    marker: null,
    usesFormattedAddress: false,
    formattedAddress: {},
    autocomplete: {},
    unsubscribe: null,
  }),
  /**
   * @vue-computed {Array|undefined} isItRequired
   * @vue-computed {String} generateLabel
   * @vue-computed {String} classes
   */
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (
        (this.field.grid_action_value || this.field.linked_value) &&
        this.field.default
      ) {
        return true;
      }
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.linkedAttributes = this.field.linked_attributes || {};
    if (this.field.inputs) {
      this.usesFormattedAddress = true;
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
    await this.initGoogleAPI();

    if (this.field.default) {
      this.handleSelection(this.field.default);
      this.selectedAddress = this.field.default;
      this.addresses.push({
        mainAddress: this.field.default,
        description: "Default value",
      });
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    handleKeyUp(event) {
      const value = event.target.value;
      this.search = value;
      if (this.search.length === 0) {
        this.addresses = [];
      }
    },
    initMap() {
      this.map = new this.googleInstance.maps.Map(
        document.getElementById("map"),
        {
          center: { lat: 50.88796335021672, lng: 4.460170475873679 },
          zoom: 15,
          gestureHandling: false,
          keyboardShortcuts: false,
          clickableIcons: false,
        }
      );
      this.map.setOptions({
        draggable: false,
      });
    },
    async initGoogleAPI() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });
      this.googleInstance = await loader.loadPromise();
      this.initMap();
      this.autocomplete = new this.googleInstance.maps.places.Autocomplete(
        this.$refs.googleAutocomplete.$refs.input,
        {
          fields: this.usesFormattedAddress
            ? ["geometry", "address_components"]
            : ["geometry", "formatted_address"],
        }
      );
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        if (this.usesFormattedAddress) {
          this.extractDetailsForPlace(place);
        } else {
          this.selectedAddress = place.formatted_address;
        }

        this.setMarker({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        this.handleSelection(this.selectedAddress);
      });
    },
    extractDetailsForPlace(address) {
      const structuredAddesss = {};
      if (!address.address_components) return;
      address.address_components.forEach((addrComp) => {
        const type = addrComp.types[0];
        structuredAddesss[type] = addrComp.long_name;
      });
      this.formattedAddress = {
        address_line: `${structuredAddesss.route} ${
          structuredAddesss.street_number || ""
        }`,
        city: structuredAddesss.locality,
        postal_code: structuredAddesss.postal_code,
        country: structuredAddesss.country,
      };
      jdeCountries.forEach((country) => {
        const countryName = country.Description;
        const comparisonDistance = distance(
          countryName,
          structuredAddesss.country
        );
        if (comparisonDistance < 3) {
          this.formattedAddress.country = country.Code;
        }
      });
      const addressAsString = Object.values(this.formattedAddress).join(", ");
      this.selectedAddress = addressAsString;
    },
    setMarker({ lat, lng }) {
      if (this.marker) {
        this.marker.setMap(null);
      }
      const position = new this.googleInstance.maps.LatLng(lat, lng);
      this.marker = new this.googleInstance.maps.Marker({
        map: this.map,
        position: { lat, lng },
      });
      this.map.setCenter(position);
    },
    /**
     * Called on form change
     * Modifies passed in value depending on filters
     * Emits text-change event with modified value as data
     * @param {String} value
     */
    handleSelection(value) {
      if (this.usesFormattedAddress) {
        const payload = {};
        for (const key in this.formattedAddress) {
          if (Object.hasOwnProperty.call(this.formattedAddress, key)) {
            const associatedOrchField = this.field.inputs[key]?.name;
            payload[associatedOrchField] = this.formattedAddress[key];
          }
        }
        this.$emit("text-change", payload);
        return;
      }
      this.$emit("text-change", { [this.field.key]: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  height: 400px;
}
</style>
