<template>
  <v-app>
    <div class="page-container">
      <div v-if="isLoading" class="flex justify__center">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <v-card v-if="!isLoading" outlined>
        <v-card-title>
          <div class="flex__1 flex__column justify__center align__center">
            <h2 class="font__inter mb__default">Contacts</h2>
            <div class="flex align__center justify__center">
              <v-btn
                v-if="!editMode && contacts.length"
                outlined
                @click="handleEditMode"
                small
              >
                Edit
                <v-icon right color="white"> mdi-pen </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="mb__double">
            <p class="font__inter text-center">
              <router-link to="/add-contact">Create</router-link> new contact.
            </p>
          </div>
          <transition name="slide-fade" mode="out-in">
            <div v-if="!editMode" class="font__inter">
              <div v-if="contacts.length && !isLoading">
                <v-combobox
                  label="Available contacts"
                  :value="contact"
                  :items="contacts"
                  return-object
                  item-text="alpha_name"
                  outlined
                  item-value="alpha_name"
                  dense
                  @change="handleContactChange"
                />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      right
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="viewContactFiles"
                    >
                      mdi-archive-outline
                    </v-icon>
                  </template>
                  <span>View files</span>
                </v-tooltip>

                <v-simple-table :key="contact.name" class="font__inter">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="key in Object.keys(contact)" :key="key">
                        <td>{{ beautifyKey(key) }}</td>
                        <td>{{ contact[key] }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-if="!contacts.length && !isLoading">
                <p class="text-center">
                  We were unable to locate any contact with email address:
                  <b>
                    {{ emailAddr }}
                  </b>
                </p>
              </div>
            </div>
            <div v-else>
              <v-text-field
                outlined
                dense
                v-for="key in Object.keys(contactModel)"
                v-model="contactModel[key]"
                :key="key"
                :label="beautifyKey(key)"
                :disabled="nonEditableFields.includes(key)"
              />
              <div class="flex justify__between">
                <v-btn
                  @click="updateContact"
                  :loading="isUpdating"
                  class="primary"
                >
                  update
                </v-btn>
                <v-btn @click="editMode = false" class="error mr-3">
                  cancel
                </v-btn>
              </div>
            </div>
          </transition>
        </v-card-text>
      </v-card>
    </div>
    <view-files :for-contact="true" ref="viewFiles" />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import axios from "axios";
import fieldsAPI from "@/api/fields";
import ViewFiles from "@/components/ViewFiles.vue";

export default {
  components: {
    ViewFiles,
  },
  data() {
    return {
      editMode: false,
      isLoading: false,
      isSubmitting: false,
      emailAddr: "",
      contactModel: {},
      isUpdating: false,
      axiosInstance: axios.create({
        baseURL:
          "https://2p44e7wlfnvkhqsud4kneh6mym0jldvh.lambda-url.us-east-1.on.aws/",
      }),
      nonEditableFields: ["ab_number", "contact_type"],
    };
  },
  computed: {
    ...mapState({
      companySettings: (state) => state.company.settings,
      currentUser: (state) => state.users.currentUser,
      contacts: (state) => state.contact.contacts,
      contact: (state) => state.contact.contact,
      selectedConnector: (state) => state.connectors.selectedConnector,
    }),
  },
  created() {
    this.axiosInstance.defaults.headers = {
      Authorization: "KEY k3RT!z8Zt2WW1-90A#23?7highTTT",
      "x-api-key": this.companySettings.api_key,
      "x-env": process.env.VUE_APP_ENV === "dev" ? "dev" : "prod",
    };
  },
  async mounted() {
    this.emailAddr = Office.context.mailbox.item.from.emailAddress;
    this.searchContactByEmail();
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    ...mapMutations({
      setContact: "contact/setContact",
      setFileMeta: "company/setFileMeta",
    }),
    async viewContactFiles() {
      this.setFileMeta({
        associatedMORaw: `ABGT`,
      });
      const moKey = this.contact.ab_number.toString();
      this.$refs.viewFiles.showDialog(moKey);
    },
    handleContactChange(e) {
      this.setContact(e);
      this.contactModel = Object.assign({}, this.contact);
    },
    handleEditMode() {
      this.editMode = !this.editMode;
    },
    beautifyKey(key) {
      if (key === "ab_number") return "AB Number";
      return key
        .split("_")
        .map((key) => `${key.slice(0, 1).toUpperCase()}${key.slice(1)}`)
        .join(" ");
    },
    async searchContactByEmail() {
      try {
        this.contactModel = this.contact;
      } catch (error) {
        console.error(error);
      }
    },
    async updateContact() {
      this.isUpdating = true;
      try {
        await this.axiosInstance.post("", {
          route: "outlook_update_contact",
          outlookDomain: this.companySettings.tenant_id_outlook,
          contact_email: this.emailAddr,
          channel: "OUTLOOK",
          ...this.contactModel,
        });
        this.showSnackbar({
          type: "success",
          message: `Contact ${this.emailAddr} updated successfully!`,
        });
        this.$router.push("/");
      } catch (error) {
        console.error(error);
        this.showSnackbar({
          type: "error",
          message: "Contact update failed!",
          timeout: 5000,
        });
      } finally {
        this.isUpdating = false;
      }
    },
  },
};
</script>
