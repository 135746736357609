export const formValidationMixin = {
  computed: {
    validation() {
      return {
        maxLength: (length) => {
          return (v) => {
            console.log("v", v);
            return v?.length <= length || `Max ${length} characters`;
          };
        },
        required: (v) => {
          if (v === 0) return true;
          return !!v || "This field is required";
        },
        requiredAllowEmpty: (value) => {
          if (value !== undefined && value !== null) return true;
          return !!value || "This field is required";
        },
        biggerThanZero: (v) => {
          return parseInt(v) > 0 || "Value should be bigger than 0";
        },
        between0and100: (v) => {
          if (v > -1 && v < 101) {
            return true;
          } else {
            return "The value should be between 0 and 100";
          }
        },
        onlyNumbers: (value) => {
          if (!value) return true;
          const regex = new RegExp(/^[0-9,.]+$/);
          return regex.test(value) ? true : "Only numbers are allowed";
        },
        email: (value) => {
          if (!value || !value.length) return true;
          const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
          return regex.test(value) ? true : "Enter valid email adress";
        },
        iban: (value) => {
          if (!value) return true;
          const regex = new RegExp(
            /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/
          );
          return regex.test(value) ? true : "Enter a valid IBAN";
        },
        between0and1: (value) => {
          if (!value) return "This field is required";
          const floatNumber = parseFloat(value);
          if (floatNumber < 1.1 && floatNumber > 0.0) {
            return true;
          }
          return "The value should be bigger than 0.0 and smaller or equal to 1.0";
        },
        onlyAccepted: (files) => {
          if (!files) return true;
          const forbiddenExtensions = ["exe", "bat", "jsp"];
          let result = true;
          // We are using plain for loop because we can break from it
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const extension = file.name.split(".").slice(-1)[0];
            if (forbiddenExtensions.includes(extension)) {
              result = `Files with extensions: ${forbiddenExtensions.join(
                ","
              )} are not allowed!`;
              break;
            }
          }
          return result;
        },
      };
    },
  },
};
