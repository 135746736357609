export default [
  {
    Code: "AD",
    Description: "Andorra"
  },
  {
    Code: "AE",
    Description: "United Arab Emirates (UAE)"
  },
  {
    Code: "AF",
    Description: "Afghanistan"
  },
  {
    Code: "AG",
    Description: "Antigua and Barbuda"
  },
  {
    Code: "AI",
    Description: "Anguilla"
  },
  {
    Code: "AL",
    Description: "Albania"
  },
  {
    Code: "AM",
    Description: "Armenia"
  },
  {
    Code: "AN",
    Description: "Netherlands Antilles"
  },
  {
    Code: "AO",
    Description: "Angola"
  },
  {
    Code: "AQ",
    Description: "Antarctica"
  },
  {
    Code: "AR",
    Description: "Argentina"
  },
  {
    Code: "AS",
    Description: "American Samoa"
  },
  {
    Code: "AT",
    Description: "Austria"
  },
  {
    Code: "AU",
    Description: "Australia"
  },
  {
    Code: "AW",
    Description: "Aruba"
  },
  {
    Code: "AZ",
    Description: "Azerbaijan"
  },
  {
    Code: "BA",
    Description: "Bosnia and Herzegovina"
  },
  {
    Code: "BB",
    Description: "Barbados"
  },
  {
    Code: "BD",
    Description: "Bangladesh"
  },
  {
    Code: "BE",
    Description: "Belgium"
  },
  {
    Code: "BF",
    Description: "Burkina Faso"
  },
  {
    Code: "BG",
    Description: "Bulgaria"
  },
  {
    Code: "BH",
    Description: "Bahrain"
  },
  {
    Code: "BI",
    Description: "Burundi"
  },
  {
    Code: "BJ",
    Description: "Benin"
  },
  {
    Code: "BM",
    Description: "Bermuda"
  },
  {
    Code: "BN",
    Description: "Brunei"
  },
  {
    Code: "BO",
    Description: "Bolivia"
  },
  {
    Code: "BR",
    Description: "Brazil"
  },
  {
    Code: "BS",
    Description: "Bahamas"
  },
  {
    Code: "BT",
    Description: "Bhutan"
  },
  {
    Code: "BV",
    Description: "Bouvet Island"
  },
  {
    Code: "BW",
    Description: "Botswana"
  },
  {
    Code: "BY",
    Description: "Byelorussian SSR"
  },
  {
    Code: "BZ",
    Description: "Belize"
  },
  {
    Code: "CA",
    Description: "Canada"
  },
  {
    Code: "CC",
    Description: "Cocos (Keeling) Islands"
  },
  {
    Code: "CD",
    Description: "Democratic Republic of Congo"
  },
  {
    Code: "CF",
    Description: "Central African Republic"
  },
  {
    Code: "CG",
    Description: "Congo"
  },
  {
    Code: "CH",
    Description: "Switzerland"
  },
  {
    Code: "CI",
    Description: "Ivory Coast"
  },
  {
    Code: "CK",
    Description: "Cook Islands"
  },
  {
    Code: "CL",
    Description: "Chile"
  },
  {
    Code: "CM",
    Description: "Cameroon"
  },
  {
    Code: "CN",
    Description: "China"
  },
  {
    Code: "CO",
    Description: "Colombia"
  },
  {
    Code: "CR",
    Description: "Costa Rica"
  },
  {
    Code: "CS",
    Description: "Serbia and Montenegro"
  },
  {
    Code: "CU",
    Description: "Cuba"
  },
  {
    Code: "CV",
    Description: "Cape Verde"
  },
  {
    Code: "CX",
    Description: "Christmas Island"
  },
  {
    Code: "CY",
    Description: "Cyprus"
  },
  {
    Code: "CZ",
    Description: "Czech Republic"
  },
  {
    Code: "DE",
    Description: "Germany"
  },
  {
    Code: "DJ",
    Description: "Djibouti"
  },
  {
    Code: "DK",
    Description: "Denmark"
  },
  {
    Code: "DM",
    Description: "Dominica"
  },
  {
    Code: "DO",
    Description: "Dominican Republic"
  },
  {
    Code: "DZ",
    Description: "Algeria"
  },
  {
    Code: "EC",
    Description: "Ecuador"
  },
  {
    Code: "EE",
    Description: "Estonia"
  },
  {
    Code: "EG",
    Description: "Egypt"
  },
  {
    Code: "EH",
    Description: "Western Sahara"
  },
  {
    Code: "ER",
    Description: "Eritrea"
  },
  {
    Code: "ES",
    Description: "Spain"
  },
  {
    Code: "ET",
    Description: "Ethiopia"
  },
  {
    Code: "FI",
    Description: "Finland"
  },
  {
    Code: "FJ",
    Description: "Fiji"
  },
  {
    Code: "FK",
    Description: "Falkland Islands"
  },
  {
    Code: "FM",
    Description: "Micronesia"
  },
  {
    Code: "FO",
    Description: "Faroe Islands"
  },
  {
    Code: "FR",
    Description: "France"
  },
  {
    Code: "GA",
    Description: "Gabon"
  },
  {
    Code: "GB",
    Description: "United Kingdom"
  },
  {
    Code: "GD",
    Description: "Grenada"
  },
  {
    Code: "GE",
    Description: "Georgia"
  },
  {
    Code: "GF",
    Description: "French Guiana"
  },
  {
    Code: "GH",
    Description: "Ghana"
  },
  {
    Code: "GI",
    Description: "Gibraltar"
  },
  {
    Code: "GL",
    Description: "Greenland"
  },
  {
    Code: "GM",
    Description: "Gambia"
  },
  {
    Code: "GN",
    Description: "Guinea"
  },
  {
    Code: "GP",
    Description: "Guadeloupe"
  },
  {
    Code: "GQ",
    Description: "Equatorial Guinea"
  },
  {
    Code: "GR",
    Description: "Greece"
  },
  {
    Code: "GS",
    Description: "South Georgia and the South Sa"
  },
  {
    Code: "GT",
    Description: "Guatemala"
  },
  {
    Code: "GU",
    Description: "Guam"
  },
  {
    Code: "GW",
    Description: "Guinea-Bissau"
  },
  {
    Code: "GY",
    Description: "Guyana"
  },
  {
    Code: "HK",
    Description: "Hong Kong"
  },
  {
    Code: "HM",
    Description: "Heard Island and McDonald Isla"
  },
  {
    Code: "HN",
    Description: "Honduras"
  },
  {
    Code: "HR",
    Description: "Croatia"
  },
  {
    Code: "HT",
    Description: "Haiti"
  },
  {
    Code: "HU",
    Description: "Hungary"
  },
  {
    Code: "ID",
    Description: "Indonesia"
  },
  {
    Code: "IE",
    Description: "Ireland"
  },
  {
    Code: "IL",
    Description: "Israel"
  },
  {
    Code: "IN",
    Description: "India"
  },
  {
    Code: "IO",
    Description: "British Indian Ocean Territory"
  },
  {
    Code: "IQ",
    Description: "Iraq"
  },
  {
    Code: "IR",
    Description: "Iran"
  },
  {
    Code: "IS",
    Description: "Iceland"
  },
  {
    Code: "IT",
    Description: "Italy"
  },
  {
    Code: "JM",
    Description: "Jamaica"
  },
  {
    Code: "JO",
    Description: "Jordan"
  },
  {
    Code: "JP",
    Description: "Japan"
  },
  {
    Code: "KE",
    Description: "Kenya"
  },
  {
    Code: "KG",
    Description: "Kyrgyzstan"
  },
  {
    Code: "KH",
    Description: "Cambodia"
  },
  {
    Code: "KI",
    Description: "Kiribati"
  },
  {
    Code: "KM",
    Description: "Comoros"
  },
  {
    Code: "KN",
    Description: "Saint Kitts and Nevis"
  },
  {
    Code: "KP",
    Description: "North Korea"
  },
  {
    Code: "KR",
    Description: "South Korea"
  },
  {
    Code: "KW",
    Description: "Kuwait"
  },
  {
    Code: "KY",
    Description: "Cayman Islands"
  },
  {
    Code: "KZ",
    Description: "Kazakhstan"
  },
  {
    Code: "LA",
    Description: "Laos"
  },
  {
    Code: "LB",
    Description: "Lebanon"
  },
  {
    Code: "LC",
    Description: "Saint Lucia"
  },
  {
    Code: "LI",
    Description: "Liechtenstein"
  },
  {
    Code: "LK",
    Description: "Sri Lanka"
  },
  {
    Code: "LR",
    Description: "Liberia"
  },
  {
    Code: "LS",
    Description: "Lesotho"
  },
  {
    Code: "LT",
    Description: "Lithuania"
  },
  {
    Code: "LU",
    Description: "Luxembourg"
  },
  {
    Code: "LV",
    Description: "Latvia"
  },
  {
    Code: "LY",
    Description: "Libya"
  },
  {
    Code: "MA",
    Description: "Morocco"
  },
  {
    Code: "MC",
    Description: "Monaco"
  },
  {
    Code: "MD",
    Description: "Moldova, Republic of"
  },
  {
    Code: "MG",
    Description: "Madagascar"
  },
  {
    Code: "MH",
    Description: "Marshall Islands"
  },
  {
    Code: "MK",
    Description: "Macedonia, The Former Yugoslav"
  },
  {
    Code: "ML",
    Description: "Mali"
  },
  {
    Code: "MM",
    Description: "Myanmar"
  },
  {
    Code: "MN",
    Description: "Mongolia"
  },
  {
    Code: "MO",
    Description: "Macau"
  },
  {
    Code: "MP",
    Description: "Northern Mariana Islands"
  },
  {
    Code: "MQ",
    Description: "Martinique"
  },
  {
    Code: "MR",
    Description: "Mauritania"
  },
  {
    Code: "MS",
    Description: "Montserrat"
  },
  {
    Code: "MT",
    Description: "Malta"
  },
  {
    Code: "MU",
    Description: "Mauritius"
  },
  {
    Code: "MV",
    Description: "Maldives"
  },
  {
    Code: "MW",
    Description: "Malawi"
  },
  {
    Code: "MX",
    Description: "Mexico"
  },
  {
    Code: "MY",
    Description: "Malaysia"
  },
  {
    Code: "MZ",
    Description: "Mozambique"
  },
  {
    Code: "NA",
    Description: "Namibia"
  },
  {
    Code: "NC",
    Description: "New Caledonia"
  },
  {
    Code: "NE",
    Description: "Niger"
  },
  {
    Code: "NF",
    Description: "Norfolk Island"
  },
  {
    Code: "NG",
    Description: "Nigeria"
  },
  {
    Code: "NI",
    Description: "Nicaragua"
  },
  {
    Code: "NL",
    Description: "Netherlands"
  },
  {
    Code: "NN",
    Description: "None"
  },
  {
    Code: "NO",
    Description: "Norway"
  },
  {
    Code: "NP",
    Description: "Nepal"
  },
  {
    Code: "NR",
    Description: "Nauru"
  },
  {
    Code: "NU",
    Description: "Niue"
  },
  {
    Code: "NZ",
    Description: "New Zealand"
  },
  {
    Code: "OM",
    Description: "Oman"
  },
  {
    Code: "PA",
    Description: "Panama"
  },
  {
    Code: "PE",
    Description: "Peru"
  },
  {
    Code: "PF",
    Description: "French Polynesia"
  },
  {
    Code: "PG",
    Description: "Papua New Guinea"
  },
  {
    Code: "PH",
    Description: "Phillippines"
  },
  {
    Code: "PK",
    Description: "Pakistan"
  },
  {
    Code: "PL",
    Description: "Poland"
  },
  {
    Code: "PM",
    Description: "St. Pierre and Miquelon"
  },
  {
    Code: "PN",
    Description: "Pitcairn"
  },
  {
    Code: "PR",
    Description: "Puerto Rico"
  },
  {
    Code: "PS",
    Description: "Palestinian Territory, Occupie"
  },
  {
    Code: "PT",
    Description: "Portugal"
  },
  {
    Code: "PW",
    Description: "Palau"
  },
  {
    Code: "PY",
    Description: "Paraguay"
  },
  {
    Code: "QA",
    Description: "Qatar"
  },
  {
    Code: "RE",
    Description: "Reunion"
  },
  {
    Code: "RO",
    Description: "Romania"
  },
  {
    Code: "RU",
    Description: "Russia"
  },
  {
    Code: "RW",
    Description: "Rwanda"
  },
  {
    Code: "SA",
    Description: "Saudi Arabia"
  },
  {
    Code: "SB",
    Description: "Solomon Islands"
  },
  {
    Code: "SC",
    Description: "Seychelles"
  },
  {
    Code: "SD",
    Description: "Sudan"
  },
  {
    Code: "SE",
    Description: "Sweden"
  },
  {
    Code: "SG",
    Description: "Singapore"
  },
  {
    Code: "SH",
    Description: "Saint Helena"
  },
  {
    Code: "SI",
    Description: "Slovenia"
  },
  {
    Code: "SJ",
    Description: "Svalbard and Jan Mayen"
  },
  {
    Code: "SK",
    Description: "Slovakia"
  },
  {
    Code: "SL",
    Description: "Sierra Leone"
  },
  {
    Code: "SM",
    Description: "San Marino"
  },
  {
    Code: "SN",
    Description: "Senegal"
  },
  {
    Code: "SO",
    Description: "Somalia"
  },
  {
    Code: "SR",
    Description: "Suriname"
  },
  {
    Code: "ST",
    Description: "Sao Tome and Principe"
  },
  {
    Code: "SV",
    Description: "El Salvador"
  },
  {
    Code: "SY",
    Description: "Syria"
  },
  {
    Code: "SZ",
    Description: "Swaziland"
  },
  {
    Code: "TC",
    Description: "Turks and Caicos Islands"
  },
  {
    Code: "TD",
    Description: "Chad"
  },
  {
    Code: "TF",
    Description: "French Southern Territories"
  },
  {
    Code: "TG",
    Description: "Togo"
  },
  {
    Code: "TH",
    Description: "Thailand"
  },
  {
    Code: "TJ",
    Description: "Tajikistan"
  },
  {
    Code: "TK",
    Description: "Tokelau"
  },
  {
    Code: "TL",
    Description: "Timor-Leste"
  },
  {
    Code: "TM",
    Description: "Turkmenistan"
  },
  {
    Code: "TN",
    Description: "Tunisia"
  },
  {
    Code: "TO",
    Description: "Tonga"
  },
  {
    Code: "TR",
    Description: "Turkey",
    "Description 2": "TU"
  },
  {
    Code: "TT",
    Description: "Trinidad and Tobago"
  },
  {
    Code: "TV",
    Description: "Tuvalu"
  },
  {
    Code: "TW",
    Description: "Taiwan"
  },
  {
    Code: "TZ",
    Description: "Tanzania"
  },
  {
    Code: "UA",
    Description: "Ukrainian SSR"
  },
  {
    Code: "UG",
    Description: "Uganda"
  },
  {
    Code: "UM",
    Description: "United States Minor Outlying I"
  },
  {
    Code: "US",
    Description: "United States"
  },
  {
    Code: "UY",
    Description: "Uruguay"
  },
  {
    Code: "UZ",
    Description: "Uzbekistan"
  },
  {
    Code: "VA",
    Description: "Vatican City"
  },
  {
    Code: "VC",
    Description: "Saint Vincent & the Grenadines"
  },
  {
    Code: "VE",
    Description: "Venezuela"
  },
  {
    Code: "VG",
    Description: "British Virgin Islands"
  },
  {
    Code: "VI",
    Description: "US Virgin Islands"
  },
  {
    Code: "VN",
    Description: "Vietnam"
  },
  {
    Code: "VU",
    Description: "Vanuatu"
  },
  {
    Code: "WF",
    Description: "Wallis and Futuna Islands"
  },
  {
    Code: "WS",
    Description: "Samoa"
  },
  {
    Code: "YE",
    Description: "Yemen"
  },
  {
    Code: "YT",
    Description: "Mayotte"
  },
  {
    Code: "ZA",
    Description: "South Africa"
  },
  {
    Code: "ZM",
    Description: "Zambia"
  },
  {
    Code: "ZW",
    Description: "Zimbabwe"
  }
]
