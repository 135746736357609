import axios from "axios";

import companyService from "@/services/api/company";
import configService from "@/services/api/config";
import usersService from "@/services/api/users";
import JDXOService from "@/services/api/jdox";

const axiosDefaultOptions = {
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AUTH_KEY}`,
    "x-locale": localStorage.getItem("x-locale") || "en",
  },
};

const nativeAxiosInstance = axios.create(axiosDefaultOptions);

const customAxiosInstance = {
  ...nativeAxiosInstance,
  setBaseURL: (url) => {
    nativeAxiosInstance.defaults.baseURL = url;
  },
};

export default {
  install: (Vue) => {
    Vue.prototype.$api = {
      company: companyService(customAxiosInstance),
      config: configService(customAxiosInstance),
      users: usersService(customAxiosInstance),
      jdox: JDXOService(customAxiosInstance),
    };
  },
};
