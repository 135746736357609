<template>
  <div>
    <v-text-field
      outlined
      :rules="isItRequired"
      :label="generateLabel"
      :value="defaultValue"
      :disabled="isDisabled"
      :hint="field.hint"
      v-model="barcode"
      append-icon="mdi-barcode-scan"
      @click:append="handleScanClick"
      @change="handleChange"
      dense
    />
    <div
      class="d-none justify-center mb-4"
      :class="{ 'd-flex': visualiseBarcode && currentCode }"
    >
      <canvas id="barcode-canvas" />
    </div>
    <v-dialog
      v-model="videoPreview"
      :width="calculateWidth"
      @click:outside="closeAndSelect"
    >
      <v-sheet class="pa-4">
        <div v-show="!isLoading" id="UIElement">
          <v-select
            outlined
            label="Cameras"
            v-model="currentCameraId"
            :items="availableCameras"
            item-value="deviceId"
            item-text="label"
            @change="handleCameraChange"
            hide-details
            class="mb-4"
          />
          <div id="UIElement" class="mb-4" :style="calculateStyles">
            <div
              id="div-ui-container"
              style="width: 100%; height: 100%; position: relative"
            >
              <div
                class="dce-video-container"
                style="position: absolute; width: 100%; height: 100%"
              />
              <div
                class="dce-scanarea"
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  overflow: hidden;
                "
              >
                <div
                  class="dce-scanlight"
                  hidden
                  style="
                    width: 100%;
                    display: none;
                    height: 70px;
                    position: absolute;
                    animation: 3s infinite dce-scanlight;
                    background-image: linear-gradient(#ffffff00, #ffb668);
                    border-bottom: 2px solid #ff8400;
                    user-select: none;
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isLoading"
          class="d-flex flex-column align-center justify-center pa-4"
        >
          <v-progress-circular
            color="primary"
            v-if="isLoading"
            indeterminate
            size="32"
            class="mb-4"
          />
          <p class="text-center text-subtitle-1 mb-0">
            Initializing barcode scanner
          </p>
        </div>
        <div v-if="visualiseBarcode" class="d-flex justify-center">
          <v-btn color="primary" @click="closeAndSelect">Close</v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  BarcodeScanner,
  EnumBarcodeFormat,
} from "dynamsoft-javascript-barcode";
import bwipjs from "bwip-js";

import BaseField from "@/mixins/BaseField";
export default {
  name: "BardcodeField",
  mixins: [BaseField],
  /**
   * @vue-prop {Object} field
   * @vue-prop {Boolean} textarea
   * @vue-prop {String|Number|Object} value
   * @vue-prop {Array} filters
   * @vue-prop {Boolean} disabled
   */
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    unsubscribe: null,
    barcodeReaderInstance: null,
    currentCode: null,
    barcode: "",
    isLoading: true,
    videoPreview: false,
    availableCameras: [],
    currentCameraId: "",
    barcodeType: "",
    widthScaling: 0,
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    calculateWidth() {
      300;
    },
    calculateHeight() {
      return "400px";
    },
    calculateStyles() {
      return `width: ${this.calculateWidth - 32}px; height: ${
        this.calculateHeight
      };`;
    },
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
    visualiseBarcode() {
      return this.field.linked_attributes.generate_barcode;
    },
    getTextColor() {
      return this.$vuetify.theme.isDark ? "FFFFFF" : "000000";
    },
  },
  mounted() {
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
    if (!BarcodeScanner.license) {
      BarcodeScanner.license = process.env.VUE_APP_DYNAMO_LICENSE;
      BarcodeScanner.engineResourcePath =
        "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.20/dist/";
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.barcodeScanner?.close();
  },
  methods: {
    closeAndSelect() {
      this.barcodeScanner.hide();
      this.videoPreview = false;
      this.$set(this, "barcode", this.currentCode);
      this.handleChange(this.barcode);
    },
    convertBarcodeTypeAndSetScale(barcodeType) {
      switch (barcodeType) {
        case "EAN_13":
          this.widthScaling = 2.5;
          return "ean13";
        case "DATAMATRIX":
          this.widthScaling = 1;
          return "datamatrix";
        case "CODE_128":
          this.widthScaling = 2.5;
          return "code128";
        case "QR_CODE":
          this.widthScaling = 1;
          return "qrcode";
        case "GS1 Databar Omnidirectional":
          this.widthScaling = 2.5;
          return "databaromni";
        default:
          this.widthScaling = 2.5;
          return "ean13";
      }
    },
    generateBarcode() {
      try {
        bwipjs.toCanvas("#barcode-canvas", {
          bcid: this.barcodeType,
          text: this.currentCode,
          scale: 3,
          height: 10,
          width: 10 * this.widthScaling,
          includetext: true,
          textxalign: "center",
          barcolor: this.getTextColor,
          textcolor: this.getTextColor,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async handleCameraChange() {
      await this.barcodeScanner.setCurrentCamera(this.currentCameraId);
    },
    handleScanClick() {
      this.isLoading = true;
      this.videoPreview = true;
      this.currentCode = null;
      this.$nextTick(async () => {
        await this.initDynamoSoft();
        this.barcodeScanner.show();
      });
    },
    async initDynamoSoft() {
      this.barcodeScanner = await BarcodeScanner.createInstance();
      this.barcodeScanner.onUniqueRead = (txt, res) => {
        this.$set(this, "currentCode", txt);
        this.closeAndSelect();
        if (this.visualiseBarcode) {
          this.barcodeType = this.convertBarcodeTypeAndSetScale(
            res.barcodeFormatString
          );
          this.generateBarcode();
        } else {
          this.barcodeScanner.hide();
          this.videoPreview = false;
          this.$set(this, "barcode", this.currentCode);
          this.handleChange(this.barcode);
        }
      };

      // Sets which camera and what resolution to use
      this.availableCameras = await this.barcodeScanner.getAllCameras();
      const backCamera = this.availableCameras.find(
        (camera) => camera.label.toLowerCase() === "back camera"
      );
      this.currentCameraId =
        backCamera?.deviceId || this.availableCameras[0].deviceId;
      await this.barcodeScanner.setCurrentCamera(this.currentCameraId);
      // Sets up the scanner behavior
      let scanSettings = await this.barcodeScanner.getScanSettings();
      // Disregards duplicated results found in a specified time period (in milliseconds).
      scanSettings.duplicateForgetTime = 3000; // The default is 3000
      // Sets a scan interval in milliseconds so the SDK may release the CPU from time to time.
      // (setting this value larger is a simple way to save battery power and reduce device heating).
      scanSettings.intervalTime = 100; // The default is 0.
      scanSettings.soundOnSuccessfullRead = "default";
      scanSettings.whenToPlaySoundforSuccessfulRead = "unique";
      scanSettings.autoFocus = true;
      this.barcodeScanner.setVideoFit("cover");

      // Sets captureAndDecodeInParallel to false, which tells the SDK not to acquire the next frame while decoding the first.
      // This is another way to save battery power and is recommended on low-end phones. However, it does slow down the decoding speed.
      scanSettings.captureAndDecodeInParallel = false; // The default is txrue.
      await this.barcodeScanner.updateScanSettings(scanSettings);

      // Uses one of the built-in RuntimeSetting templates: "single" (decode a single barcode, the default mode), "speed", "balance", "coverage", "dense" and "distance"
      await this.barcodeScanner.updateRuntimeSettings("single");

      // Makes changes to the template. The code below demonstrates how to specify enabled symbologies
      let runtimeSettings = await this.barcodeScanner.getRuntimeSettings();
      runtimeSettings.barcodeFormatIds =
        EnumBarcodeFormat.BF_ONED |
        EnumBarcodeFormat.BF_QR_CODE |
        EnumBarcodeFormat.BF_DATAMATRIX |
        EnumBarcodeFormat.BF_GS1_DATABAR_OMNIDIRECTIONAL;
      await this.barcodeScanner.updateRuntimeSettings(runtimeSettings);
      await this.barcodeScanner.setUIElement(
        document.getElementById("div-ui-container")
      );
      this.isLoading = false;
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    handleChange(value) {
      this.$emit("selected", { [this.field.key]: value });
    },
  },
};
</script>

<style lang="scss" scoped>
#UIElement {
  text-align: center;
  font-size: medium;
}
</style>
