import Vue from "vue";
import Vuex from "vuex";
import company from "./company";
import users from "./users";
import common from "./common";
import contact from "./contact";
import widgets from "./widgets";
import templates from "./templates";
import notifications from "./notifications";
import connectors from "./connectors";
import salesforce from "./salesforce";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    company,
    users,
    common,
    contact,
    widgets,
    templates,
    notifications,
    connectors,
    salesforce,
  },
});
