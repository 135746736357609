export default [
  {
    title: "EURO",
    value: "EUR",
  },
  {
    title: "US DOLLAR",
    value: "USD",
  },
  {
    title: "CANADIAN DOLLAR",
    value: "CAD",
  },
  {
    title: "SWISS FRANC",
    value: "CHF",
  },
  {
    title: "BRITISH POUND",
    value: "GBP",
  },
  {
    title: "NORWEGIAN KRONE",
    value: "NOK",
  },
  {
    title: "SOUTH AFRICAN RAND",
    value: "ZAR",
  },
  {
    title: "SWEDISH KRONA",
    value: "SEK",
  },
  {
    title: "TURKISH LIRA",
    value: "TRY",
  },
  {
    title: "MEXICAN PESO",
    value: "MXN",
  },
  {
    title: "CHILEAN PESO",
    value: "CLP",
  },
  {
    title: "SOUTH KOREA WON",
    value: "KRW",
  },
  {
    title: "AUSTRALIAN DOLLAR",
    value: "AUD",
  },
  {
    title: "CHINA RENMINBI",
    value: "CNY",
  },
  {
    title: "INDIAN RUPEE",
    value: "INR",
  },
  {
    title: "THAI BAHT",
    value: "THB",
  },
  {
    title: "POLISH ZLOTY",
    value: "PLN",
  },
  {
    title: "BRAZILIAN REAL",
    value: "BRL",
  },
];
