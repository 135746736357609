<template>
  <v-dialog v-model="authDialog" persistent>
    <v-card>
      <v-card-title class="brand-header px-4 py-2">
        <h6 class="text-subtitle-1">{{ $t("jde_auth_title") }}</h6>
      </v-card-title>
      <v-card-text class="px-4 py-2">
        <v-form ref="authForm">
          <v-text-field
            v-model="credentials.userId"
            :label="$t('jde_username_label')"
            autocomplete="username"
            outlined
            dense
            :rules="[validation.required]"
          />
          <v-text-field
            v-model="credentials.userPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('jde_password_label')"
            outlined
            dense
            autocomplete="current-password"
            :rules="[validation.required]"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="flex justify__between">
        <v-btn
          class="page-btn--primary"
          :loading="isLoading"
          @click="verifyCredentials"
          small
        >
          {{ $t("login_label") }}
        </v-btn>
        <v-btn class="page-btn--error" @click="cancel" small>
          {{ $t("cancel_label") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import fieldsAPI from "@/api/fields";
export default {
  name: "AuthenticateJDE",
  data: () => ({
    authDialog: false,
    credentials: {},
    showPassword: false,
    isLoading: false,
    cookiesInstance: {},
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      selectedConnector: (state) => state.connectors.selectedConnector,
    }),
  },
  mounted() {
    localStorage.removeItem("JDEToken");
    if (this.currentFlow.config.main_config.auth_on_exec) {
      localStorage.setItem("requires-auth", true);
      localStorage.removeItem("JDEToken");
      this.authDialog = true;
    }
    if (localStorage.getItem("JDEUsername")) {
      this.credentials.userId = localStorage.getItem("JDEUsername");
      this.credentials.userPassword = atob(localStorage.getItem("JDEPassword"));
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    cancel() {
      this.authDialog = false;
      this.$router.push("/app-menus");
    },
    async verifyCredentials() {
      if (!this.$refs.authForm.validate()) return;
      this.isLoading = true;
      try {
        const result = await fieldsAPI.authenticateJDE({
          connectorName: this.selectedConnector.connector_name,
          userId: this.selectedConnector.connector_user,
          userPassword: this.selectedConnector.password,
          ...this.currentFlow,
          ...this.credentials,
          ...this.currentUser,
        });
        localStorage.setItem("JDEToken", result.user_auth?.userInfo?.token);
        localStorage.setItem("JDEUsername", this.credentials.userId);
        localStorage.setItem(
          "JDEPassword",
          btoa(this.credentials.userPassword)
        );
        localStorage.setItem("JDELocale", result.user_auth?.userInfo?.langPref);
        this.authDialog = false;
        this.credentials = {};
        this.$refs.authForm.reset();
        this.$emit("authenticated", result.user_auth?.userInfo?.token);
      } catch (error) {
        console.log(error);
        this.showSnackbar({ type: "error", message: error.message });
      }
      this.isLoading = false;
    },
  },
};
</script>
