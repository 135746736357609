export const getCompanyUrl = () => {
  if (process.env.VUE_APP_ENV === "dev") {
    return "https://cnmx1tn8h7.execute-api.us-east-1.amazonaws.com";
  }
  return "https://lpkkxggpl9.execute-api.us-east-1.amazonaws.com";
};

export const getConfigUrl = () => {
  if (process.env.VUE_APP_ENV === "dev") {
    return "https://hcb8d7oi2e.execute-api.us-east-1.amazonaws.com/";
  }
  return "https://scwoieh6tf.execute-api.us-east-1.amazonaws.com/";
};

export const getUsersUrl = () => {
  if (process.env.VUE_APP_ENV === "dev") {
    return "https://cwqs1146e5.execute-api.us-east-1.amazonaws.com";
  }
  return "https://s7jtfq8t1f.execute-api.us-east-1.amazonaws.com";
};

export const getJDOXUrl = () => {
  return "https://dev.api.jdox.io/api";
};
