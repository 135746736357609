import { getConfigUrl } from "@/config/endpointsUrl";
import axios from "axios";

const getInstance = (axios) => {
  axios.setBaseURL(getConfigUrl());
  return axios;
};

const generateUploadFilePayload = (payload) => ({
  company_teams_tid: payload.company_teams_tid,
  key: payload.file.name,
  po_key: payload.po_key,
  env: payload.env,
  flow_name: payload.flow_name,
});

const generateCreateTemplate = (
  { currentUser, currentFlow, name },
  payload
) => ({
  name,
  botflow_id: currentFlow.id,
  user_id: currentUser.bot_user_id,
  payload,
});

const generateClearLinkedInformationPayload = (currentBotUser) => ({
  company_id: currentBotUser.company_id,
  groups: currentBotUser.groups || [],
  user_email: currentBotUser.email,
});

export default (axiosInApp) => ({
  fetchBotFlows: async (companyId) => {
    const { data } = await getInstance(axiosInApp).get("/botflows", {
      params: { company_id: companyId },
    });
    return data.botflows;
  },
  uploadFile: async (payload, callback) => {
    try {
      const { data } = await getInstance(axiosInApp).post(
        "/uploadfile",
        generateUploadFilePayload(payload)
      );
      const file = payload.file;
      await axios.put(data.url, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": `attachment; filename="${encodeURI(
            file.name
          )}"`,
        },
      });
      if (callback) {
        callback(payload.file.name, null);
      }
    } catch (error) {
      console.log(error);
      callback(payload.file.name, true);
    }
  },
  storeLinkedData: async (payload) => {
    await getInstance(axiosInApp).post("/store-linked-information", payload);
  },
  getLinkedData: async (payload) => {
    const { data } = await getInstance(axiosInApp).post(
      "/get-linked-information",
      payload
    );
    return data.linked_information[0];
  },
  updateLinkedInformation: async (payload) => {
    await getInstance(axiosInApp).patch("/linked-information", payload);
  },
  updateApprovalNotification: async (payload) => {
    await getInstance(axiosInApp).patch("/approval-information", {
      id: payload.id,
      approval_status: payload.approvalData.status,
      ab_number: payload.approvalData?.ab_number,
      notes: payload.approvalData.notes,
      origin: "OUTLOOK",
      environment: payload.env,
      connector_id: payload.connectorId,
      obot_approval_type: payload.obotApprovalType,
    });
  },
  clearNotifications: async (currentBotUser) => {
    await getInstance(axiosInApp).post(
      "/clear-linked-information",
      generateClearLinkedInformationPayload(currentBotUser)
    );
  },
  deleteNotification: async (notificationId, companyId) => {
    await getInstance(axiosInApp).delete("/notification", {
      params: {
        id: notificationId,
        company_id: companyId,
      },
    });
  },
  getNotifications: async (payload) => {
    const { data } = await getInstance(axiosInApp).get("/notifications", {
      params: payload,
    });
    return data.linked_information;
  },
  checkForActiveLinks: async (payload) => {
    try {
      const { data } = await getInstance(axiosInApp).get("/active-links", {
        params: payload,
      });
      return data;
    } catch (error) {
      return false;
    }
  },
  fetchWidgets: async (currentUser) => {
    const { data } = await getInstance(axiosInApp).get("/widgets", {
      params: { company_id: currentUser.company_id },
    });
    return data.widgets;
  },
  getTemplate: async (botflowId, templateName) => {
    const { data } = await getInstance(axiosInApp).get("/botflow-template", {
      params: { botflow_id: botflowId, name: templateName },
    });
    return data.template;
  },
  createTemplate: async (config, payload) => {
    await getInstance(axiosInApp).post(
      "/botflow-template",
      generateCreateTemplate(config, payload)
    );
  },
  getTemplatesPerBotflow: async (botflowId, botUserId) => {
    const { data } = await getInstance(axiosInApp).get("/botflow-templates", {
      params: { botflow_id: botflowId, bot_user_id: botUserId },
    });
    return data.templates || [];
  },
  getBotflowsPerConnector: async (connectorId) => {
    const { data } = await getInstance(axiosInApp).get(
      "/botflows-per-connector",
      {
        params: { connector_id: connectorId },
      }
    );
    return data.botflows;
  },
});
