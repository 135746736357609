<template>
  <div>
    <v-text-field
      outlined
      :value="field.default"
      :label="field.published_name"
      dense
      :hint="field.hint"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
export default {
  name: "StaticField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDisabled() {
      if (
        (this.field.grid_action_value || this.field.linked_value) &&
        this.field.default
      ) {
        return true;
      }
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
};
</script>
