<template>
  <div>
    <v-text-field
      outlined
      :rules="isItRequired"
      :label="generateLabel"
      @change="handleChange"
      :value="defaultValue"
      :disabled="isDisabled"
      :counter="hasLimit ? limit : null"
      :maxlength="hasLimit ? limit : null"
      dense
      :hint="field.hint"
    />
  </div>
</template>

<script>
import BaseField from "@/mixins/BaseField";

export default {
  name: "HTML5String",
  mixins: [BaseField],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    limit: 0,
  }),
  computed: {
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required, ...this.customRules]
        : [...this.customRules];
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    hasLimit() {
      return (
        this.field.character_limit &&
        this.field.character_limit.length &&
        this.field.character_limit !== "0"
      );
    },
    isDisabled() {
      if (
        (this.field.grid_action_value || this.field.linked_value) &&
        this.field.default
      ) {
        return true;
      }
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (this.hasLimit) {
      this.limit = parseInt(this.field.character_limit);
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("text-change", { [this.field.key]: value });
    },
  },
};
</script>
