<template>
  <div>
    <v-text-field
      v-if="!isTextArea"
      outlined
      :rules="isItRequired"
      :label="generateLabel"
      @change="handleChange"
      :value="defaultValue"
      :class="classes"
      :disabled="isDisabled"
      :counter="hasLimit ? limit : null"
      :maxlength="hasLimit ? limit : null"
      dense
      :hint="field.hint"
    />
    <v-textarea
      v-if="isTextArea"
      outlined
      :rules="isItRequired"
      :label="generateLabel"
      @change="handleChange"
      rows="3"
      :value="defaultValue"
      :class="classes"
      :disabled="isDisabled"
      :counter="hasLimit ? limit : null"
      :maxlength="hasLimit ? limit : null"
      dense
      :hint="field.hint"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseField from "@/mixins/BaseField";

export default {
  name: "TextField",
  mixins: [BaseField],
  props: {
    field: {
      type: Object,
      required: true,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    filters: {
      type: Array,
      default: () => [],
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    limit: 0,
    unsubscribe: null,
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      const rules = [];
      if (this.hasLimit) {
        rules.push(this.validation.maxLength(this.limit));
      }
      if (this.field.element_required || this.field.required) {
        rules.push(this.validation.required, ...this.customRules);
      }
      return rules.length ? rules : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    classes() {
      let classes = "";
      if (
        this.filters.includes("uppercase") ||
        this.field.linked_attributes?.casing === "Uppercase"
      ) {
        classes += "text-field--uppercase";
      }
      if (this.field.linked_attributes?.casing === "Lowercase") {
        classes += "text-field--lowercase";
      }
      return classes;
    },
    hasLimit() {
      return (
        this.field.character_limit &&
        this.field.character_limit.length &&
        this.field.character_limit !== "0"
      );
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return this.disabled;
    },
    isTextArea() {
      return this.textarea || this.field.linked_attributes?.textarea;
    },
  },
  created() {
    if (this.hasLimit) {
      this.limit = parseInt(this.field.character_limit);
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    handleChange(value) {
      let modifiedValue = value;
      if (
        this.filters.includes("uppercase") ||
        this.field.linked_attributes?.casing === "Uppercase"
      ) {
        modifiedValue = modifiedValue.toUpperCase();
      }
      if (this.field.linked_attributes?.casing === "Lowercase") {
        modifiedValue = modifiedValue.toLowerCase();
      }
      this.$emit("text-change", { [this.field.key]: modifiedValue });
    },
  },
};
</script>
