export default {
  state: () => ({
    widgets: [],
    isFetchingWidgets: false,
  }),

  mutations: {
    setWidgets(state, widgets) {
      state.widgets = widgets;
    },
    setIsFetchingWidgets(state, value) {
      state.isFetchingWidgets = value;
    },
  },
  actions: {
    async fetchWidgets({ commit, rootState }) {
      commit("setIsFetchingWidgets", true);
      const widgets = await this._vm.$api.config.fetchWidgets(
        rootState.users.currentUser
      );
      commit("setWidgets", widgets);
      commit("setIsFetchingWidgets", false);
    },
  },
  namespaced: true,
};
