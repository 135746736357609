<template>
  <div>
    <v-autocomplete
      outlined
      :items="fieldsFromReference"
      :label="generateLabel"
      :rules="isItRequired"
      :value="defaultValue"
      :hint="field.hint"
      item-text="Name"
      dense
      item-value="Id"
      :disabled="isDisabled"
      :loading="isFetchingSFObjectFields || pullingData"
      @change="handleValueChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseField from "@/mixins/BaseField";
import sfAPI from "@/api/salesforce";

export default {
  name: "SFReferenceField",
  mixins: [BaseField],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },
  data: () => ({
    unsubscribe: null,
    fieldsFromReference: [],
    referenceField: null,
    pullingData: false,
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      isFetchingSFObjectFields: (state) =>
        state.salesforce.isFetchingSFObjectFields,
      sfObjectFields: (state) => state.salesforce.sfObjectFields,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.requiredAllowEmpty]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  watch: {
    sfObjectFields(newVal) {
      if (this.preview) return;
      const currentReferenceToField = newVal.find(
        (field) => field.name === this.field.key && field.type === "reference"
      );
      this.referenceField = currentReferenceToField.referenceTo[0];
      this.fetchParentField();
    },
  },
  created() {
    if (!this.isFetchingSFObjectFields) {
      this.$store.dispatch("salesforce/fetchSFObjectFields", {
        sfObject: this.currentFlow.linked_orch_name,
        connectorName: this.currentFlow.ais_config,
      });
    }
  },
  methods: {
    handleValueChange(value) {
      this.$emit("selected", { [this.field.key]: value });
    },
    async fetchParentField() {
      try {
        this.pullingData = true;
        const fields = await sfAPI.getAllRecordsPerObject({
          ...this.currentUser,
          ...this.currentFlow,
          sfObject: this.currentFlow.linked_orch_name,
        });
        this.fieldsFromReference = fields;
      } catch (error) {
        this.$logError(error);
      } finally {
        this.pullingData = false;
      }
    },
  },
};
</script>
