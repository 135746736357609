import axios from "axios";

const contactInstance = (apiKey) =>
  axios.create({
    baseURL:
      "https://2p44e7wlfnvkhqsud4kneh6mym0jldvh.lambda-url.us-east-1.on.aws/",
    headers: {
      Authorization: "KEY k3RT!z8Zt2WW1-90A#23?7highTTT",
      "x-api-key": apiKey,
      "x-env": process.env.VUE_APP_ENV === "dev" ? "dev" : "prod",
    },
  });

export default {
  state: () => ({
    contacts: [],
    contact: {},
  }),
  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setContact(state, contact) {
      state.contact = contact;
    },
  },
  actions: {
    async searchContactByEmail({ commit, rootState }, email) {
      const companySettings = rootState.company.settings;
      const { data } = await contactInstance(companySettings.api_key).post("", {
        route: "outlook_find_contact",
        outlookDomain: companySettings.tenant_id_outlook,
        contact_email: email,
        channel: "OUTLOOK",
      });
      const contacts = data.contact_data || [];
      const contactData = contacts[0] || {};
      commit("setContact", contactData);
      commit("setContacts", contacts);
    },
  },
  namespaced: true,
};
