<template>
  <v-app class="app">
    <v-navigation-drawer
      v-if="!isFileViewer"
      :class="isDark ? 'black' : 'white'"
      v-model="drawer"
      app
      right
    >
      <v-list nav dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="d-flex align-center justify-space-between title"
            >
              <div class="d-flex">
                <v-btn
                  depressed
                  class="mx-2"
                  v-if="selectedConnector && !isHamon"
                  outlined
                  color="orange"
                  small
                  @click="showEnvironmentDialog"
                >
                  {{ selectedConnector.environment }}
                </v-btn>
              </div>
              <div class="icon-container">
                <theme-switcher></theme-switcher>
              </div>
              <locales />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense nav>
        <v-list-item
          v-for="item in filteredItems"
          :key="item.title"
          link
          :to="item.path"
          class="font__inter navigation-link"
          :disabled="item.path !== '/' && isHamon"
        >
          <v-list-item-icon>
            <v-badge
              overlap
              :content="notifications.length"
              v-if="item.path === '/notifications' && notifications.length"
            >
              <v-icon class="navigation-link__icon">{{ item.icon }}</v-icon>
            </v-badge>
            <v-icon
              v-if="item.path !== '/notifications' || !notifications.length"
              class="navigation-link__icon"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="navigation-link__label">
              {{ $t(item.title + "_label") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group class="mt-2 font__inter" v-if="isHamon">
          <template v-slot:activator>
            <v-list-item-icon><hamon-svg id="hamon-svg" /></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>HAMON</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            @click="handleMenuItemClick(menuItem)"
            v-for="menuItem in filteredMenuItems"
            :key="menuItem.name"
          >
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          class="font__inter navigation-link"
          @click="showEnvironmentDialog"
        >
          <v-list-item-icon>
            <v-icon class="navigation-link__icon">
              mdi-office-building-cog
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navigation-link__label">
              {{ $t("change_environment_label") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="info-container">
          <p>OrchestratorBot Pane v{{ currentVersion }}</p>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar v-if="!accessDenied && !isLoading && !ie11" app>
      <v-toolbar-title>
        <img height="24px" width="24px" class="logo" :src="logoLink" />
      </v-toolbar-title>
      <v-spacer />
      <v-badge dot overlap v-if="notifications.length">
        <v-icon class="ml-2" v-if="!accessDenied" @click="drawer = !drawer">
          mdi-menu
        </v-icon>
      </v-badge>
      <v-icon
        class="ml-2"
        v-if="!accessDenied && !notifications.length && !isFileViewer"
        @click="drawer = !drawer"
      >
        mdi-menu
      </v-icon>
    </v-app-bar>
    <v-main v-if="!ie11" :key="updateOnItemChange">
      <router-view v-if="!accessDenied && !isLoading && !missingConnector" />
      <div
        v-if="accessDenied && !isLoading"
        class="page-container error-container"
      >
        <h2 class="font__inter">{{ errorTitle }}</h2>
        <p class="font__inter">{{ errorMessage }}</p>
      </div>
      <div v-if="isLoading" class="loading-container">
        <obot-logo :isLoading="isLoading" />
        <p class="mt-4">{{ $t("main_loader_text") }}</p>
      </div>
    </v-main>
    <v-main v-if="ie11">
      <v-alert outlined class="ma-4 ie-11-fix" dense type="warning">
        <h3>Unsupported version</h3>
        <p>
          This add-in won't run in your version of Office. Please upgrade to
          either one-time purchase Office 2021 or to a Microsoft 365 account.
        </p>
      </v-alert>
    </v-main>
    <div class="snackbar-container">
      <v-snackbar
        v-for="(snackbar, i) in snackbars"
        :key="i + generateKey"
        :value="true"
        :timeout="snackbar.timeout"
        :color="snackbar.type"
        style="padding-top: 0px; margin-top: 10px"
      >
        <p class="mb-0">
          {{ snackbar.message }}
        </p>
        <template #action="{ attrs }">
          <v-icon
            color="white"
            v-bind="attrs"
            @click="removeSnackbar(snackbar)"
          >
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>
    </div>
    <footer>
      <div class="flex justify__center" v-if="showSocials">
        <a
          href="https://www.linkedin.com/company/hight-io"
          target="_blank"
          class="link__socials"
        >
          <svg class="" fill="currentColor" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
              clip-rule="evenodd"
            />
          </svg>
        </a>

        <a
          href="https://www.facebook.com/Hightio-348395899560569"
          target="_blank"
          class="link__socials"
        >
          <svg class="" fill="currentColor" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clip-rule="evenodd"
            />
          </svg>
        </a>

        <a
          href="https://www.youtube.com/channel/UC_nrn072d7iTCIikBYl1P_w"
          target="_blank"
          class="link__socials"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
            />
          </svg>
        </a>

        <a
          href="https://twitter.com/hight_io"
          target="_blank"
          class="link__socials"
        >
          <svg class="" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
            />
          </svg>
        </a>
      </div>
      <p class="text-center mb__0">
        &copy; {{ getYear }} {{ $t("trademark_text") }}
      </p>
    </footer>
    <v-dialog v-model="selectedConnectorDialog" persistent max-width="600">
      <v-sheet width="auto" class="pa-4">
        <h2 class="text-h5 mb-4">Environments</h2>
        <div class="d-flex flex-wrap justify-center mb-4">
          <div
            v-if="isFetchingConnectors || !currentUser"
            class="d-flex flex-column align-center pa-4"
          >
            <v-progress-circular
              size="28"
              color="primary"
              indeterminate
              class="mb-2"
            />
            <p class="text-h6">Fetching environments...</p>
          </div>
          <v-select
            v-if="!isFetchingConnectors && currentUser"
            :value="selectedConnector"
            outlined
            label="Environments"
            :items="availableConnectors"
            return-object
            dense
            item-text="environment"
            item-value="environment"
            hide-details
            @change="setConnector"
          />
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="page-btn--error"
            @click="selectedConnectorDialog = false"
          >
            {{ $t("cancel_label") }}
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import HamonSVG from "@/assets/svg/hamon.svg";
import ObotLogo from "@/components/Logo";
import ThemeSwitcher from "@/components/ThemeSwitcher";
import Locales from "@/components/Locales.vue";
import { version } from "@/../package.json";
import { setCompanyColors } from "@/utils/common";
export default {
  components: { "hamon-svg": HamonSVG, ObotLogo, ThemeSwitcher, Locales },
  data: () => ({
    drawer: null,
    currentDomain: "",
    errorTitle: "Access denied",
    isLoading: true,
    errorMessage:
      "You are currently not enabled to use OrchestratorBot. Please contact your Office365 admin.",
    items: [
      {
        title: "home",
        icon: "mdi-home",
        path: "/",
      },
      {
        title: "message_center",
        icon: "mdi-email-open-outline",
        path: "/notifications",
      },

      { title: "menus", icon: "mdi-apps", path: "/app-menus" },
      {
        title: "attach_email",
        icon: "mdi-note-plus-outline",
        path: "/attachEmail",
      },
      {
        title: "look_up_contact",
        icon: "mdi-account-search-outline",
        path: "/findCustomer",
      },
      {
        title: "create_new_contact",
        icon: "mdi-account-plus-outline",
        path: "/add-contact",
      },
    ],
    accessDenied: false,
    hamonItems: [
      "HOME",
      "LOOK UP CONTACT",
      "ATTACH FULL EMAIL TO JDE",
      "SETTINGS",
      "ABOUT ORCHESTRATORBOT",
    ],
    demoItems: ["NOTIFICATIONS", "HOME", "APP MENUS"],
    ie11: false,
    currentVersion: "",
    updateOnItemChange: false,
    missingConnector: false,
    shouldRefresh: false,
    selectedConnectorDialog: false,
  }),
  computed: {
    ...mapState({
      menuItems: (state) => state.company.menuItems,
      currentUser: (state) => state.users.currentUser,
      settings: (state) => state.company.settings,
      snackbars: (state) => state.common.snackbars,
      demoState: (state) => state.company.demoState,
      outlookState: (state) => state.common.outlookState,
      notifications: (state) => state.notifications.notifications,
      isFetchingConnectors: (state) => state.connectors.isFetchingConnectors,
      selectedConnector: (state) => state.connectors.selectedConnector,
      connectors: (state) => state.connectors.connectors,
      isHamon: (state) => state.company.isHamon,
    }),
    /**
     * @return {Object} - Object containing the company colors
     */
    getCompanyColors() {
      return this.settings.colors || {};
    },
    isFileViewer() {
      const isFileViewer = localStorage.getItem("isFileViewer");
      return isFileViewer === "true";
    },
    logoLink() {
      return "orchestratorbot_zondertekst_logo.png";
    },
    isDark() {
      return this.$vuetify.theme.isDark;
    },
    generateKey() {
      return new Date().getTime();
    },
    filteredMenuItems() {
      return this.menuItems.filter((menuItem) => {
        if (menuItem.name.includes("HR") || menuItem.name.includes("TEST")) {
          return false;
        }
        return true;
      });
    },
    filteredItems() {
      const currentDomain =
        Office.context.mailbox.userProfile.emailAddress.split("@")[1];
      if (this.isHamon) {
        return this.items.filter((item) =>
          this.hamonItems.includes(item.title)
        );
      }
      if (this.demoState) {
        return this.items.filter((item) => this.demoItems.includes(item.title));
      }
      if (this.outlookState === "Compose") {
        return this.items.filter(
          (item) =>
            !item.path.includes("attachEmail") &&
            !item.path.includes("findCustomer")
        );
      }
      return this.items;
    },
    getYear() {
      return new Date().getFullYear();
    },
    availableConnectors() {
      if (this.isFetchingConnectors || !this.currentUser) return;
      if (this.isHamon) return;
      return this.connectors.filter((conn) =>
        this.currentUser.environments.includes(conn.environment)
      );
    },
    hasMultipleAvailableConnectors() {
      return this.availableConnectors?.length > 1;
    },
    showSocials() {
      if (!this.settings) return true;
      return !this.settings.hide_socials;
    },
  },
  watch: {
    selectedConnector(newVal, oldVal) {
      if (newVal.connector_id !== oldVal?.connector_id) {
        this.$store.dispatch("notifications/init");
      }
    },
  },
  created() {
    this.currentVersion = version;
    if (navigator.userAgent.includes("Trident")) {
      this.ie11 = true;
    }
    this.onOfficeLoaded();
    const clientLogo = localStorage.getItem("clientLogo");
    if (clientLogo) {
      this.setLoaderImage(clientLogo);
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleAppRefresh);
  },
  methods: {
    ...mapActions({
      fetchMenuItems: "company/fetchMenuItems",
      fetchBotFlows: "company/fetchBotFlows",
      fetchBotUsers: "users/fetchBotUsers",
      fetchSettings: "company/fetchSettings",
      showSnackbar: "common/showSnackbar",
    }),
    ...mapMutations({
      setCurrentMenuItem: "company/setCurrentMenuItem",
      removeSnackbar: "common/removeSnackbar",
      setDemoState: "company/setDemoState",
      setOutlookState: "common/setOutlookState",
      setSelectedConnector: "connectors/setSelectedConnector",
      setLoaderImage: "common/setLoaderImage",
    }),
    setConnector(connector) {
      this.setSelectedConnector(connector);
      localStorage.setItem("selectedConnector", JSON.stringify(connector));
      this.missingConnector = false;
      this.selectedConnectorDialog = false;
      if (this.shouldRefresh) {
        this.shouldRefresh = false;
        location.reload();
      }
    },
    showEnvironmentDialog() {
      this.selectedConnectorDialog = true;
      this.shouldRefresh = true;
    },
    handleAppRefresh() {
      localStorage.removeItem("JDEToken");
      window.removeEventListener("beforeunload", this.handleAppRefresh);
    },
    applyCustomTheme() {
      const theme = localStorage.getItem("theme");
      const currentThemeColors = this.getCompanyColors[theme];
      if (!currentThemeColors) return;
      setCompanyColors(currentThemeColors);
      const pagePrimaryBg = currentThemeColors["--page-primary"];
      if (!pagePrimaryBg) return;
      this.$vuetify.theme.themes[theme].primary = pagePrimaryBg;
    },
    async fetchDemoInformation() {
      try {
        await this.fetchSettings("demo");
      } catch (error) {
        console.error(error.message);
      }
    },
    handleItemChange() {
      this.updateOnItemChange = !this.updateOnItemChange;
    },
    async onOfficeLoaded() {
      if (this.isFileViewer) {
        this.isLoading = false;
        return;
      }
      this.currentDomain =
        Office.context.mailbox.userProfile.emailAddress.split("@")[1];
      Office.context.mailbox.addHandlerAsync(
        Office.EventType.ItemChanged,
        this.handleItemChange
      );
      if (
        Object.prototype.hasOwnProperty.call(
          Office.context.mailbox,
          "displayNewMessageForm"
        )
      ) {
        this.setOutlookState("Read");
      } else {
        this.setOutlookState("Compose");
      }
      try {
        // await this.fetchSettings("");
        await this.fetchSettings(this.currentDomain);
        await this.applyCustomTheme();
      } catch (error) {
        console.warn("Outlook domain not associated with a company!");
        this.setDemoState();
        await this.fetchDemoInformation();
      }
      const companyId = this.settings.company_id;
      await this.fetchBotUsers(companyId);
      await this.fetchBotFlows(companyId);
      if (
        (!this.demoState && !this.currentUser) ||
        this.currentUser.status !== "ENABLED" ||
        (!this.currentUser.channels.includes("OUTLOOK") &&
          !this.currentUser.channels.includes("MS OUTLOOK"))
      ) {
        this.accessDenied = true;
      }
      this.$sentry.setUser({
        company_id: this.currentUser?.company_id,
        id: this.currentUser?.bot_user_id,
      });
      await this.$store.dispatch("connectors/fetchConnectors");
      if (this.isHamon) {
        this.missingConnector = false;
        this.selectedConnectorDialog = false;
        this.isLoading = false;
        return;
      }
      const selectedConnector = localStorage.getItem("selectedConnector");
      if (selectedConnector) {
        this.missingConnector = false;
        this.setSelectedConnector(JSON.parse(selectedConnector));
      }
      if (!selectedConnector && this.connectors?.length > 0) {
        this.missingConnector = true;
        this.selectedConnectorDialog = true;
        this.trySilentConnectorSelection();
      }
      if (this.connectors?.length === 0) {
        this.missingConnector = false;
      }
      this.isLoading = false;
    },
    trySilentConnectorSelection() {
      const interval = setInterval(() => {
        if (this.availableConnectors.length > 1) {
          clearInterval(interval);
        }
        if (
          this.availableConnectors.length === 1 &&
          !this.isFetchingConnectors &&
          this.currentBotUser
        ) {
          this.setConnector(this.availableConnectors[0]);
          clearInterval(interval);
        }
      }, 400);
    },
    handleMenuItemClick(menuItem) {
      this.setCurrentMenuItem(menuItem);
      if (this.$route.path !== "/flows") {
        this.$router.push("/flows");
      }
    },
  },
};
</script>

<style lang="scss">
.logo {
  margin: auto;
  vertical-align: middle;
}
.icon-container {
  display: flex;
  justify-content: flex-end;
}
.info-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}

.font__inter {
  font-family: "InterL";
}
.link__socials + .link__socials {
  margin-left: 10px;
}
.link__socials svg {
  width: 24px;
  height: 24px;
}
.app {
  min-height: 100vh;
}
.app .v-application--wrap {
  min-height: 0;
}
footer {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
footer p {
  font-size: 14px;
  font-family: "InterL";
}
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-container h2 {
  color: red;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  p {
    font-size: 15px;
    text-align: center;
  }
}
</style>
