<template>
  <div>
    <v-dialog v-model="viewFilesDialog" max-width="100%" fullscreen persistent>
      <v-sheet class="d-flex flex-column" height="100%">
        <div
          class="d-flex align-center brand-header justify-space-between px-4 py-2 mb-2"
        >
          <div class="d-flex">
            <h3 class="mb-0 text-subtitle-1 mr-1">
              {{ $t("attachments_title") }}
            </h3>
            <v-icon color="primary" @click="fetchFiles" :disabled="isLoading">
              mdi-refresh
            </v-icon>
          </div>
          <v-icon color="primary" @click="closeDialog"> mdi-close</v-icon>
        </div>
        <div class="py-2 mb-2 flex-grow-1">
          <div v-if="isLoading" class="d-flex px-4 justify-center align-center">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <v-list v-if="!isLoading && files.length" rounded dense>
            <template v-for="(file, index) in files">
              <v-list-item :key="file.name" dense three-line>
                <v-list-item-icon>
                  <v-icon class="mr-1">{{ getFiletypeIcon(file) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <p class="subtitle-2 mb-0">
                      {{ file.itemName }}
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <p class="text-caption mb-0 mr-1">
                      {{ $t("sequence_label") }}: {{ file.sequence }}
                    </p>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <p class="text-caption mb-0 mr-1">
                      {{ $t("date_label") }}: {{ file.updateDate }}
                      {{ $t("user_label") }}:
                      {{ file.updateUserID }}
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action style="align-self: center">
                  <div v-if="!file.isLoading">
                    <v-icon
                      color="light-blue"
                      @click="fetchFileInformation(file, index)"
                    >
                      mdi-download
                    </v-icon>
                    <v-icon
                      class="ml-1"
                      color="light-blue"
                      @click="viewFile(file, index)"
                    >
                      mdi-eye
                    </v-icon>
                  </div>
                  <v-progress-circular
                    v-if="file.isLoading"
                    size="22"
                    indeterminate
                    color="primary"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < files.length - 1" :key="index" />
            </template>
          </v-list>
          <div class="px-4">
            <span v-if="!isLoading && !files.length" class="text-center">
              {{ $t("no_attachments_message") }}
            </span>
          </div>
        </div>
        <div class="w-100 d-flex justify-center px-4 py-2">
          <v-btn small color="primary" @click="uploadFiles">
            {{ $t("upload_label") }}
            <v-icon right> mdi-upload </v-icon>
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog
      v-model="uploadFilesDialog"
      width="100%"
      max-width="280"
      persistent
    >
      <additional-data
        :no-response="true"
        :for-contact="forContact"
        @close="close"
        @refresh="fetchFiles"
        :file-meta="uploadFileMeta"
      />
    </v-dialog>
  </div>
</template>

<script>
import { binaryStringToBlob } from "blob-util";
import { mapState } from "vuex";
import fieldsAPI from "@/api/fields";
import AdditionalData from "@/components/AdditionalData.vue";

export default {
  name: "ViewFiles",
  components: { AdditionalData },
  props: {
    forContact: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    viewFilesDialog: false,
    files: [],
    fileTypes: {
      pdf: "mdi-file-pdf-box",
      png: "mdi-file-png-box",
      jpg: "mdi-file-jpg-box",
      jpeg: "mdi-file-jpg-box",
      txt: "mdi-file-document-outline",
      xls: "mdi-microsoft-excel",
      xlsx: "mdi-microsoft-excel",
      odt: "mdi-file-word",
      docx: "mdi-file-word-outline",
      csv: "mdi-file-delimited",
    },
    isLoading: false,
    basePayload: {},
    uploadFileMeta: {},
    uploadFilesDialog: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      currentFlow: (state) => state.company.currentFlow,
      selectedConnector: (state) => state.connectors.selectedConnector,
      fileMeta: (state) => state.company.fileMeta,
    }),
  },
  methods: {
    close() {
      this.uploadFilesDialog = false;
    },
    uploadFiles() {
      this.uploadFilesDialog = true;
    },
    getFiletypeIcon(file) {
      if (file.isURL) return "mdi-web";
      const fileExtension = file.itemName.split(".").slice(-1);
      return this.fileTypes[fileExtension] || "mdi-file-document";
    },
    getContentType(type) {
      switch (type) {
        case "jpg":
          return "image/jpeg";
        case "jpeg":
          return "image/jpeg";
        case "png":
          return "image/png";
        case "txt":
          return "text/plain";
        case "pdf":
          return "application/pdf";
        default:
          return type;
      }
    },
    async getFileBlob(atta) {
      const payload = Object.assign({}, this.basePayload);
      payload["mo_seq_nr"] = atta.sequence;
      payload["route"] = "attachment_mo_download";
      payload["downloadUrl"] = atta.downloadUrl;
      const jdeFile = await fieldsAPI.downloadFile(payload);
      const fileType = atta.itemName.split(".").at(-1);
      const fileBlob = binaryStringToBlob(
        jdeFile,
        this.getContentType(fileType)
      );
      return fileBlob;
    },
    async downloadFile(atta) {
      try {
        const fileBlob = await this.getFileBlob(atta);
        const url = URL.createObjectURL(fileBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = atta.itemName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        this.$logError(error);
      }
    },
    async fetchFileInformation(file, index) {
      this.$set(this.files, index, { ...file, isLoading: true });
      try {
        switch (file.moType) {
          case 0:
            this.downloadTXTFile(file);
            break;
          case 1:
            await this.downloadFile(file);
            break;
          case 5:
            this.showURLFile(file);
            break;
          default:
            console.warn("Unhandled file type!");
            break;
        }
      } catch (error) {
        this.$logError(error);
      }
      this.$set(this.files, index, { ...file, isLoading: false });
    },
    showURLFile(file) {
      window.open(file.link, "_blank");
    },
    downloadTXTFile(file) {
      const fileName = `${file.itemName}.txt`;
      const divElement = document.createElement("div");
      divElement.innerHTML = file.data;
      const myFile = new Blob(
        [divElement.textContent || divElement.innerText],
        { type: "text/plain" }
      );
      const dlBtn = document.createElement("a");
      document.body.appendChild(dlBtn);
      dlBtn.setAttribute("href", window.URL.createObjectURL(myFile));
      dlBtn.setAttribute("download", fileName);
      dlBtn.click();
      document.body.removeChild(dlBtn);
    },
    async viewFile(file, index) {
      this.$set(this.files, index, { ...file, isLoading: true });

      let fileContent;
      switch (file.moType) {
        case 0:
          fileContent = file.data;
          break;
        case 1:
          fileContent = await this.getFileBlob(file);
          fileContent = URL.createObjectURL(fileContent);
          break;
      }
      Office.context.ui.displayDialogAsync(
        `${location.origin}/file-viewer?isViewer=true`,
        { height: 80, width: 50, promptBeforeOpen: false },
        (result) => {
          if (result.status === "failed") {
            console.error(`Failed to open dialog: ${result.error.message}`);
            this.$set(this.files, index, { ...file, isLoading: false });
          } else {
            const fileDetails = { ...file, fileContent };
            result.value.addEventHandler(
              Office.EventType.DialogMessageReceived,
              () => {
                result.value.messageChild(
                  JSON.stringify({
                    fileDetails,
                    basePayload: this.basePayload,
                  })
                );
              }
            );

            setTimeout(() => {}, 5000);
            this.$set(this.files, index, { ...file, isLoading: false });
          }
        }
      );
    },
    showDialog(moKey) {
      this.basePayload = {
        route: "attachment_list_mo",
        org_id: this.currentUser.company_id,
        connector_name: this.selectedConnector.connector_name,
        user_email: this.currentUser.email,
        channel: "OUTLOOK APP",
        mo_object: this.fileMeta.associatedMORaw,
        mo_key: [moKey],
      };
      this.viewFilesDialog = true;
      this.uploadFileMeta = {
        mo_key: `${this.fileMeta.associatedMORaw}_${moKey}`,
      };
      this.fetchFiles();
    },
    async fetchFiles() {
      this.isLoading = true;
      try {
        const result = await fieldsAPI.fetchMOObjects(this.basePayload);
        const mainConfig = this.currentFlow?.config?.main_config;
        const allowedFileTypes = this.forContact
          ? "file"
          : mainConfig.additional_data_type?.toLowerCase();
        this.files = result.filter((file) => {
          if (allowedFileTypes === "both") return true;
          if (allowedFileTypes === "file" && file.moType !== 0) return true;
          if (allowedFileTypes === "text" && file.moType === 0) return true;
          return false;
        });
      } catch (error) {
        this.$logError(error);
      }
      this.isLoading = false;
    },
    closeDialog() {
      this.viewFilesDialog = false;
    },
  },
};
</script>
