export default {
  state: () => ({
    botUsers: [],
    currentUser: null,
  }),
  mutations: {
    setBotUsers(state, payload) {
      state.botUsers = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
  },
  actions: {
    async fetchBotUsers({ commit, rootState }, payload) {
      const response = await this._vm.$api.users.fetchBotUsers(payload);
      let currentOutlookUser = Office.context.mailbox.userProfile.emailAddress;
      // Test purposes only
      /* if (currentOutlookUser === "nikolay@hight.io") {
        currentOutlookUser = "jan.hox@hamon.com";
      } */
      let currentBotUser = response.find(
        (user) => user.email.toLowerCase() === currentOutlookUser.toLowerCase()
      );
      if (rootState.company.demoState) {
        currentBotUser = response.find(
          (user) => user.email === "demo@hight.io"
        );
      }
      commit("setBotUsers", response);
      commit("setCurrentUser", currentBotUser);
      localStorage.setItem("STATIC_JDE_AB", currentBotUser?.ab_number);
      localStorage.setItem("STATIC_JDE_COMPANY", currentBotUser?.jde_company);
      localStorage.setItem("STATIC_JDE_USER_ID", currentBotUser?.jde_user);
      localStorage.setItem("x-locale", currentBotUser?.locale || "en");
    },
  },
  namespaced: true,
};
