<template>
  <div>
    <v-file-input
      v-model="csvFile"
      outlined
      :label="generateLabel"
      :hint="field.hint"
      :rules="isItRequired"
      small-chips
      accept="text/csv"
      prepend-icon=""
      prepend-inner-icon="mdi-paperclip"
      @change="handleCSVUpload"
      dense
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

/**
 * @module components/DynamicForm/Fields/StaticField
 * @desc Static field component of dynamic form
 */
import { mapState, mapActions } from "vuex";
import { convertCSVToJSON } from "@/utils/conversion";
export default {
  name: "CSVFilePicker",
  /**
   * @vue-prop {Object} field
   * @vue-prop {String|null} value
   */
  props: {
    field: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    csvFile: null,
    rowData: [],
  }),
  /**
   * @vue-computed {Array|undefined} isItRequired
   * @vue-computed {String} generateLabel
   */
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
  },
  /**
   * @vue-event {Object} text-change
   */
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    async handleCSVUpload() {
      if (!this.csvFile) return;
      try {
        const convertedCSV = await convertCSVToJSON(
          this.csvFile,
          this.field.linked_attributes.generated_headers
        );
        this.rowData = convertedCSV.formattedSpreadsheet.flat();
        this.handleCellEdit();
      } catch (error) {
        console.error(error);
        this.showSnackbar({
          type: "error",
          message: "CSV file is corrupted, conversion failed!",
        });
      }
    },
    /**
     * Caled on form change
     * Emits text-change event with passed in value as data
     * @param {String} value
     */
    handleCellEdit() {
      const inputTypes = {};
      const mappedStructure = {};
      Object.keys(this.field.linked_attributes.structure).forEach((key) => {
        const input = this.field.linked_attributes.structure[key];
        inputTypes[input.name] = input.type;
        mappedStructure[key] = input.name;
      });
      const mappedPayload = this.rowData.map((row) => {
        const keys = Object.keys(mappedStructure);
        const mappedRow = {};
        keys.forEach((key) => {
          let value;
          const inputType = inputTypes[mappedStructure[key]];
          if (inputType === "String") {
            value = `${row[key]}`;
          }
          if (inputType === "Numeric") {
            value = parseInt(row[key]);
          }
          mappedRow[mappedStructure[key]] = value;
        });
        return mappedRow;
      });
      this.$emit("text-change", { [this.field.key]: mappedPayload });
    },
  },
};
</script>
