<template>
  <div class="container">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
export default {
  name: "TextViewer",
  props: {
    fileDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    htmlContent() {
      return this.fileDetails.fileContent || this.fileDetails.data;
    },
  },
};
</script>

<style style="scss" scoped>
.container {
  padding: 1rem;
}
</style>