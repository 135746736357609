<template>
  <div>
    <v-expansion-panels
      class="mb-5"
      :key="arrayElement.elements.length"
      multiple
      v-model="expandedPanels"
    >
      <div class="w__full" :key="index" v-for="(line, index) in lines">
        <line-item
          @update-payload="handleUpdatePayload($event)"
          :fields="modifiedElements"
          :object-name="getArrayElementLabel"
          :line-index="index"
          :template-line="templateLines[index]"
          @delete-line="deleteLine"
        />
      </div>
    </v-expansion-panels>
    <div class="flex justify__center mb-5">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            @click="addLine"
            v-bind="attrs"
            v-on="on"
            fab
            small
            :disabled="isLineAddDisabled"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add item in {{ getArrayElementLabel }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import LineItem from "./LinteItem";
export default {
  components: { LineItem },
  name: "Lines",
  props: {
    arrayElement: {
      type: Object,
      required: true,
    },
    usingDataFromTemplate: {
      type: Boolean,
      default: false,
    },
    templateLines: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    payload: {},
    expandedPanels: [],
    lines: [{}],
    limitation: 0,
  }),
  computed: {
    modifiedElements() {
      return this.arrayElement.elements.map((el) => {
        const clonedEl = JSON.parse(JSON.stringify(el));
        clonedEl.key = el.key.split("]")[1];
        return clonedEl;
      });
    },
    getArrayElementLabel() {
      return this.arrayElement.config.label || "Array element";
    },
    isLineAddDisabled() {
      if (!this.limitation) return false;
      return this.limitation <= this.lines.length;
    },
  },
  created() {
    this.expandedPanels = this.lines.map((item, index) => index);
    this.payload = { lines: [{}] };
    if (this.usingDataFromTemplate) {
      this.lines = this.templateLines.map(() => ({}));
      this.payload = { lines: [...this.templateLines] };
    }
    try {
      this.limitation = parseInt(this.arrayElement.config.maxLines);
    } catch (error) {
      this.limitation = 0;
    }
  },
  methods: {
    handleUpdatePayload({ index, object }) {
      Object.assign(this.payload.lines[index], object);
      this.$emit("update-payload", {
        [this.arrayElement.config.key]: this.payload.lines,
      });
    },
    generateOpenAllValue() {
      if (this.lines.length) {
        return this.lines.map((item, index) => index);
      }
      return [];
    },
    addLine() {
      if (this.limitation && this.limitation === this.lines.length) return;
      this.lines.push({});
      this.expandedPanels = this.lines.map((item, index) => index);
      this.payload.lines.push({});
    },
    deleteLine(index) {
      this.lines.splice(index, 1);
      this.payload.lines.splice(index, 1);
      this.$emit("update-payload", {
        [this.arrayElement.config.key]: this.payload.lines,
      });
    },
  },
};
</script>
