<template>
  <div>
    <v-autocomplete
      outlined
      :items="items"
      :label="generateLabel"
      :rules="isItRequired"
      :readonly="isLoading"
      item-text="filter"
      @change="handleChange"
      :value="defaultValue"
      dense
      :disabled="isDisabled"
      :append-icon="showRefresh ? 'mdi-refresh' : 'mdi-menu-down'"
      @click:append="fetchItems"
      :hint="field.hint"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          size="24"
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.label }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.filter }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:selection="data">
        <p class="mb-0">{{ data.item.label }}</p>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import fieldsAPI from "@/api/fields";
import BaseField from "@/mixins/BaseField";

export default {
  name: "UDCField",
  mixins: [BaseField],
  data: () => ({
    isLoading: true,
    items: [],
    unsubscribe: null,
    showRefresh: false,
  }),
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      companySettings: (state) => state.company.settings,
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.requiredAllowEmpty]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.fetchItems();
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
    }),
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    async fetchItems() {
      this.isLoading = true;
      const UDCObject = this.field.linked_attributes;
      try {
        const payload = {
          udc_key: UDCObject.udc_key,
          udc_code: UDCObject.udc_code,
          ...this.companySettings,
          ...this.currentFlow,
        };
        const config = {
          valueColumn: UDCObject.value_column || "F0005_KY",
          displayColumn: UDCObject.details_column || "F0005_DL01",
          filterColumns: UDCObject.filter_columns ||
            UDCObject.filters_columns || [
              "F0005_DL01",
              "F0005_DL02",
              "F0005_KY",
              "F0005_SPHD",
            ],
        };
        this.items = await fieldsAPI.fetchDataFromUDC(payload, config);
      } catch (error) {
        let message = error.message;
        if (error.response?.data?.result) {
          message = error.response.data.result;
        }
        this.$logError(error);
        this.showSnackbar({
          type: "error",
          message: `Field: ${this.field.published_name}, Error: ${message}`,
        });
        this.showRefresh = true;
      }
      this.isLoading = false;
    },
    handleChange(value) {
      this.$emit("selected", { [this.field.key]: value });
    },
  },
};
</script>
