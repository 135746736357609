<template>
  <v-app>
    <v-container>
      <v-card outlined>
        <v-card-title>
          <h2 class="page-title text-center mb__double">CREATE SALES ORDER</h2>
        </v-card-title>
        <v-card-text>
          <v-form ref="salesOrder">
            <div class="mb__default">
              <h3 class="mb__default">Header fields</h3>
              <v-text-field
                v-model="salesOrder.sold_to_name"
                label="Sold to Name"
                outlined
                :rules="[rules.required]"
                dense
              />
              <v-text-field
                v-model="salesOrder.sold_to_number"
                label="Sold to Number"
                outlined
                :rules="[rules.required]"
                dense
              />
              <v-combobox
                class="flex__1"
                label="Business unit"
                item-text="description"
                item-value="business_unit"
                :items="businessUnits"
                outlined
                dense
                :return-object="false"
                v-model="salesOrder.business_unit"
                :rules="[rules.required]"
              />
            </div>
            <div>
              <h3 class="mb__default">Detail fields</h3>
              <v-text-field
                v-for="field in detailFields"
                :key="field.key"
                outlined
                dense
                :rules="[rules.required]"
                :label="field.label"
                v-model="salesOrder[field.key]"
              />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify__center">
          <v-btn class="mb__default" @click="createSOOrder" color="primary">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import businessUnitsArr from "../config/businessUnits.json";
export default {
  data: () => ({
    newSoNumber: "",
    stage: "",
    totalPrice: "",
    businessUnits: [],
    salesOrder: {},
    detailFields: [
      { label: "Item Number", key: "item_number" },
      { label: "Description", key: "description" },
      { label: "Quantity", key: "quantity" },
      { label: "Unit Price", key: "unit_price" },
    ],
  }),
  computed: {
    rules() {
      return {
        required: (v) => !!v || "This field is required!",
      };
    },
  },
  created() {
    this.businessUnits = businessUnitsArr.map((bu) => ({
      description: bu["Description"],
      business_unit: bu["Business Unit"],
    }));
  },
  methods: {
    createSOOrder() {
      if(!this.$refs.salesOrder.validate()) return
      console.log(this.salesOrder);
    },
    async oldWay() {
      // Get a reference to the current message
      var itemNew = Office.context.mailbox.item;

      let responseOrch = {};
      const configOrch = {
        headers: { Authorization: "Basic SkRFOkpERQ==" },
      };

      let orchPayload = [];
      orchPayload.push(
        {
          name: "lastSONumber",
          value: "2849",
        },
        {
          name: "soldTo",
          value: "6655110",
        },
        {
          name: "P4210_Version",
          value: "ZJDE0001",
        },
        {
          name: "Select_Row",
          value: "1",
        }
      );

      this.stage = "ORCHESTRATION #1";
      responseOrch = await axios.post(
        "https://hight-ais.ngrok.io/jderest/v3/orchestrator/HIGHT_DuplicateSO",
        {
          inputs: orchPayload,
        },
        configOrch
      );
      let soDuplicate = responseOrch.data;

      responseOrch = {};
      orchPayload = [];
      orchPayload.push({
        name: "abSoldTo",
        value: "6655110",
      });
      orchPayload.push({
        name: "company",
        value: "00200",
      });

      this.stage = "ORCHESTRATION #2";
      responseOrch = await axios.post(
        "https://hight-ais.ngrok.io/jderest/v3/orchestrator/HIGHT_GetSOLatest",
        {
          inputs: orchPayload,
        },
        configOrch
      );
      let soCreated = responseOrch.data.HIGHT_GetSOLatest.rowset[0];

      this.newSoNumber =
        `Nr: ${soCreated["Order Number"]}` +
        " Type: " +
        soDuplicate["Order Type"] +
        " Co: " +
        soDuplicate["Order Company"];
      let newSoNumber_Ref = soCreated["Order Number"];
      this.totalPrice = "$725,000.00";

      this.stage = "EWS ATTACHMENTS";
      /*try {
      var attachment = Office.context.mailbox.item.getAttachmentContentAsync(
        itemNew.attachments[0].id, [],
        handleAttachmentsCallback
      );
    }
    catch (e) {
      this.stage = 'ERROR' + e;
      await sleep(20000)
    }*/

      let ewsData = {};
      try {
        Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function(
          result
        ) {
          var ewsId = Office.context.mailbox.item.itemId;
          var token = result.value;
          var restId = Office.context.mailbox.convertToRestId(
            ewsId,
            Office.MailboxEnums.RestVersion.v2_0
          );
          var getAttachmentsUrl =
            Office.context.mailbox.restUrl +
            "/v2.0/me/messages/" +
            restId +
            "/attachments";

          var headers = { Authorization: "Bearer " + token };
          var options = { headers: headers };
          ewsData.ewsId = ewsId;
          ewsData.url = getAttachmentsUrl;
          ewsData.token = token;
          ewsData.restId = restId;

          axios
            .get(getAttachmentsUrl, options)
            .then(function(response) {
              //ewsData.response = response.data;

              for (var k = 0; k < response.data.value.length; k++) {
                if (
                  response.data.value[k]["@odata.type"] ===
                    "#Microsoft.OutlookServices.FileAttachment" &&
                  response.data.value[k].ContentType ===
                    "application/octet-stream"
                ) {
                  let postDataAttachment = {
                    attachment_content: response.data.value[k].ContentBytes,
                    action: "attachment_parse",
                    so_number: newSoNumber_Ref,
                    file_name: response.data.value[k].Name,
                  };

                  axios
                    .post(
                      "https://s3skv5gm1l.execute-api.us-east-1.amazonaws.com/production/api/outlookbot",
                      postDataAttachment
                    )
                    .then(function(response) {
                      ewsData.status = "OK";
                      return ewsData;
                    })
                    .catch(function(error) {
                      throw "Post Attachments API ERROR.." + error;
                    });
                }
              }
            })
            .catch(function(error) {
              throw "Get Attachments EWS Call ERROR.." + error;
            });
        });
      } catch (e) {
        this.stage = "ATTACHMENTS ERROR" + e;
        await sleep(15000);
      }
      await sleep(30000);
      this.stage = "EWS ATTACHMENTS DONE" + JSON.stringify(ewsData);

      function callbackAttachment(result) {
        if (result.value.length > 0) {
          for (i = 0; i < result.value.length; i++) {
            result.asyncContext.currentItem.getAttachmentContentAsync(
              result.value[i].id,
              handleAttachmentsCallback
            );
          }
        }
      }

      function handleAttachmentsCallback(result) {
        // Parse string to be a url, an .eml file, a base64-encoded string, or an .icalendar file.
        switch (result.value.format) {
          case Office.MailboxEnums.AttachmentContentFormat.Base64:
            this.stage = "BASE64";
            // Handle file attachment.
            let postDataAtt = {
              attachment_content: result.value,
              action: "attachment_parse",
            };
            axios
              .post(
                "https://s3skv5gm1l.execute-api.us-east-1.amazonaws.com/production/api/outlookbot",
                postDataAtt
              )
              .then(function(response) {
                this.stage = "BASE64_OK";
                console.log(response);
              })
              .catch(function(error) {
                this.stage = "BASE64_ERROR";
                console.log(error);
              });
            break;
          case Office.MailboxEnums.AttachmentContentFormat.Eml:
            this.stage = "ELM";
            // Handle email item attachment.
            break;
          case Office.MailboxEnums.AttachmentContentFormat.ICalendar:
            // Handle .icalender attachment.
            break;
          case Office.MailboxEnums.AttachmentContentFormat.Url:
            this.stage = "URL";
            // Handle cloud attachment.
            break;
          default:
            // Handle attachment formats that are not supported.
            this.stage = "NOCONTENT";
        }
      }

      function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
    },
  },
};
</script>
