<template>
  <v-app class="page-container">
    <v-alert outlined class="mb-4" v-if="demoState" dense type="info">
      <h3>You are using a demo account</h3>
      <p>
        This environment is used to showcase our product and it contains
        pre-populated data!
      </p>
    </v-alert>
    <v-card outlined class="mb-3" v-if="outlookState === 'Read'">
      <v-card-title>
        <div class="flex align__center">
          <v-icon class="mr-2">mdi-email-outline</v-icon>
          <h4 class="email-subject font__inter">{{ subject }}</h4>
        </div>
      </v-card-title>
      <v-card-text class="font__inter">
        <div class="email-info">
          <p>Name: {{ name }}</p>
          <p>From: {{ from }}</p>
        </div>
      </v-card-text>
    </v-card>
    <v-select
      v-if="outlookState === 'Read'"
      label="Available contacts"
      :value="contact.alpha_name + ' - ' + contact.ab_number"
      :loading="isPullingERPReference"
      :items="contacts"
      return-object
      :item-text="(contact) => contact.alpha_name + ' - ' + contact.ab_number"
      outlined
      dense
      @change="handleContactChange"
    />
    <v-expansion-panels
      outlined
      class="mb-5"
      v-if="!demoState && outlookState === 'Read'"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="flex align__center">
            <v-checkbox
              @click="handleCheckboxClick"
              @change="handleCheckboxChange"
              class="mt__0 pt__0"
              hide-details
              v-model="selectAllAttachments"
            />
            <p class="font__inter mb__0">ATTACHMENTS</p>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="(att, i) in items"
            :key="att.title + i"
            class="attachment-row"
          >
            <v-checkbox v-model="selectedAttachments" :value="att" />
            <div class="flex__1 filename">
              <p class="font__inter">{{ att.title }}</p>
              <p class="size">{{ parseFilesize(att.size) }}</p>
            </div>
            <v-icon class="icon">{{ appendIcon(att.type) }}</v-icon>
          </div>

          <div class="flex justify__center">
            <v-btn
              :disabled="!selectedAttachments.length || !contact?.ab_number"
              color="primary"
              :loading="isAttachingFileToContact"
              @click="handleAttachToContact"
            >
              Attach to contact
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-list dense class="mb-5" v-if="currentDomain === 'hamon.com'">
      <v-list-group class="font__inter">
        <template v-slot:activator>
          <v-list-item-icon><hamon-svg id="hamon-svg" /></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>HAMON</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          @click="handleMenuItemClick(menuItem)"
          v-for="menuItem in filteredMenuItems"
          :key="menuItem.name"
        >
          <v-list-item-content>
            <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <div class="button-container">
      <v-btn
        large
        color="primary"
        :to="link.to"
        v-for="link in filteredLinks"
        :key="link.label"
        :disabled="
          currentDomain === 'hamon.com' ||
          (link.to === '/attachEmail' && !contact?.ab_number)
        "
      >
        <div class="flex align__center">
          <p class="flex__1 mb__0 text-center">{{ link.label }}</p>
          <v-icon right>{{ link.icon }}</v-icon>
        </div>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import HamonSVG from "@/assets/svg/hamon.svg";
export default {
  components: { "hamon-svg": HamonSVG },
  data: () => ({
    subject: "",
    from: "",
    name: "",
    full: "",
    items: [],
    currentDomain: "",
    isAttachingFileToContact: false,
    links: [
      {
        label: "APP MENUS",
        to: "/app-menus",
        icon: "mdi-apps",
      },
      {
        label: "ATTACH FULL EMAIL TO JDE",
        to: "/attachEmail",
        icon: "mdi-note-plus-outline",
      },
      /*       {
        label: "AI-BASED AUTO SUGGESTIONS",
        to: "/auto-suggest",
        icon: "mdi-robot",
      }, */
      {
        label: "LOOK UP CONTACT",
        to: "/findCustomer",
        icon: "mdi-account-search-outline",
      },
      {
        label: "CREATE A NEW CONTACT",
        to: "/add-contact",
        icon: "mdi-account-plus-outline",
      },

      // { label: "ATTACH FILES TO JDE", to: "/", icon: "mdi-attachment" },
      /* {
        label: "ARCHIVE EMAIL",
        to: "/attachEmail",
        icon: "mdi-archive-arrow-up-outline",
      }, */
    ],
    currentUser: {},
    selectMO: false,
    selectAllAttachments: false,
    selectedAttachments: [],
    isPullingERPReference: true,
  }),
  computed: {
    ...mapState({
      menuItems: (state) => state.company.menuItems,
      demoState: (state) => state.company.demoState,
      contacts: (state) => state.contact.contacts,
      contact: (state) => state.contact.contact,
      outlookState: (state) => state.common.outlookState,
      selectedConnector: (state) => state.connectors.selectedConnector,
      companySettings: (state) => state.company.settings,
    }),
    filteredMenuItems() {
      return this.menuItems.filter((menuItem) => {
        if (menuItem.name.includes("HR") || menuItem.name.includes("TEST")) {
          return false;
        }
        return true;
      });
    },
    filteredLinks() {
      if (this.$store.state.company.settings.company_id === "Hight.io-Demo") {
        return this.links.filter((item) => {
          return item.label === "APP MENUS";
        });
      }
      if (this.outlookState === "Compose") {
        return this.links.filter(
          (link) =>
            link.to.includes("app-menus") || link.to.includes("add-contact")
        );
      }
      return this.links;
    },
  },
  methods: {
    ...mapMutations({
      setCurrentMenuItem: "company/setCurrentMenuItem",
      setContact: "contact/setContact",
    }),
    ...mapActions({
      searchContactByEmail: "contact/searchContactByEmail",
      showSnackbar: "common/showSnackbar",
    }),
    convertToBlob(encoded, type) {
      const binary = atob(encoded);
      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type });
      return blob;
    },
    async handleAttachToContact() {
      this.isAttachingFileToContact = true;
      for (const att of this.selectedAttachments) {
        try {
          const content = await new Promise((resolve, reject) => {
            Office.context.mailbox.item.getAttachmentContentAsync(
              att.id,
              {},
              (result) => {
                console.log(result);
                if (result.status === "succeeded") {
                  resolve(result.value);
                } else {
                  reject(result.error);
                }
              }
            );
          });

          const blob = this.convertToBlob(content.content, att.type);
          const file = new File([blob], att.title, { type: att.type });

          const uploadPayload = {
            key: file.name,
            env: this.selectedConnector.environment,
            company_teams_tid: this.companySettings.tenant_id_teams,
            po_key: `ABGT_${this.contact.ab_number}`,
            flow_name: "Attach to contact",
            file,
          };
          await this.$api.config.uploadFile(uploadPayload, this.handleUpload);
        } catch (error) {
          this.$logError(error);
          continue;
        }
      }
      this.isAttachingFileToContact = false;
    },
    handleUpload(filename, error) {
      if (error) {
        this.showSnackbar({
          type: "error",
          message: `Upload for file: ${filename} failed!`,
        });
        return;
      }
      this.showSnackbar({
        type: "success",
        message: `Upload for file: ${filename} was successful!`,
      });
    },
    handleContactChange(e) {
      this.setContact(e);
    },
    async fetchContactInformation() {
      try {
        this.isPullingERPReference = true;
        await this.searchContactByEmail(
          Office.context.mailbox.item.from.emailAddress
        );
      } catch (error) {
        this.$logError(error);
      } finally {
        this.isPullingERPReference = false;
      }
    },
    handleCheckboxClick(e) {
      e.stopPropagation();
    },
    handleCheckboxChange(value) {
      this.selectedAttachments = value ? [...this.items] : [];
    },
    appendIcon(mimeType) {
      switch (mimeType.split("/")[0]) {
        case "image":
          return "mdi-image";
        case "video":
          return "mdi-video";
        default:
          return "mdi-file";
      }
    },
    parseFilesize(filesize) {
      const sizeInKB = parseFloat(filesize / 1024).toFixed(2);
      if (sizeInKB < 1024) return `${sizeInKB}KB`;
      const sizeInMB = parseFloat(filesize / 1024 / 1024).toFixed(2);
      return `${sizeInMB}MB`;
    },
    handleMenuItemClick(menuItem) {
      this.setCurrentMenuItem(menuItem);
      this.$router.push("/flows");
    },
  },
  mounted() {
    const subject = Office.context.mailbox.item.subject;
    this.subject = subject;
    const from = Office.context.mailbox.item.from;
    this.from = from.emailAddress;
    this.name = from.displayName;
    const att = Office.context.mailbox.item.attachments;
    this.currentUser = Office.context.mailbox.userProfile;
    this.items = att?.map(({ name, contentType, isInline, size, id }) => ({
      icon: true,
      title: name,
      type: contentType,
      inline: isInline,
      size,
      id,
    }));
    this.currentDomain =
      Office.context.mailbox.userProfile.emailAddress.split("@")[1];
    this.fetchContactInformation();
  },
};
</script>

<style>
.attachment-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.attachment-row .icon {
  font-size: 26px;
  margin-left: 10px;
  color: #5bb3d0;
}
.filename > p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}
.attachment-row .size {
  font-size: 14px;
  font-style: italic;
  color: #ddd;
  margin-bottom: 0;
}
.email-info p {
  margin-bottom: 5px;
}
.button-container {
  display: flex;
  flex-direction: column;
}
.button-container .v-btn {
  flex: 1 0 auto;
}
.button-container .v-btn + .v-btn {
  margin-top: 10px;
}
.email-subject {
  line-height: 1.2;
}
.center {
  display: grid;
  place-items: center;
}
.successBox {
  border: 3px solid #4caf50 !important;
}

.errorBox {
  border: 3px solid #f44336 !important;
}

.descriptionL {
  font-family: "InterL";
  font-size: 18px;
}

.descriptionXS {
  font-family: "InterL";
  font-size: 12px;
}
</style>
