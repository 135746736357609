<template>
  <div>
    <v-sheet rounded elevation="2" class="mb-5 pa-1 brand-header">
      <div class="flex align__center">
        <v-icon @click="goBack" color="accent" class="mr-3" large>
          mdi-chevron-left
        </v-icon>
        <div>
          <h3 class="text-h6 font-weight-bold">{{ currentFlow.name }}</h3>
          <p class="mb-0 text-body-1">{{ currentFlow.description }}</p>
        </div>
        <v-menu
          v-if="hasTemplateOptionEnabled"
          v-model="dotMenu"
          left
          offset-x
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              depressed
              color="transparent"
              transparent
              fab
              small
              v-on="on"
            >
              <v-icon class="white--text">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="availableTemplates.length">
              <v-list-item-content>
                <v-list-item-title class="mb-3">
                  Select a template
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-autocomplete
                    label="Saved templates"
                    outlined
                    :items="availableTemplates"
                    item-text="name"
                    item-value="name"
                    hide-details
                    dense
                    :readonly="isFetchingTemplate"
                    @change="handleTemplateSelection"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                <v-checkbox
                  v-model="saveAsTemplate"
                  :value="true"
                  :label="
                    usingDataFromTemplate
                      ? 'Update template'
                      : 'Save as template'
                  "
                  hide-details
                  class="mt-0"
                />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-sheet>
    <div v-if="isFetchingTemplate">
      <div class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          size="35"
          color="blue darken-2"
          class="mb-4"
        />
      </div>
      <p class="text-h6 text-center">Fetching template data...</p>
    </div>
    <div v-if="!isFetchingTemplate">
      <v-form ref="dynamicForm">
        <div v-if="widgetFields" class="widgets-container mb-6">
          <div
            v-for="(field, index) in widgetFields"
            v-show="isFieldVisible(field)"
            :key="index"
          >
            <component
              :is="decideWhichComponent(field)"
              class="widget"
              :field="field"
            />
          </div>
        </div>
        <tabs
          v-if="tabFields.length"
          :tabs="tabFields"
          class="mb-6"
          @update-payload="handleTabUpdate"
          @delete-payload-entry="handleTabDeleteEntry"
        />
        <div
          v-for="(field, index) in filteredFields"
          :key="field.key + index || index"
          v-show="isFieldVisible(field)"
        >
          <component
            @selected="handleFieldSelection"
            @text-change="handleFieldSelection"
            @phone-change="handleFieldSelection"
            @delete-payload-field="handlePayloadFieldDeletion"
            @manage-field-visibility="manageFieldVisibility"
            :field="field"
            :is="decideWhichComponent(field)"
          />
        </div>
        <div v-for="(item, key) in extractArrayElements" :key="key">
          <lines
            @update-payload="handleLinesUpdate"
            :array-element="item"
            :using-data-from-template="usingDataFromTemplate"
            :template-lines="templateLines"
          />
        </div>
      </v-form>
    </div>
    <div class="flex flex__half-gap px-4 justify__between">
      <v-btn
        class="page-btn--primary"
        @click="submit"
        :loading="isLoading"
        :disabled="disableExecution"
        v-if="!isFetchingTemplate"
      >
        submit
      </v-btn>
      <v-btn class="page-btn--error" @click="goBack"> cancel </v-btn>
    </div>
    <v-dialog v-model="templateNameDialog" max-width="400" persistent>
      <v-sheet rounded>
        <v-sheet elevation="2" class="mb-5 pa-2 page-title">
          <p class="text-h4 mb-0">Template config</p>
        </v-sheet>
        <div class="pa-4 pt-0">
          <v-text-field
            v-if="saveAsTemplate"
            ref="templateName"
            v-model="templateName"
            style="min-width: 300px"
            outlined
            :disabled="usingDataFromTemplate"
            class="mt-4"
            :rules="[validation.required]"
            label="Template name"
          />
          <div class="d-flex justify-center">
            <v-btn
              class="page-btn--primary mr-4"
              :loading="isSubmittingTemplate"
              @click="saveTemplate"
            >
              submit
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState, mapActions, mapMutations } from "vuex";
import fieldsAPI from "@/api/fields";
import sfAPI from "@/api/salesforce";
import LOVField from "@/components/Fields/LOVField.vue";
import UDCField from "@/components/Fields/UDCField.vue";
import SearchFormField from "@/components/Fields/SearchFormField.vue";
import XREFField from "@/components/Fields/XREFField.vue";
import TableField from "@/components/Fields/TableField.vue";
import StaticField from "@/components/Fields/StaticField.vue";
import ORCHField from "@/components/Fields/ORCHField.vue";
import TextField from "@/components/Fields/TextField.vue";
import RuleField from "@/components/Fields/RuleField.vue";
import DateField from "@/components/Fields/DateField.vue";
import PasswordField from "@/components/Fields/PasswordField.vue";
import LinklistField from "@/components/Fields/LinklistField.vue";
import HTML5String from "@/components/Fields/HTML5/String.vue";
import HTML5Email from "@/components/Fields/HTML5/Email.vue";
import HTML5Phone from "@/components/Fields/HTML5/Phone.vue";
import HTML5Numeric from "@/components/Fields/HTML5/Numeric.vue";
import HTML5Slider from "@/components/Fields/HTML5/Slider.vue";
import HTML5Checkbox from "@/components/Fields/HTML5/Checkbox.vue";
import HTML5Money from "@/components/Fields/HTML5/Money.vue";
import HTML5Image from "@/components/Fields/HTML5/Image.vue";
import HTML5Title from "@/components/Fields/HTML5/Title.vue";
import HTML5Description from "@/components/Fields/HTML5/Description.vue";
import AddressField from "@/components/Fields/AddressField.vue";
import RatingField from "@/components/Fields/RatingField.vue";
import Lines from "@/components/Lines/Lines";
import CSVFilePickerField from "@/components/Fields/CSVFilePickerField.vue";
import WidgetField from "@/components/Fields/WidgetField.vue";
import BarcodeField from "@/components/Fields/BarcodeField.vue";
import PicklistField from "@/components/Fields/SF/PicklistField.vue";
import ReferenceField from "@/components/Fields/SF/ReferenceField.vue";
import Tabs from "@/components/Tabs.vue";
export default {
  name: "DynamicForm",
  props: {
    fields: {
      type: Object,
      required: true,
    },
    disableExecution: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "udc-field": UDCField,
    "lov-field": LOVField,
    "searchform-field": SearchFormField,
    "xref-field": XREFField,
    "table-field": TableField,
    "static-field": StaticField,
    "orch-field": ORCHField,
    "text-field": TextField,
    "rule-field": RuleField,
    "date-field": DateField,
    "password-field": PasswordField,
    "linklist-field": LinklistField,
    "string-field": HTML5String,
    "email-field": HTML5Email,
    "phone-field": HTML5Phone,
    "numeric-field": HTML5Numeric,
    "slider-field": HTML5Slider,
    "checkbox-field": HTML5Checkbox,
    "money-field": HTML5Money,
    "csv-file-picker-field": CSVFilePickerField,
    ImageField: HTML5Image,
    TitleField: HTML5Title,
    DescriptionField: HTML5Description,
    AddressField: AddressField,
    RatingField: RatingField,
    lines: Lines,
    WidgetField,
    BarcodeField,
    PicklistField,
    ReferenceField,
    Tabs,
  },
  data: () => ({
    payload: {},
    groupedFields: {},
    isLoading: false,
    token: null,
    visibleFields: [],
    dotMenu: false,
    availableTemplates: [],
    isFetchingTemplate: false,
    usingDataFromTemplate: false,
    templateLines: [],
    saveAsTemplate: false,
    templateName: "",
    templateNameDialog: false,
    isSubmittingTemplate: false,
  }),
  created() {
    this.filteredFields;
  },
  mounted() {
    if (this.currentFlow.config.main_config.auth_on_exec) {
      this.token = localStorage.getItem("JDEToken");
    }
    this.currentFlow.config.payload_config.root_elements.forEach((field) => {
      if (field.visibility === "hide") return;
      this.visibleFields.push(field.key);
    });
    this.filteredFields.forEach((field) => {
      if (field.default) {
        this.payload[field.key] = field.default;
      }
    });
    if (this.currentFlow.config.main_config.immediate_submittion) {
      this.submit();
    }
  },
  watch: {
    templatesPerBotflow() {
      this.availableTemplates = this.templatesPerBotflow.filter(
        (template) => template.user_id === this.currentUser.bot_user_id
      );
    },
  },
  computed: {
    ...mapState({
      companySettings: (state) => state.company.settings,
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      fileMeta: (state) => state.company.fileMeta,
      templatesPerBotflow: (state) => state.templates.templatesPerBotflow,
      selectedConnector: (state) => state.connectors.selectedConnector,
    }),
    filteredFields() {
      return this.currentFlow.config.payload_config.root_elements.filter(
        (field) => field.attribute_type !== "WIDGET"
      );
    },
    extractArrayElements() {
      return this.currentFlow.config.payload_config.array_elements;
    },
    widgetFields() {
      return this.currentFlow.config.payload_config.root_elements.filter(
        (field) => field.attribute_type === "WIDGET"
      );
    },
    tabFields() {
      return this.currentFlow.config.payload_config?.tab_elements || [];
    },
    hasTemplateOptionEnabled() {
      return this.currentFlow.config.main_config.has_template_option || false;
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "common/showSnackbar",
      createTemplate: "templates/createTemplate",
    }),
    ...mapMutations({
      setFlowData: "company/setFlowData",
      updateCurrentFlow: "company/updateCurrentFlow",
      setFileMeta: "company/setFileMeta",
    }),
    async handleTemplateSelection(templateName) {
      try {
        this.isFetchingTemplate = true;
        this.dotMenu = false;
        const templateData = this.availableTemplates.find(
          (temp) => temp.name === templateName
        );
        this.templateName = templateName;
        this.payload = JSON.parse(JSON.stringify(templateData.payload));
        const payloadKeys = Object.keys(templateData.payload);
        const clonedFlow = JSON.parse(JSON.stringify(this.currentFlow));
        const updateRootFields = (array) => {
          return new Promise((resolve) => {
            if (!array.length) resolve();
            array.forEach((field, index) => {
              if (payloadKeys.includes(field.key)) {
                field.default = this.payload[field.key];
              }
              if (array.length === index + 1) {
                resolve();
              }
            });
          });
        };
        this.templateLines =
          Object.values(templateData.payload).find((item) =>
            Array.isArray(item)
          ) || [];
        await updateRootFields(clonedFlow.config.payload_config.root_elements);
        this.updateCurrentFlow(clonedFlow);
        this.usingDataFromTemplate = true;
      } catch (error) {
        this.$logError(error);
      } finally {
        this.isFetchingTemplate = false;
      }
    },
    async saveTemplate() {
      try {
        if (this.saveAsTemplate && !this.templateName) {
          this.$refs.templateName.blur();
          this.$refs.templateName.focus();
          return;
        }
        this.isSubmittingTemplate = true;
        await this.createTemplate({
          payload: this.payload,
          name: this.templateName,
        });
        this.templateNameDialog = false;
      } catch (error) {
        this.$logError(error);
      } finally {
        this.isSubmittingTemplate = false;
      }
    },
    async showTemplateNameDialog() {
      if (this.usingDataFromTemplate) {
        await this.saveTemplate();
        return;
      }
      return new Promise((resolve) => {
        this.templateNameDialog = true;
        const interval = setInterval(() => {
          if (!this.templateNameDialog) {
            clearInterval(interval);
            resolve();
          }
        }, 300);
      });
    },
    handleTabDeleteEntry(field) {
      this.handlePayloadFieldDeletion(field);
    },
    handleTabUpdate(value) {
      this.payload = Object.assign({}, this.payload, value);
      this.setFlowData(this.payload);
    },
    handlePayloadFieldDeletion(field) {
      delete this.payload[field];
      this.setFlowData(this.payload);
    },
    isFieldVisible(field) {
      return this.visibleFields.includes(field.key);
    },
    manageFieldVisibility({ visibility, field }) {
      const indexOfField = this.visibleFields.indexOf(field);
      if (visibility && indexOfField === -1) {
        this.visibleFields.push(field);
      }
      if (!visibility && indexOfField !== -1) {
        this.visibleFields.splice(indexOfField, 1);
      }
    },
    handleLinesUpdate(value) {
      Object.assign(this.payload, value);
    },
    generateMOKey(response) {
      let payloadFields = Object.keys(this.payload);
      let payloadValues = this.payload;
      let moKeyConcated = `${this.currentFlow.config.main_config.associated_mo}_`;
      const moKeys = this.currentFlow.config.main_config.mo_key;
      if (moKeys) {
        moKeys.forEach((item) => {
          const key = Object.keys(item).find((key) => key !== "type");
          const moValue = item[key];
          if (item.type === "static") {
            moKeyConcated += `${moValue}|`;
            return;
          }
          if (payloadFields.includes(moValue)) {
            moKeyConcated += `${payloadValues[moValue]}|`;
          } else {
            moKeyConcated += `${response[moValue]}|`;
          }
        });
        return moKeyConcated.slice(0, -1);
      }
    },
    async submit() {
      if (!this.$refs.dynamicForm.validate()) {
        if (this.currentFlow.config.main_config.immediate_submittion) {
          setTimeout(() => {
            this.submit();
          }, 100);
        }
        return;
      }
      this.isLoading = true;
      const config = {
        org_id: this.companySettings.company_id,
        orchestration_name: this.currentFlow.linked_orch_name,
        botflow_name: this.currentFlow.name,
        response_format: this.currentFlow.config.main_config.response_format,
        user_email: this.currentUser.email,
      };
      if (this.token) {
        config.token = this.token;
      }
      const payloadFields = Object.keys(this.payload);
      this.currentFlow.config.payload_config.root_elements.forEach((field) => {
        if (!payloadFields.includes(field.key) && field.default?.length) {
          this.payload[field.key] = field.default;
        }
      });
      try {
        let result = null;
        if (this.currentFlow.type === "SALESFORCE") {
          result = await sfAPI.createRecord(
            { ...config, sfObject: this.currentFlow.linked_orch_name },
            this.payload
          );
        } else {
          result = await fieldsAPI.submit(config, this.payload);
        }
        if (this.saveAsTemplate) {
          await this.showTemplateNameDialog();
        }
        const response = {
          result: result.orchestration_response,
        };
        const mainConfig = this.currentFlow.config.main_config;

        if (mainConfig.additional_data_type) {
          const newMeta = {
            mo_key: this.generateMOKey(result.orchestration_response),
            associatedMORaw: mainConfig.associated_mo,
            moKeyRaw: mainConfig.mo_key,
          };
          response.fileMeta = newMeta;
          this.setFileMeta(newMeta);
        }

        if (
          mainConfig.response_format === "TABLE" ||
          mainConfig.response_format === "ADVANCED_TABLE"
        ) {
          response.tableData = result.orchestration_response;
        }

        this.showSnackbar({
          type: "success",
          message: "Flow executed succesffully!",
        });
        this.$emit("submitted", { response });
      } catch (error) {
        console.log(error);
        let message = error.message;
        if (error.response.data.orch_response_message) {
          message = error.response.data.orch_response_message;
        }
        this.showSnackbar({
          type: "error",
          message,
          timeout: 7000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    decideWhichComponent(field) {
      const lowerCasedField = field.attribute_type?.toLowerCase();
      if (!lowerCasedField || lowerCasedField === "string") {
        return "text-field";
      }
      return `${field.attribute_type.toLowerCase()}-field`;
    },
    handleFieldSelection(value) {
      this.payload = Object.assign(this.payload, value);
      this.setFlowData(this.payload);
    },
    goBack() {
      this.$router.push("/app-menus");
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets-container {
  display: grid;
  gap: 0.5rem;
  height: 100%;
  grid-template-columns: 1fr 1fr;
}
</style>
