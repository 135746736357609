<template>
  <div>
    <v-autocomplete
      outlined
      :items="getItems"
      :label="generateLabel"
      :rules="isItRequired"
      :value="defaultValue"
      :hint="field.hint"
      item-text="title"
      item-value="value"
      return-object
      :disabled="isDisabled"
      @change="handleSelection"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseField from "@/mixins/BaseField";

export default {
  name: "LinklistField",
  mixins: [BaseField],
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    unsubscribe: null,
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    getItems() {
      return this.field.linked_attributes.rows || [];
    },
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.requiredAllowEmpty]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  /**
   * @vue-event {Object} selected
   */
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    /**
     * Called on form change
     * Emits selected event with passed in value as data
     */
    handleSelection(selection) {
      const values = [];
      for (const key in selection) {
        if (key !== "title") {
          values.push(selection[key]);
        }
      }
      this.$emit("selected", { [this.field.key]: values.join("-_-_-") });
    },
  },
};
</script>
