export default {
  state: () => ({
    connectors: [],
    isFetchingConnectors: true,
    selectedConnector: null,
  }),
  mutations: {
    setConnectors(state, connectors) {
      state.connectors = connectors;
    },
    setIsFetchingConnectors(state, value) {
      state.isFetchingConnectors = value;
    },
    setSelectedConnector(state, connector) {
      state.selectedConnector = connector;
    },
  },
  actions: {
    async fetchConnectors({ commit, rootState }) {
      commit("setIsFetchingConnectors", true);
      const connectors = await this._vm.$api.company.getConnectors(
        rootState.users.currentUser
      );
      // This is neeced because Jan does not want to modify his BE functions to use environment
      commit(
        "setConnectors",
        connectors.map((conn) => ({
          ...conn,
          environment: conn.environments[0],
        }))
      );
      commit("setIsFetchingConnectors", false);
    },
  },
  namespaced: true,
};
