<template>
  <div>
    <html5-money
      v-if="ruleType === 'amount'"
      :field="field"
      :custom-rules="[...customRules()]"
      @text-change="validateText"
    />
    <html5-string
      v-if="ruleType === 'text'"
      :field="{ ...field, ...hasLimit }"
      :custom-rules="[...customRules()]"
      @text-change="validateText"
    />
    <html5-numeric
      v-if="ruleType === 'numeric'"
      :field="{ ...field, ...hasLimit }"
      :custom-rules="[...customRules()]"
      @text-change="validateText"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import HTML5Money from "@/components/Fields/HTML5/Money.vue";
import HTML5String from "@/components/Fields/HTML5/String.vue";
import HTML5Numeric from "@/components/Fields/HTML5/Numeric.vue";
export default {
  name: "RuleField",
  components: {
    "html5-money": HTML5Money,
    "html5-string": HTML5String,
    "html5-numeric": HTML5Numeric,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    additionalSettings: {},
    ruleType: "text",
    linkedAttributes: {},
    unsubscribe: null,
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required, ...this.customRules()]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    hasLimit() {
      return this.linkedAttributes.character_limit
        ? {
            character_limit: this.linkedAttributes.character_limit,
          }
        : {};
    },
  },
  created() {
    if (!this.field.linked_attributes) {
      this.linkedAttributes = {};
      return;
    }
    this.linkedAttributes = this.field.linked_attributes;
    this.additionalSettings = this.linkedAttributes.additional_settings;
    this.ruleType = this.linkedAttributes.type;
    this.additionalSettings = this.linkedAttributes.additional_settings;
    if (this.ruleType === "amount") {
      Object.assign(this.linkedAttributes, this.additionalSettings);
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField = this.currentFlow.config.payload_config.root_elements.find(
        (field) =>
          field.published_name === this.field.conditional_visibility_input
      );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    customRules() {
      const condition = Object.keys(this.linkedAttributes)[0];
      const constrains = this.linkedAttributes[condition];
      const customValidation = (value) => {
        if (constrains.length === 3) {
          const valueA = this.parseValueToFloat(value);
          const rangeValueA = this.parseValueToFloat(constrains[0]);
          const rangeValueB = this.parseValueToFloat(constrains[2]);
          return valueA >= rangeValueA && valueA <= rangeValueB
            ? true
            : `The value must be between ${constrains[0]} and ${constrains[2]}`;
        }
        if (isNaN(constrains[1]) && this.ruleType !== "amount") {
          return this.compareValue(
            value,
            condition,
            constrains[1],
            constrains[1]
          );
        }
        const valueA = this.parseValueToFloat(value);
        return this.compareValue(
          valueA,
          condition,
          this.parseValueToFloat(constrains[1]),
          constrains[1]
        );
      };
      return [customValidation];
    },
    parseValueToFloat(value) {
      if (!value) return 0.0;
      if (this.ruleType === "amount") {
        const clearedValue = [];
        value.split("").forEach((l) => {
          const decimalSeparator = this.additionalSettings.decimal;
          if (/\d/.test(l)) {
            clearedValue.push(l);
          }
          if (l === decimalSeparator) {
            clearedValue.push(".");
          }
        });
        return parseFloat(clearedValue.join(""));
      }
      return parseFloat(value);
    },
    compareValue(valueA, condition, valueB, orginalValue) {
      switch (condition) {
        case ">":
          return valueA > valueB
            ? true
            : `The value must be greater than ${orginalValue}`;
        case "<":
          return valueA < valueB
            ? true
            : `The value must be less than ${orginalValue}`;
        case ">=":
          return valueA >= valueB
            ? true
            : `The value must be greater or equal ${orginalValue}`;
        case "<=":
          return valueA <= valueB
            ? true
            : `The value must be less or equal ${orginalValue}`;
        case "!==":
          return valueA !== valueB
            ? true
            : `The value must be different than ${orginalValue}`;
        case "===":
          return valueA === valueB ? true : `The value must be ${orginalValue}`;
        default:
          return "Custom validation failed!";
      }
    },
    handleTextChange(value) {
      this.$emit("text-change", { [this.field.key]: value });
    },
    validateText(value) {
      this.$emit("text-change", value);
    },
  },
};
</script>
