<template>
  <div>
    <v-text-field
      outlined
      :rules="isItRequired"
      :label="generateLabel"
      :value="defaultValue"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append="showPassword = !showPassword"
      @change="handleChange"
      dense
      :hint="field.hint"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
/**
 * @module components/DynamicForm/Fields/PasswordField
 * @desc Text field component of dynamic form
 */
import { mapState } from "vuex";
import BaseField from "@/mixins/BaseField";

export default {
  name: "PasswordField",
  mixins: [BaseField],
  /**
   * @vue-prop {Object} field
   * @vue-prop {Boolean} textarea
   * @vue-prop {String|Number|Object} value
   * @vue-prop {Array} filters
   * @vue-prop {Boolean} disabled
   */
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },
  data: () => ({
    showPassword: false,
    unsubscribe: null,
  }),
  /**
   * @vue-computed {Array|undefined} isItRequired
   * @vue-computed {String} generateLabel
   * @vue-computed {String} classes
   */
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  /**
   * Called on component creation
   * If default field, call handleChange
   */
  created() {
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  /**
   * @vue-event {Object} text-change
   */
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField =
        this.currentFlow.config.payload_config.root_elements.find(
          (field) =>
            field.published_name === this.field.conditional_visibility_input
        );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    /**
     * Called on form change
     * Modifies passed in value depending on filters
     * Emits text-change event with modified value as data
     * @param {String} value
     */
    handleChange(value) {
      this.$emit("text-change", { [this.field.key]: value });
    },
  },
};
</script>
