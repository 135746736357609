<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <div
        ref="widgetContainer"
        class="widget"
        :style="generateInlineCSS"
        :class="{
          cursor__pointer: hasLinkedBotflow,
          watchlist: isWatchList,
          widget__light: !isDark,
        }"
        v-bind="attrs"
        @click="redicrectToFlow"
        v-on="on"
      >
        <div class="widget__header">
          <h2 class="widget__title">{{ widget.config.title }}</h2>
          <v-icon class="widget__icon">mdi-{{ widget.config.icon }}</v-icon>
        </div>
        <div class="widget__refresh-button">
          <v-icon @click="refresh($event)">mdi-refresh</v-icon>
        </div>

        <v-progress-circular
          v-if="isLoading"
          color="accent"
          indeterminate
          size="28"
        />
        <span
          v-if="!isLoading && widget.widget_display !== 'Currency'"
          class="widget__value"
        >
          {{ statisticsValue }}
        </span>
        <span
          v-if="!isLoading && widget.widget_display === 'Currency'"
          class="widget__value"
        >
          {{ statisticsValue | currency }}
        </span>
      </div>
    </template>
    <span>{{ getWidgetText }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import fieldsAPI from "@/api/fields";

/**
 * @module components/Widgets/Widget
 * @desc Component visualizing the widget
 * @vue-prop {Object} widget - Object containing information about the widget
 */
export default {
  name: "WidgetwComponent",
  props: {
    widget: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    statisticsValue: "0",
    isLoading: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      isFetchingWidgets: (state) => state.widgets.isFetchingWidgets,
      widgets: (state) => state.widgets.widgets,
    }),
    /**
     * Applies the selected widget color
     * @returns {String} Inline css rule
     */
    generateInlineCSS() {
      return `--widget-color: ${this.widget.config?.color}`;
    },
    hasLinkedBotflow() {
      return this.widget.linked_botflow?.length;
    },
    getWidgetText() {
      return this.widget.config?.text || "";
    },
    isWatchList() {
      return this.widget.type?.toLowerCase() === "jdewatchlist";
    },
    isDark() {
      return this.$vuetify.theme.isDark;
    },
  },
  async created() {
    if (!this.widgets.length) {
      await this.waitForFetchingToComplete();
    }
    this.initiateDataFetching();
  },
  methods: {
    ...mapMutations({
      setCurrentFlow: "company/setCurrentFlow",
    }),
    waitForFetchingToComplete() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isFetchingWidgets) {
            resolve();
            clearInterval(interval);
          }
        }, 100);
      });
    },
    refresh(event) {
      event.stopPropagation();
      this.initiateDataFetching();
    },
    async initiateDataFetching() {
      this.isLoading = true;
      if (this.widget.type.toLowerCase() === "jdewatchlist") {
        await this.fetchWatchlistData();
      }
      if (this.widget.type.toLowerCase() === "jdeanalytics") {
        await this.fetchOrchData();
      }
      this.isLoading = false;
    },
    async redicrectToFlow() {
      if (!this.widget.linked_botflow) return;
      await this.$store.commit("flows/setCurrentFlow", null);
      this.$router.push(`/obot-app/flows/${this.widget.linked_botflow}`);
    },
    async fetchOrchData() {
      try {
        const aisConfig = JSON.parse(this.widget.ais_config);
        const config = {
          org_id: this.currentUser.company_id,
          connector_name: aisConfig.connector,
          orchestration_name: this.widget.linked_orch_name,
          botflow_name: "Widget",
          user_email: this.currentUser.email,
          response_format: "STANDARD",
        };

        const data = await fieldsAPI.submit(config, {});
        const responseKey = aisConfig.specific_field;
        let valueKey = aisConfig.mapped_value;
        if (valueKey.includes("]")) {
          valueKey = valueKey.split("]")[1];
        }
        let result;
        if (data.orchestration_response[valueKey]) {
          result = data.orchestration_response[valueKey];
        } else {
          result = data.orchestration_response[responseKey].rowset[0][valueKey];
        }
        this.parseResult(result);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWatchlistData() {
      try {
        const aisConfig = JSON.parse(this.widget.ais_config);
        const payload = {
          ...this.currentUser,
          user_email: this.currentUser.email,
          connector: aisConfig.connector,
          watchlistId: aisConfig.specific_field,
        };
        const data = await fieldsAPI.fetchWatchlistData(payload);
        const result =
          data.value === data.maxRecords ? `${data.value}+` : data.value;
        this.parseResult(result);
        this.changeColor(data);
      } catch (error) {
        console.log(error);
      }
    },
    changeColor(data) {
      if (data.criticalThreshold < data.value) {
        this.$refs.widgetContainer?.setAttribute(
          "style",
          "--widget-color: red"
        );
        this.statisticsValue = `${data.value}+`;
        return;
      }
      if (data.warningThreshold < data.value) {
        this.$refs.widgetContainer?.setAttribute(
          "style",
          "--widget-color: #FC971C"
        );
        return;
      }
      this.$refs.widgetContainer?.setAttribute(
        "style",
        "--widget-color: green"
      );
    },
    parseResult(result) {
      switch (this.widget.widget_display) {
        case "Float":
          this.statisticsValue = parseFloat(result, 2);
          break;
        case "Currency":
          this.statisticsValue = this.$n(result, "currency");
          break;
        default:
          this.statisticsValue = parseInt(result);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  background-color: var(--page-component-bg);
  height: 100px;
  padding: 0.5rem;
  width: 100%;
  border-radius: 6px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.5);
  border-bottom: 3px solid var(--widget-color);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &__light {
    background-color: #fff !important;
  }
  &__icon,
  &__value {
    color: var(--widget-color);
    line-height: 1;
  }
  &:not(.watchlist) {
    background-color: var(--page-component-bg);
    color: var(--page-component-color);
    border-bottom: 0;
  }
  &__icon {
    font-size: 24px;
  }
  &__value {
    font-weight: 600;
    text-align: left;
    font-size: 30px;
    overflow: hidden;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    overflow: hidden;
    line-height: 1;
    margin-right: 5px;
  }
  &__refresh-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: rgba(#02a4d3, 0.9);
    pointer-events: none;
    opacity: 0;
    z-index: 2;
    padding: 4px;
    transition: 0.3s opacity ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 4px;
  }
  &:hover {
    .widget {
      &__refresh-button {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
</style>
