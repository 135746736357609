<template>
  <div class="image-wrapper">
    <img :src="getSrc" alt="Company's image" />
  </div>
</template>

<script>
export default {
  name: "HTML5Image",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getSrc() {
      return this.field.linked_attributes?.image_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 400px;
    margin-bottom: ($page-spacing / 2);
    width: 100%;
  }
}
</style>
