export default {
  state: () => ({
    templatesPerBotflow: [],
    templatesPerUser: [],
  }),

  mutations: {
    setTemplatesPerBotflow(state, templatesPerBotflow) {
      state.templatesPerBotflow = templatesPerBotflow;
    },
    setTemplatesPerUser(state, templatesPerUser) {
      state.templatesPerUser = templatesPerUser;
    },
  },
  actions: {
    async createTemplate({ rootState }, data) {
      try {
        await this._vm.$api.config.createTemplate(
          {
            currentFlow: rootState.company.currentFlow,
            currentUser: rootState.users.currentUser,
            name: data.name,
          },
          data.payload
        );
      } catch (error) {
        this._vm.$logError(error);
      }
    },
    async fetchTemplatesPerBotflow({ commit, rootState }, botflowId) {
      try {
        const templates = await this._vm.$api.config.getTemplatesPerBotflow(
          botflowId,
          rootState.users.currentUser.bot_user_id
        );
        commit("setTemplatesPerBotflow", templates);
      } catch (error) {
        this._vm.$logError(error);
      }
    },
  },
  namespaced: true,
};
