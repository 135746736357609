<template>
  <div class="image-viewer">
    <img
      ref="imageElement"
      class="d-none"
      :src="getImageSrc"
      :alt="fileDetails.itemName"
    />
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
export default {
  name: "ImageViewer",
  props: {
    fileDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getImageSrc() {
      const fileExtension = this.fileDetails.itemName.split(".").slice(-1)[0];
      switch (fileExtension) {
        case "png":
          return `data:image/png;base64,${this.fileDetails.data}`;
        case "jpg":
        case "jpeg":
          return `data:image/jpeg;base64,${this.fileDetails.data}`;
        default:
          return `data:image/png;base64,${this.fileDetails.data}`;
      }
    },
    getToolbarOptions() {
      const defaultOptions = {
        zoomIn: 1,
        zoomOut: 1,
        oneToOne: 1,
        reset: 1,
        rotateLeft: 1,
        rotateRight: 1,
        flipHorizontal: 1,
        flipVertical: 1,
      };
      return defaultOptions;
    },
  },
  mounted() {
    const viewer = new Viewer(this.$refs.imageElement, {
      inline: true,
      backdrop: false,
      button: false,
      navbar: 0,
      toolbar: this.getToolbarOptions,
    });
    viewer.zoomTo(1.2);
  },
};
</script>

<style lang="scss" scoped>
.image-viewer {
  height: 80vh;
}
</style>
