<template>
  <div>
    <v-checkbox
      v-model="checkboxValue"
      :rules="isItRequired"
      :label="generateLabel"
      :hint="field.hint"
      :disabled="isDisabled"
      @change="handleCheckboxChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HTML5Checkbox",
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },
  data: () => ({
    checkboxValue: false,
    unsubscribe: null,
  }),
  computed: {
    ...mapState({
      currentFlow: (state) => state.company.currentFlow,
    }),
    isItRequired() {
      return this.field.element_required || this.field.required
        ? [this.validation.required]
        : undefined;
    },
    generateLabel() {
      return this.field.element_required || this.field.required
        ? `${this.field.published_name}*`
        : this.field.published_name;
    },
    isDisabled() {
      if (this.field.protected) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (this.field.linked_attributes.truthy === this.field.default) {
      this.checkboxValue = true;
      this.handleCheckboxChange(this.checkboxValue);
    }
    if (this.field.linked_attributes.falsy === this.field.default) {
      this.checkboxValue = false;
      this.handleCheckboxChange(this.checkboxValue);
    }
    if (this.field.conditional_visibility) {
      this.handleConditionalVisibility();
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    handleConditionalVisibility() {
      if (!this.field.conditional_visibility_values) return;
      const controllerField = this.currentFlow.config.payload_config.root_elements.find(
        (field) =>
          field.published_name === this.field.conditional_visibility_input
      );
      this.subscribeToMasterFieldChanges(controllerField);
    },
    subscribeToMasterFieldChanges(controlField) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "company/setFlowData") {
          const controlFieldKey = controlField.key;
          if (
            !Object.prototype.hasOwnProperty.call(
              mutation.payload,
              controlFieldKey
            )
          )
            return;
          const value = mutation.payload[controlFieldKey];
          if (this.field.conditional_visibility_values.includes(value)) {
            this.$emit("manage-field-visibility", {
              visibility: false,
              field: this.field.key,
            });
            return;
          }
          this.$emit("manage-field-visibility", {
            visibility: true,
            field: this.field.key,
          });
        }
      });
    },
    handleCheckboxChange(value) {
      const parsedValue = value
        ? this.field.linked_attributes.truthy
        : this.field.linked_attributes.falsy;
      this.$emit("text-change", { [this.field.key]: parsedValue });
    },
  },
};
</script>
