export default {
  state: () => ({
    currentMenuItem: null,
    botFlows: [],
    menuItems: [],
    currentFlow: null,
    settings: null,
    flowData: {},
    demoState: false,
    staticValues: [
      { value: "STATIC_JDE_COMPANY", type: "standard" },
      { value: "STATIC_JDE_USER", type: "standard" },
      { value: "STATIC_JDE_AB", type: "standard" },
      { value: "CURRENT_USER_EMAIL", type: "standard" },
      { value: "STATIC_FROM", type: "outlook" },
      { value: "STATIC_FROM_NAME", type: "outlook" },
      { value: "STATIC_TO", type: "outlook" },
      { value: "STATIC_CC", type: "outlook" },
      { value: "STATIC_BODY", type: "outlook" },
      { value: "STATIC_SUBJECT", type: "outlook" },
    ],
    linkedData: {},
    fileMeta: {},
    isHamon: false,
    isFetchingFlows: true,
    flowsPerConnector: [],
  }),
  mutations: {
    setCurrentMenuItem(state, payload) {
      state.currentMenuItem = payload;
    },
    setMenuItems(state, payload) {
      state.menuItems = payload;
    },
    setBotFlows(state, payload) {
      state.botFlows = payload;
    },
    setCurrentFlow(state, payload) {
      state.currentFlow = payload;
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
    setFlowData(state, payload) {
      state.flowData = payload;
    },
    setDemoState(state) {
      state.demoState = true;
    },
    setLinkedData(state, payload) {
      state.linkedData = payload;
    },
    updateCurrentFlow(state, payload) {
      const updatedFlow = Object.assign({}, state.currentFlow, payload);
      state.currentFlow = updatedFlow;
    },
    setFileMeta(state, payload) {
      state.fileMeta = payload;
    },
    setIsHamon(state) {
      state.isHamon = true;
    },
    setIsFetchingFlows(state, value) {
      state.isFetchingFlows = value;
    },
    setFlowsPerConnector(state, payload) {
      state.flowsPerConnector = payload;
    },
  },
  actions: {
    async fetchMenuItems({ commit }, payload) {
      const response = await this._vm.$api.users.fetchMenuItems(payload);
      commit(
        "setMenuItems",
        response.filter((menuItem) => menuItem.name !== "CONFIG")
      );
    },
    async fetchBotFlows({ commit }, payload) {
      const response = await this._vm.$api.config.fetchBotFlows(payload);
      commit("setBotFlows", response);
    },
    async fetchSettings({ commit }, payload) {
      const companySettings = await this._vm.$api.company.fetchCompany(payload);
      if (payload === "3472e2d9-a6f8-49b4-a60a-d7a39ed153d5") {
        commit("setIsHamon");
      }
      localStorage.setItem("x-api-key", companySettings.api_key);
      if (companySettings?.logo && companySettings?.logo.length > 0) {
        localStorage.setItem("clientLogo", companySettings.logo);
        commit("common/setLoaderImage", companySettings.logo, { root: true });
      }
      commit("setSettings", companySettings);
    },
    async fetchBotflowsPerConnector({ commit, rootState }) {
      const selectedConnector = rootState.connectors.selectedConnector;
      if (!selectedConnector) return;
      try {
        commit("setIsFetchingFlows", true);
        const response = await this._vm.$api.config.getBotflowsPerConnector(
          selectedConnector.connector_id
        );
        commit("setFlowsPerConnector", response);
      } catch (error) {
        this._vm.$logError(error);
      } finally {
        commit("setIsFetchingFlows", false);
      }
    },
  },
  getters: {
    getAvailableBotFlows(state, _, rootState) {
      return state.botFlows.filter((flow) => {
        const matchingRoles = flow.allowed_roles.filter((element) =>
          rootState.users.currentUser.groups.includes(element)
        );
        return matchingRoles.length && flow.enabled;
      });
    },
  },
  namespaced: true,
};
