<template>
  <div
    :key="$vuetify.theme.isDark"
    class="theme-switcher"
    :class="{ bottom: bottom, top: top, left: left, right: right }"
  >
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-icon
          v-if="$vuetify.theme.isDark === true"
          v-on="on"
          @click="changeTheme"
        >
          mdi-white-balance-sunny
        </v-icon>
        <v-icon v-else v-on="on" @click="changeTheme">
          mdi-moon-waxing-crescent
        </v-icon>
      </template>
      <span> THEME </span>
    </v-tooltip>
  </div>
</template>

<script>
import { setCompanyColors } from "@/utils/common";

export default {
  name: "ThemeSwitcher",
  /**
   * @vue-prop {String} currentTheme - Current theme
   * @vue-prop {Boolean} bottom - wether or not "bottom" class is active
   * @vue-prop {Boolean} top - wether or not "top" class is active
   * @vue-prop {Boolean} left - wether or not "left" class is active
   * @vue-prop {Boolean} right - wether or not "bright" class is active
   */
  props: {
    currentTheme: {
      type: String,
      default: () => "",
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * @return {Object} - Object containing the company colors
     */
    getCompanyColors() {
      return this.$store.state.company.settings.colors || {};
    },
  },
  /**
   * Called when component is clicked
   * Reverses isDark and vuetify theme
   * set vue's color preference to dark or light
   */
  methods: {
    changeTheme() {
      this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark;
      const theme = this.$vuetify.theme.isDark ? "dark" : "light";
      localStorage.setItem("theme", theme);

      if (this.$vuetify.theme.isDark) {
        document.documentElement.classList.add("dark-mode");
        document.documentElement.classList.remove("light-mode");
      } else {
        document.documentElement.classList.add("light-mode");
        document.documentElement.classList.remove("dark-mode");
      }
      const currentThemeColors = this.getCompanyColors[theme];
      if (!currentThemeColors) return;
      setCompanyColors(currentThemeColors);
      const pagePrimaryBg = currentThemeColors["--page-primary"];
      if (!pagePrimaryBg) return;
      this.$vuetify.theme.themes[theme].primary = pagePrimaryBg;
    },
  },
};
</script>
