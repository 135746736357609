<template>
  <div>
    <widget :widget="widget" :loading="isFetchingWidgets" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Widget from "@/components/Widget.vue";
export default {
  name: "WidgetField",
  components: { Widget },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    widget: {},
  }),
  computed: {
    ...mapState({
      widgets: (state) => state.widgets.widgets,
      isFetchingWidgets: (state) => state.widgets.isFetchingWidgets,
    }),
  },
  watch: {
    widgets() {
      this.getWidget();
    },
    field() {
      this.getWidget();
    },
  },
  created() {
    if (!this.widgets?.length && !this.isFetchingWidgets) {
      this.fetchWidgets();
    }
    if (this.widgets.length) {
      this.getWidget();
    }
  },
  methods: {
    ...mapActions({
      fetchWidgets: "widgets/fetchWidgets",
    }),
    getWidget() {
      this.widget =
        this.widgets.find(
          (widget) => widget.id === this.field.linked_attributes?.widget
        ) || {};
    },
  },
};
</script>
