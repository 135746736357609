<template>
  <v-card>
    <v-card-title>
      <div class="d-flex justify__between w__full">
        <div class="flex gap--small">
          <v-icon large color="primary">{{ fileIcon }}</v-icon>
          <h3>{{ clonedFileDetails.itemName }}</h3>
        </div>
        <v-icon large color="primary" @click="closeDialog"> mdi-close </v-icon>
      </div>
    </v-card-title>
    <component
      v-if="!isConverting"
      :is="componentViewer"
      :key="clonedFileDetails.url"
      :file-details="clonedFileDetails"
      @fetch-error="$emit('fetch-error')"
    />
    <div
      v-if="isConverting"
      class="d-flex flex-column align-center justify-center"
    >
      <p class="text-subtitle-1">Converting file</p>
      <v-progress-circular size="28" color="primary" indeterminate />
    </div>
  </v-card>
</template>

<script>
import { binaryStringToBlob } from "blob-util";
import fieldsAPI from "@/api/fields";

import PdfViewer from "@/components/Viewers/PDFViewer.vue";
import TextViewer from "@/components/Viewers/TextViewer.vue";
import ImageViewer from "@/components/Viewers/ImageViewer.vue";
export default {
  name: "FileViewer",
  components: {
    PdfViewer,
    TextViewer,
    ImageViewer,
  },
  data: () => ({
    componentViewer: "",
    isConverting: false,
    clonedFileDetails: {},
    fileExtension: "",
    basePayload: {},
    fileTypes: {
      pdf: "mdi-file-pdf-box",
      png: "mdi-file-png-box",
      jpg: "mdi-file-jpg-box",
      jpeg: "mdi-file-jpg-box",
      txt: "mdi-file-document-outline",
      xls: "mdi-microsoft-excel",
      xlsx: "mdi-microsoft-excel",
      odt: "mdi-file-word",
      docx: "mdi-file-word-outline",
      csv: "mdi-file-delimited",
    },
  }),
  computed: {
    fileIcon() {
      return this.fileTypes[this.fileExtension] || "mdi-file-document";
    },
    getMimeType() {
      const type = this.fileExtension;
      switch (type) {
        case "jpg":
          return "image/jpeg";
        case "png":
          return "image/png";
        case "txt":
          return "text/plain";
        case "pdf":
          return "application/pdf";
        case "xls":
          return "application/vnd.google-apps.spreadsheet";
        case "xlsx":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "docx":
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "odt":
          return "application/msword";
        default:
          return type;
      }
    },
  },
  async mounted() {
    Office.context.ui.addHandlerAsync(
      Office.EventType.DialogParentMessageReceived,
      (result) => {
        if (result.error) {
          console.error(result.error);
          return;
        }
        const { message } = result;
        try {
          const parsedMessage = JSON.parse(message);
          this.init(parsedMessage);
        } catch (error) {
          console.error(error);
          this.closeDialog();
        }
      }
    );
    Office.context.ui.messageParent("loaded");
  },
  methods: {
    closeDialog() {
      window.close();
    },

    async init({ fileDetails, basePayload }) {
      this.clonedFileDetails = fileDetails;
      this.basePayload = basePayload;
      this.fileExtension = this.clonedFileDetails.itemName
        .split(".")
        .slice(-1)[0];
      const imageExtensions = ["png", "jpg", "jpeg"];
      if (imageExtensions.includes(this.fileExtension)) {
        this.componentViewer = "image-viewer";
        return;
      }
      if (this.fileExtension === "docx") {
        this.isConverting = true;
        await this.downloadAndUpload();
        const s3Url = await this.generatePresignedUrl();
        await this.convertToPDF(s3Url);
      }
      switch (this.clonedFileDetails.moType) {
        case 0:
          this.componentViewer = "text-viewer";
          break;
        case 1:
          this.componentViewer = "pdf-viewer";
          break;
        default:
          this.componentViewer = "pdf-viewer";
          break;
      }
    },
    async downloadAndUpload() {
      try {
        const file = await this.downloadFileFromJDE();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", this.clonedFileDetails.itemName);
        formData.append("bucket", "temporary-attachements-bucket");
        formData.append("classification", "temp-upload");
        formData.append("company_id", "Hight.io");
        formData.append("additionalClassification", "");
        formData.append("jde_key", "");
        await this.$api.jdox.uploadFile(formData);
      } catch (error) {
        console.error(error);
      }
    },
    async downloadFileFromJDE() {
      const payload = Object.assign({}, this.basePayload);
      payload["mo_seq_nr"] = this.clonedFileDetails.sequence;
      payload["route"] = "attachment_mo_download";
      payload["downloadUrl"] = this.clonedFileDetails.downloadUrl;
      const jdeFile = await fieldsAPI.downloadFile(payload);
      const fileBlob = binaryStringToBlob(jdeFile, this.getMimeType);
      const file = new File([fileBlob], this.clonedFileDetails.itemName, {
        type: this.getMimeType,
      });
      return file;
    },
    async generatePresignedUrl() {
      const url = await this.$api.jdox.getPresignedUrl(
        "Hight.io",
        this.clonedFileDetails.itemName
      );
      return url;
    },
    async convertToPDF(url) {
      this.isConverting = true;
      try {
        const convertedURL = await this.$api.jdox.convertToPDF({
          type: this.fileExtension,
          url,
        });
        this.clonedFileDetails.fileContent = convertedURL;
      } catch (error) {}
      this.isConverting = false;
    },
  },
};
</script>
